import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FlatList, Text, View } from 'react-native'

import { LoadingIcon, NavigationHeader } from '../../components'
import { fetchProducts, resetCatalog } from './behaviors'
import { ProductListItem } from './components'
import {
  getProductsForCategory,
  getProductsContentState,
  getProductsLoadingState,
  getCatalogErrorState
} from './selectors'
import styles from './styles'

import { getUserAccount } from '../UserAccount/selectors'

class ProductListScreen extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const title = navigation.getParam('categoryTitle', 'Products')
    return { header: props => <NavigationHeader navigation={navigation} title={title} /> }
  }

  static propTypes = {
    products: PropTypes.array,

    isRequestingProducts: PropTypes.bool,
    hasProductsResponse: PropTypes.bool,

    fetchProducts: PropTypes.func.isRequired,
    resetCatalog: PropTypes.func.isRequired,

    error: PropTypes.any
  }

  static defaultProps = {
    products: [],

    isRequestingProducts: false,
    hasProductsResponse: false,

    error: false,
  }

  componentDidMount () {
    this.getProductList()
  }

  getProductList () {
    const { show_pricing: getUserPricing, show_inventory: getUserInventory } = this.props.account

    const categoryId = this.props.route.params?.categoryId
    this.props.fetchProducts(categoryId, getUserPricing || getUserInventory)
  }

  handleOnPress (productId, productTitle) {
    const { navigation } = this.props
    navigation.navigate('ProductView', {
      productTitle,
      productId
    })
  }

  render () {
    const { products, isRequestingProducts, hasProductsResponse, error } = this.props

    return (
      <View style={styles.productList.container}>
        { isRequestingProducts && (
          <LoadingIcon />
        )}

        { hasProductsResponse && (
          <FlatList
            data={products}
            keyExtractor={item => String(item.id)}
            renderItem={({ item }) => (
              <ProductListItem
                product={item}
                onPress={() => {
                  const { id, title } = item
                  this.handleOnPress(id, title)
                }} />
            )} />
        )}

        { error && (
          <View>
            <Text>No products found</Text>
          </View>
        )}
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  products: getProductsForCategory(state),

  isRequestingProducts: getProductsLoadingState(state),
  hasProductsResponse: getProductsContentState(state),

  account: getUserAccount(state),

  error: getCatalogErrorState(state)
})

export default connect(mapStateToProps, { fetchProducts, resetCatalog })(ProductListScreen)
