import config from './config'

const alphabet = 'ABCDEF0123456789'

const getTitle = (token, input) => {
  return (token === '') ? input : `[${token}] ${input}`
}

export const bookmark = (length = 4) => {
  let token = ''
  for (let i = 0; i < length; i += 1) {
    token += alphabet.charAt(Math.floor(
      Math.random() * alphabet.length))
  }
  return token
}

export const trace = (message, params, token = '') => {
  if (config.traceEnabled) {
    console.trace(getTitle(token, (message || 'TRACE')), params)
  }
}

export const debug = (message, params, token = '') => {
  if (config.debugEnabled) {
    console.log(getTitle(token, (message || 'DEBUG')), params)
  }
}

export const warn = (message, params, token = '') => {
  if (config.warnEnabled) {
    console.warn(getTitle(token, (message || 'WARN')), params)
  }
}

export const fault = (message, params, token = '') => {
  console.error(getTitle(token, (message || 'ERROR')), params)
}
