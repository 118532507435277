import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'react-native'
import { format, parse } from 'date-fns'

const dateFormat = 'MMM D, YYYY'
const formatDate = date => format(parse(date), dateFormat)

const DateText = (props) => {
  const formatted = formatDate(props.value || '')
  return <Text {...props}>{formatted}</Text>
}

DateText.propTypes = {
  value: PropTypes.string.isRequired
}

export default DateText
