import React from 'react'
import { connect } from 'react-redux'
import { FlatList, Switch, Text, View } from 'react-native'

import { updateCurrentUser } from '../UserAccount/behaviors'
import { getUserAccount } from '../UserAccount/selectors'
import { ButtonPill } from '../../components'
import { colors } from '../../theme'

import {
  fetchLocations,
  deleteShippingLocation
} from './behaviors'
import {
  getLocationErrorState,
  getLocationContentState,
  getLocationLoadingState,
  getShippingLocations,
} from './selectors'
import styles from './styles'

const { DANGER } = colors

class ShippingLocationsScreen extends React.Component {
  static navigationOptions = {
    title: 'Shipping Locations'
  }

  state = {
    refreshing: true
  }

  getShippingLocations = async () => {
    await this.props.fetchLocations()
    this.setState({ refreshing: false })
  }

  getLocationAddress = ({ address1, city, state, zip }) => {
    return `${address1}\n${city}, ${state}\n${zip}`
  }

  handleSwitchPress = (value, location) => {
    this.props.updateCurrentUser({
      preferred_shipping_location: (value) ? location.id : null
    })
  }

  handleEditPress = async location => {
    this.props.navigation.navigate('ShippingEdit', { locationId: location.id })
  }

  handleDeletePress = location => {
    this.props.deleteShippingLocation(location.id)
    this.props.fetchLocations()
  }

  handleRefresh = () => {
    this.setState(
      {
        refreshing: true
      },
      () => {
        this.getShippingLocations()
      }
    )
  }

  componentDidMount () {
    this.getShippingLocations()
  }

  renderLocation = item => {
    return (
      <View style={styles.shipping.panel}>
        <View style={styles.shipping.column}>
          <Text style={styles.shipping.boldTextLarge}>{item.name}</Text>
          <Text style={styles.shipping.label}>{this.getLocationAddress(item)}</Text>
        </View>
        <View style={styles.shipping.columnDouble}>
          <View style={styles.shipping.flexRow}>
            <ButtonPill
              text='Delete'
              align='center'
              color={DANGER}
              onPress={() => this.handleDeletePress(item)} />
            <ButtonPill
              text='Edit'
              align='center'
              onPress={() => this.handleEditPress(item)} />
          </View>
          <View style={styles.shipping.divider} />
          <View style={styles.shipping.switchWrap}>
            <Switch
              value={this.props.user.preferred_shipping_location === item.id}
              onValueChange={value => this.handleSwitchPress(value, item)} />
            <Text style={styles.shipping.switchLabel}>Preferred Location</Text>
          </View>
        </View>
      </View>
    )
  }

  render () {
    const { hasLocations, isLoading, locations } = this.props
    return (
      <View style={styles.shipping.container}>
        <FlatList
          data={locations}
          onEndReached={null}
          keyExtractor={item => String(item.id)}
          renderItem={({ item }) => this.renderLocation(item)}
          ItemSeparatorComponent={({ item }) => (
            <View style={styles.shipping.separator} />
          )} />
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    error: getLocationErrorState(state),
    isLoading: getLocationLoadingState(state),
    hasLocation: getLocationContentState(state),
    locations: getShippingLocations(state),
    user: getUserAccount(state)
  }
}

export default connect(mapStateToProps, {
  deleteShippingLocation,
  fetchLocations,
  updateCurrentUser
})(ShippingLocationsScreen)
