import { colors } from '../../theme'

const { ACCENT, GREY, GREY_LT3, WHITE } = colors

export default {
  pickup: {
    container: {
      flex: 1,
      backgroundColor: GREY_LT3
    },
    panel: {
      width: '100%',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: ACCENT,
      backgroundColor: WHITE,
      paddingVertical: 15,
      paddingHorizontal: 10,
      flexDirection: 'row',
      marginTop: 10,
      alignItems: 'center'
    },
    column: {
      flex: 2,
      flexDirection: 'column'
    },
    columnDouble: {
      flex: 3,
      flexDirection: 'column'
    },
    flexRow: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    inlineWrap: {
      flexDirection: 'row'
    },
    boldText: {
      lineHeight: 24,
      fontWeight: 'bold'
    },
    boldTextLarge: {
      lineHeight: 24,
      fontSize: 16,
      fontWeight: 'bold'
    },
    label: {
      lineHeight: 24,
      marginRight: 5
    },
    divider: {
      height: 0,
      marginVertical: 5
    },
    switchWrap: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 15
    },
    switchLabel: {
      marginLeft: 10
    }
  },
  shipping: {
    container: {
      flex: 1,
      backgroundColor: GREY_LT3
    },
    panel: {
      width: '100%',
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: ACCENT,
      backgroundColor: WHITE,
      paddingVertical: 15,
      paddingHorizontal: 10,
      flexDirection: 'row',
      marginTop: 10,
      alignItems: 'center'
    },
    column: {
      flex: 2,
      flexDirection: 'column'
    },
    columnDouble: {
      flex: 3,
      flexDirection: 'column'
    },
    flexRow: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    inlineWrap: {
      flexDirection: 'row'
    },
    boldText: {
      lineHeight: 24,
      fontWeight: 'bold'
    },
    boldTextLarge: {
      lineHeight: 24,
      fontSize: 16,
      fontWeight: 'bold'
    },
    label: {
      lineHeight: 24,
      marginRight: 5
    },
    divider: {
      height: 0,
      marginVertical: 5
    },
    switchWrap: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 15
    },
    switchLabel: {
      marginLeft: 10
    }
  },
  shippingForm: {
    container: {
      flex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: GREY_LT3
    },
    formWrap: {
      backgroundColor: GREY_LT3,
      padding: 40
    },
    icon: {
      height: 30,
      resizeMode: 'contain'
    },
    lightText: {
      color: GREY,
      fontSize: 18
    },
    switchWrap: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 15
    },
    switchLabel: {
      marginLeft: 10
    },
    textInput: {
      backgroundColor: WHITE,
      borderWidth: 2,
      borderColor: ACCENT,
      padding: 15,
      marginBottom: 15
    }
  }
}
