import React from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, View } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'

import { colors } from '../theme'

const { DANGER, GREY } = colors

const styles = {
  container: {},
  icon: {
    width: 34,
    height: 34,
    alignSelf: 'flex-end'
  }
}

class FavoriteButton extends React.Component {
  static propTypes = {
    product: PropTypes.shape({
      id: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
      user_favorite_id: PropTypes.number
    }).isRequired,
    style: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired,
    setFavorite: PropTypes.func.isRequired,
    unsetFavorite: PropTypes.func.isRequired
  }

  static defaultProps = {}

  state = {
    color: GREY
  }

  componentDidMount () {
    const { product } = this.props
    if (product['user_favorite_id']) {
      this.setState({ color: DANGER })
    } else {
      this.setState({ color: GREY })
    }
  }

  handleFavoritePress () {
    const { product, style } = this.props
    if (product['user_favorite_id']) {
      this.setState({ color: GREY })
      this.props.unsetFavorite(product['user_favorite_id'])
    } else {
      this.setState({ color: DANGER })
      this.props.setFavorite(product.id, style.id)
    }
  }

  render () {
    return (
      <View style={styles.container}>
        <TouchableOpacity onPress={() => this.handleFavoritePress()}>
          <FontAwesome
            color={this.state.color}
            style={styles.icon}
            name='heart'
            size={32} />
        </TouchableOpacity>
      </View>
    )
  }
}

export default FavoriteButton
