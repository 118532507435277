import { showMessage } from 'react-native-flash-message'
import { processErrorResponse } from '../../utilities'
import { bookmark, debug, fault } from '../../logger'
import getClient from '../../services/getClient'

export const USER_ACCOUNT_RESET = 'USER/ACCOUNT/RESET'
export const USER_ACCOUNT_ERROR = 'USER/ACCOUNT/ERROR'
export const USER_ACCOUNT_FETCH = 'USER/ACCOUNT/FETCH'
export const USER_ACCOUNT_UPDATE = 'USER/ACCOUNT/UPDATE'
export const USER_ACCOUNT_RECEIVE = 'USER/ACCOUNT/RECEIVE'
export const USER_ACCOUNT_DELETE_REQUEST = 'USER/ACCOUNT/DELETE/REQUEST'
export const USER_ACCOUNT_DELETE_PASSWORD_INCORRECT = 'USER/ACCOUNT/DELETE/PASSWORD_INCORRECT'
export const USER_ACCOUNT_DELETE_SUCCESS = 'USER/ACCOUNT/DELETE/SUCCESS'

const initialState = {
  error: null,
  hasError: false,
  hasUserAccountResponse: false,
  isRequestingUserAccount: false,
  confirmDeletePasswordIncorrect: false,
  deleteSuccess: false,
  account: {},
}

const handlers = {
  [USER_ACCOUNT_FETCH]: (state, action) => ({ ...state, error: null, hasError: false, hasUserAccountResponse: false, isRequestingUserAccount: true }),
  [USER_ACCOUNT_RECEIVE]: (state, action) => {
    const { payload } = action

    return {
      ...state,
      isRequestingUserAccount: false,
      hasUserAccountResponse: true,
      account: payload
    }
  },
  [USER_ACCOUNT_UPDATE]: (state, action) => ({ ...state, error: null, hasError: false, hasUserAccountResponse: false, isRequestingUserAccount: true }),
  [USER_ACCOUNT_RESET]: (state, action) => ({ ...state, ...initialState }),
  [USER_ACCOUNT_ERROR]: (state, action) => ({ ...state, hasError: true, error: action.error }),
  [USER_ACCOUNT_DELETE_REQUEST]: (state, action) => ({ ...state, confirmDeletePasswordIncorrect: false }),
  [USER_ACCOUNT_DELETE_PASSWORD_INCORRECT]: (state, action) => ({ ...state, confirmDeletePasswordIncorrect: true }),
  [USER_ACCOUNT_DELETE_SUCCESS]: (state, action) => ({ ...state, deleteSuccess: true })

}

export default (state = initialState, action) => {
  const handler = handlers[action.type]
  return handler ? handler(state, action) : state
}

export const fetchCurrentUser = () => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/me'

    debug(USER_ACCOUNT_FETCH, { path }, token)
    dispatch({ type: USER_ACCOUNT_FETCH })

    try {
      const response = await client.all('/users')
      debug(USER_ACCOUNT_RECEIVE, { response }, token)
      dispatch({ type: USER_ACCOUNT_RECEIVE, payload: response })
    } catch (error) {
      fault(USER_ACCOUNT_FETCH, { error }, token)
      dispatch(processErrorResponse(USER_ACCOUNT_FETCH, error))
      showMessage({
        message: 'An error occurred while refreshing your profile data.',
        type: 'danger'
      })
    }
  }
}

export const updateCurrentUser = (form) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/me'

    debug(USER_ACCOUNT_UPDATE, { path, form }, token)
    dispatch({ type: USER_ACCOUNT_UPDATE })

    try {
      const response = await client.update(path, form)
      debug(USER_ACCOUNT_RECEIVE, { response }, token)
      dispatch({ type: USER_ACCOUNT_RECEIVE, payload: response })
      showMessage({
        message: 'Your account information has been updated.',
        type: 'success'
      })
    } catch (error) {
      fault(USER_ACCOUNT_UPDATE, { error }, token)
      dispatch(processErrorResponse(USER_ACCOUNT_UPDATE, error))
      showMessage({
        message: 'An error occurred while updating your account.',
        type: 'danger'
      })
    }
  }
}

export const deleteCurrentUser = (password) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/me' 

    debug(USER_ACCOUNT_DELETE_REQUEST, { path }, token)
    dispatch({ type: USER_ACCOUNT_DELETE_REQUEST })

    try {
      const response = await client.request(path, 'DELETE', { password: password }, true)
      debug(USER_ACCOUNT_DELETE_SUCCESS, { response }, token)
      dispatch({ type: USER_ACCOUNT_DELETE_SUCCESS, payload: response })
      showMessage({
        message: 'Your account has been deleted.',
        type: 'success'
      })
    } catch (error) {
      // Check if it is a 401 or 422 status
      if (error.status == 401 || error.status == 422) {
        dispatch({ type: USER_ACCOUNT_DELETE_PASSWORD_INCORRECT })
        showMessage({
          message: 'Incorrect password. Please try again.',
          type: 'danger'
        })
      } else {
        fault(USER_ACCOUNT_DELETE, { error }, token)
        dispatch(processErrorResponse(USER_ACCOUNT_DELETE_REQUEST, error))
        showMessage({
          message: 'An error occurred while deleting your account.',
          type: 'danger'
        })
      }
    }
  }
}
