import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from '../theme'

const { ACCENT, WHITE } = colors

const Badge = props => {
  return (
    <View style={styles.badge}>
      <Text style={styles.lightText}>{props.count}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  badge: {
    position: 'absolute',
    top: 5,
    right: '4%',
    backgroundColor: ACCENT,
    borderRadius: 10,
    paddingHorizontal: 5,
    borderColor: WHITE,
    borderWidth: 1
  },
  lightText: {
    color: WHITE,
    textAlign: 'center',
    fontSize: 12
  }
})

export default Badge
