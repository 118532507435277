import { colors } from '../../theme'

const { ACCENT, GREY, GREY_LT3, WHITE } = colors

export default {
  container: {
    backgroundColor: GREY_LT3,
    height: '100%'
  },
  category: {
    background: {
      paddingVertical: '3%',
      width: '100%'
    },
    container: {
      borderColor: GREY,
      backgroundColor: WHITE,
      borderLeftWidth:1,
      borderBottomWidth:1,
      flexDirection: 'row',
      flex: 1,
      width: '100%'
    },
    icon: {
      position: 'absolute',
      right: 25
    },
    image: {
      width: '100%',
      opacity: 0.4,
    },
    text: {
      fontSize: 20,
      paddingRight: 40,
      paddingLeft: 25
    }
  },
  productList: {
    container: {
      height: '100%',
      backgroundColor: GREY_LT3
    },
    item: {
      flex: 1,
      flexDirection: 'row',
      padding: 10,
      borderWidth: 1,
      borderColor: ACCENT,
      backgroundColor: WHITE,
      margin: 'auto',
      marginBottom: 15,
      width: '100%',
      maxWidth: 1200
    },
    image: {
      width: 75,
      height: 75
    },
    info: {
      flex: 1,
      flexGrow: 1,
      justifyContent: 'space-between',
      paddingLeft: 9,
      width: 0
    },
    title: {
      fontSize: 16
    },
    partNumber: {
      paddingTop: 5,
      fontSize: 10
    },
    stockIndicator: {
    }
  }
}
