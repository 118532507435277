import { showMessage } from 'react-native-flash-message'
import { processErrorResponse } from '../../utilities'
import { bookmark, debug, fault } from '../../logger'
import getClient from '../../services/getClient'
import { formatRequestBody, ERP_PRICING_MULTIPLE_PATH } from '../../services/erpFormattingService'

export const USER_FAVORITE_ERROR = 'USER/FAVORITE/ERROR'
export const USER_FAVORITE_RESET = 'USER/FAVORITE/RESET'
export const USER_FAVORITE_ERP_REQUEST = 'USER/FAVORITE/ERP/REQUEST'
export const USER_FAVORITE_ERP_RECEIVE = 'USER/FAVORITE/ERP/RECEIVE'
export const USER_FAVORITE_FETCH_REQUEST = 'USER/FAVORITE/FETCH/REQUEST'
export const USER_FAVORITE_FETCH_RECEIVE = 'USER/FAVORITE/FETCH/RECEIVE'
export const USER_FAVORITE_CREATE_REQUEST = 'USER/FAVORITE/CREATE/REQUEST'
export const USER_FAVORITE_CREATE_RECEIVE = 'USER/FAVORITE/CREATE/RECEIVE'
export const USER_FAVORITE_DELETE_REQUEST = 'USER/FAVORITE/DELETE/REQUEST'
export const USER_FAVORITE_DELETE_RECEIVE = 'USER/FAVORITE/DELETE/RECEIVE'
export const USER_FAVORITE_SORT_ORDER_REQUEST = 'USER/FAVORITE/SORT_ORDER/REQUEST'
export const USER_FAVORITE_SORT_ORDER_RECEIVE = 'USER/FAVORITE/SORT_ORDER/RECEIVE'

/**
 * Definitions:
 * - error        contains API error string on error
 * - hasError     `true` if API responded with an error
 * - hasResponse  `true` if API request completed successfully
 * - isRequesting `true` if API request has not yet returned a response
 *
 * - favorites    array containing API favorites response
 * - items        array containing favorite products list
 */
const initialState = {
  error: null,
  hasError: false,
  hasErpDataResponse: false,
  hasFavoritesResponse: false,
  isRequestingErpData: false,
  isRequestingFavorites: false,
  favorites: []
}

const handlers = {
  [USER_FAVORITE_FETCH_REQUEST]: (state, action) => ({ ...state, error: null, hasError: false, hasFavoritesResponse: false, isRequestingFavorites: true }),
  [USER_FAVORITE_FETCH_RECEIVE]: (state, action) => {
    const { favorites } = state
    const { payload } = action
    
    // If the favorite already has pricing data, keep it and map it to the products pulled in
    const newFavorites = payload.map(responseFavorite => {
      responseFavorite.product.erp = favorites.find(stateFavorite => 
        stateFavorite.id === responseFavorite.id
      )?.product.erp ?? responseFavorite.product.erp
      return responseFavorite
    })

    return {
      ...state,
      hasFavoritesResponse: true,
      isRequestingFavorites: false,
      favorites: newFavorites
    }
  },
  [USER_FAVORITE_ERP_REQUEST]: (state, action) => ({ ...state, error: null, hasError: false, hasErpDataResponse: false, isRequestingErpData: true }),
  [USER_FAVORITE_ERP_RECEIVE]: (state, action) => {
    const { payload } = action
    const { favorites } = state
    const newFavorites = favorites.map(favorite => {
      favorite.product.erp = payload.products.find(responseItem =>
        responseItem['product_code'] === favorite.product['part_num']
      ) ?? favorite.product.erp
      return favorite
    })

    return {
      ...state,
      hasErpDataResponse: true,
      isRequestingErpData: false,
      favorites: newFavorites
    }
  },
  [USER_FAVORITE_CREATE_REQUEST]: (state, action) => ({ ...state, error: null, hasError: false }),
  [USER_FAVORITE_CREATE_RECEIVE]: (state, action) => {
    const { payload } = action
    const { favorites } = state

    const newFavorites = [ ...favorites ]
    newFavorites.push(payload)

    return {
      ...state,
      favorites: newFavorites
    }
  },
  [USER_FAVORITE_DELETE_REQUEST]: (state, action) => ({ ...state, error: null, hasError: false }),
  [USER_FAVORITE_DELETE_RECEIVE]: (state, action) => {
    const { payload } = action
    const { favorites } = state

    return {
      ...state,
      favorites: favorites.filter(it => it.id !== payload.id)
    }
  },

  [USER_FAVORITE_SORT_ORDER_REQUEST]: (state, action) => ({ ...state, isRequestingFavorites: true }),
  [USER_FAVORITE_SORT_ORDER_RECEIVE]: (state, action) => {
    const { direction, product } = action

    // We need a copy of favorites instead of a reference so the component knows to rerender
    let sortedFavorites = [...state.favorites]

    // I would like to let the server handle this on its own, but waiting for another response feels slow and clunky
    // Find the affected favorites and change their sort order accordingly
    let matching = sortedFavorites.findIndex(element => element.id == product.id)
    let swapping = null
    if (direction == 'next' && !sortedFavorites[matching].is_last) {
      swapping = matching + 1
    } 
    else if (direction == 'previous' && !sortedFavorites[matching].is_first) {
      swapping = matching - 1
    }

    if (swapping != null) {
      // We have to be careful of assign by reference when swapping
      // swap index position
      let temp = {...sortedFavorites[matching]}
      sortedFavorites[matching] = sortedFavorites[swapping]
      sortedFavorites[swapping] = temp

      // swap sorting data
      temp = sortedFavorites[matching].sort_order
      sortedFavorites[matching].sort_order = sortedFavorites[swapping].sort_order
      sortedFavorites[swapping].sort_order = temp
      
      temp = sortedFavorites[matching].is_first
      sortedFavorites[matching].is_first = sortedFavorites[swapping].is_first
      sortedFavorites[swapping].is_first = temp

      temp = sortedFavorites[matching].is_last
      sortedFavorites[matching].is_last = sortedFavorites[swapping].is_last
      sortedFavorites[swapping].is_last = temp
    }

    return { ...state, favorites: sortedFavorites, isRequestingFavorites: false }
  },

  [USER_FAVORITE_ERROR]: (state, action) => ({ ...state, hasError: true, error: action.error }),
  [USER_FAVORITE_RESET]: (state, action) => ({ ...state, ...initialState })
}

export default (state = initialState, action) => {
  const handler = handlers[action.type]
  return handler ? handler(state, action) : state
}

export const fetchFavoritesPrice = () => {
  return async (dispatch , getState) => {
    const state = getState()
    const client = getClient()
    const token = bookmark()

    const { userfavorite: { favorites } } = state

    if (favorites.length > 0) {

      // Make product top level for ERP request
      let fetchProducts = favorites.map(favorite => {
        return favorite.product
      })

      // Only products that don't already have data
      fetchProducts = fetchProducts.filter(favorite => (!favorite.erp))

      const erpReqBody = formatRequestBody(fetchProducts)

      debug(USER_FAVORITE_ERP_REQUEST, { path: ERP_PRICING_MULTIPLE_PATH, body: erpReqBody }, token)
      dispatch({ type: USER_FAVORITE_ERP_REQUEST })
  
      const erpResponse = await client.create(ERP_PRICING_MULTIPLE_PATH, erpReqBody)

      debug(USER_FAVORITE_ERP_RECEIVE, { payload: erpResponse }, token)
      dispatch({ type: USER_FAVORITE_ERP_RECEIVE, payload: erpResponse })
    }
  }
}

export const fetchFavorites = () => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/favorites'

    debug(USER_FAVORITE_FETCH_REQUEST, { path }, token)
    dispatch({ type: USER_FAVORITE_FETCH_REQUEST })

    try {
      const response = await client.all(path)
      debug(USER_FAVORITE_FETCH_RECEIVE, { response }, token)
      dispatch({ type: USER_FAVORITE_FETCH_RECEIVE, payload: response })
    } catch (error) {
      fault(USER_FAVORITE_FETCH_REQUEST, { error }, token)
      dispatch(processErrorResponse(USER_FAVORITE_FETCH_REQUEST, error))
      showMessage({
        message: 'Failed to fetch favorite products. Please try again later.',
        type: 'warning'
      })
    }
  }
}

export const setFavorite = (productId, styleId) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/favorites'
    const body = { product_id: productId }

    debug(USER_FAVORITE_CREATE_REQUEST, { path, body }, token)
    dispatch({ type: USER_FAVORITE_CREATE_REQUEST })

    try {
      const response = await client.create(path, body)
      debug(USER_FAVORITE_CREATE_RECEIVE, response, token)
      dispatch({ type: USER_FAVORITE_CREATE_RECEIVE, payload: response })

      showMessage({
        message: 'Product added to favorites',
        type: 'success'
      })
    } catch (error) {
      fault(USER_FAVORITE_CREATE_REQUEST, { error }, token)
      dispatch(processErrorResponse(USER_FAVORITE_CREATE_REQUEST, error))
      showMessage({
        message: 'Product could not be added to favorites',
        type: 'warning'
      })
    }
  }
}

export const unsetFavorite = (favoriteId) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/favorites'

    debug(USER_FAVORITE_DELETE_REQUEST, { path, favoriteId }, token)
    dispatch({ type: USER_FAVORITE_DELETE_REQUEST })

    try {
      const response = await client.delete(path, favoriteId)
      debug(USER_FAVORITE_DELETE_RECEIVE, { response }, token)
      dispatch({ type: USER_FAVORITE_DELETE_RECEIVE, payload: { id: favoriteId } })

      showMessage({
        message: 'Product removed from favorites',
        type: 'success'
      })
    } catch (error) {
      fault(USER_FAVORITE_DELETE_REQUEST, error, token)
      dispatch(processErrorResponse(USER_FAVORITE_DELETE_REQUEST, error))
      showMessage({
        message: 'Product could not be removed from favorites',
        type: 'warning'
      })
    }
  }
}

export const changeFavoriteSortOrder = (favoriteId, direction) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/favorites/' + favoriteId
    const body = {
      sort_order: direction
    }

    debug(USER_FAVORITE_SORT_ORDER_REQUEST, { path, body }, token)
    dispatch({ type: USER_FAVORITE_SORT_ORDER_REQUEST })

    try {
      const response = await client.update(path, body)
      dispatch({ type: USER_FAVORITE_SORT_ORDER_RECEIVE, product: response, direction: direction })
    } catch (error) {
      fault(USER_FAVORITE_SORT_ORDER_REQUEST, error, token)
      dispatch(processErrorResponse(USER_FAVORITE_SORT_ORDER_REQUEST, error))

      showMessage({
        message: 'Could not change favorite position.',
        type: 'warning'
      })
    }
  }
}

export const resetFavorites = () => {
  return (dispatch) => dispatch({ type: USER_FAVORITE_RESET })
}
