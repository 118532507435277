import { showMessage } from 'react-native-flash-message'
import { processErrorResponse } from '../../utilities'
import { bookmark, debug, fault } from '../../logger'
import getClient from '../../services/getClient'

export const ORDER_CANCEL = 'ORDER/CANCEL'
export const ORDER_CANCEL_UPDATE = 'ORDER/CANCEL/UPDATE'
export const ORDER_HISTORY_FETCH = 'ORDER/HISTORY/FETCH'
export const ORDER_HISTORY_RECEIVE = 'ORDER/HISTORY/RECEIVE'
export const ORDER_HISTORY_ERP_FETCH = 'ORDER/HISTORY/ERP/FETCH'
export const ORDER_HISTORY_ERP_RECEIVE = 'ORDER/HISTORY/ERP/RECEIVE'

const initialState = {
    isRequestingData: false,
    loadedInitialPage: false,
    loadedUpToPage: 0,
    pageCount: 0,
    orders: []
}

const handlers = {
    [ORDER_HISTORY_FETCH]: (state, action) => ({ ...state, isRequestingData: true }),
    [ORDER_HISTORY_RECEIVE]: (state, action) => {
        const { payload } = action
        return {
            ...state,
            loadedInitialPage: true,
            loadedUpToPage: payload['current_page'],
            pageCount: payload['last_page'],
            isRequestingData: false,
            orders: state.orders.concat(payload.data)
        }
    }
}

export default (state = initialState, action) => {
    const handler = handlers[action.type]
    return handler ? handler(state, action) : state
}


export const fetchOrderHistory = (page = 1) => {
    return async (dispatch) => {
        const client = getClient()
        const token = bookmark()
        const path = '/orders'

        debug(ORDER_HISTORY_FETCH, { path, page }, token)
        dispatch({ type: ORDER_HISTORY_FETCH })

        try {
            const response = await client.all(path, { page })
            debug(ORDER_HISTORY_RECEIVE, { response }, token)
            dispatch({ type: ORDER_HISTORY_RECEIVE, payload: response })
        } catch (error) {
            fault(ORDER_HISTORY_FETCH, { error }, token)
            dispatch(processErrorResponse(ORDER_HISTORY_FETCH, error))
            showMessage({
                message: 'Failed to fetch order history. Please try again later.',
                type: 'danger'
            })
        }
    }
}

export const cancelOrder = (orderId) => {
    return async (dispatch) => {
        const client = getClient()
        const token = bookmark()
        const path = `/orders/${orderId}`

        debug(ORDER_CANCEL, { path, id: orderId }, token)
        dispatch({ type: ORDER_CANCEL })

        try {
            const response = await client.delete(path, orderId)
            debug(ORDER_CANCEL_UPDATE, { response }, token)
            dispatch({ type: ORDER_CANCEL_UPDATE, payload: { id: orderId } })
            showMessage({
                message: `Order ID#${orderId} cancelled`,
                type: 'warning'
            })
        } catch (error) {
            fault(ORDER_CANCEL, { error }, token)
            dispatch(processErrorResponse(ORDER_CANCEL, error))
            showMessage({
                message: 'Failed to cancel order. Please try again later.',
                type: 'danger'
            })
        }
    }
}
