import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { colors } from '../../theme'

import FavoriteList from './FavoriteListScreen'

const { ACCENT, WHITE } = colors

const Stack = createStackNavigator()

const navigatorOptions = {
  headerTintColor: WHITE,
  headerStyle: {
    backgroundColor: ACCENT,
  }
}

function FavoritesStack() {
  return (
    <Stack.Navigator screenOptions={navigatorOptions}>
      <Stack.Screen name="FavoriteList" component={FavoriteList} options={{ title: 'Favorites', headerBackTitle: ' ' }}/>
    </Stack.Navigator>
  )
}

export default FavoritesStack

