import React from 'react'
import { View } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import Cart from './features/Cart'
import Catalog from './features/Catalog'
import ProductSearch from './features/ProductSearch'
import UserAccount from './features/UserAccount'
import UserAuth from './features/UserAuth'
import UserFavorite from './features/UserFavorite'
import UserRecover from './features/UserRecover'
import UserRegister from './features/UserRegister'

import { Badge } from './components'
import { colors } from './theme'

const { ACCENT, GREY, WHITE } = colors

const loginNavigatorOptions = {
    headerTintColor: WHITE,
    headerStyle: {
      backgroundColor: ACCENT,
    }
}


function LoginNavigator() {
  const Stack = createStackNavigator()
  return (
    <Stack.Navigator screenOptions={loginNavigatorOptions}>
      <Stack.Screen name='UserAuth' component={UserAuth} options={{ title: 'Login' }}/>
      <Stack.Screen name='UserRegister' component={UserRegister} options={{ title: 'Register', headerBackTitle: ' ' }}/>
      <Stack.Screen name='UserRecover' component={UserRecover} options={{ title: 'Password Recover', headerBackTitle: ' ' }}/>
    </Stack.Navigator>
  )
}

const menuNavigatorOptions = {
    showIcon: true,
    swipeEnabled: false,
    activeTintColor: ACCENT,
    inactiveTintColor: GREY,
    indicatorStyle: {
      backgroundColor: WHITE
    },
    style: {
      backgroundColor: WHITE,
      height: 60,
      // iOS
      shadowOpacity: 0.3,
      shadowRadius: 3,
      shadowOffset: {
        height: 1,
        width: 0
      },
      // android
      elevation: 10
    },
    labelStyle: {
      fontSize: 14
    },
    iconStyle: {
      width: 60,
      height: 25
    },
  tabBarPosition: 'bottom'
}


function MenuNavigator(menuProps) {
  const Tab = createBottomTabNavigator()
  return (
    <Tab.Navigator screenOptions={menuNavigatorOptions}>
      <Tab.Screen
        name="Home"
        component={Catalog}
        options={{
          title: 'Home',
          showLabel: true,
          tabBarIcon: ({ focused }) =>
            (<FontAwesome name='home' size={28} color={focused ? ACCENT : GREY} />)
        }}
      />
      <Tab.Screen
        name="UserFavorite"
        component={UserFavorite}
        options={{
          title: 'Favorites',
          showLabel: true,
          tabBarIcon: ({ focused }) =>
            (<FontAwesome name='heart' size={28} color={focused ? ACCENT : GREY} />)
        }}
      />
      <Tab.Screen
        name="UserAccount"
        component={UserAccount}
        options={{
          title: 'Account',
          tabBarIcon: ({ focused }) =>
            (<FontAwesome name='user' size={28} color={focused ? ACCENT : GREY} />)
        }}
      />
      <Tab.Screen
        name="ProductSearch"
        component={ProductSearch}
        options={{
          title: 'Search',
          tabBarIcon: ({ focused }) =>
            (<FontAwesome name='search' size={28} color={focused ? ACCENT : GREY} />)
        }}
        />
      <Tab.Screen
        name="Cart"
        component={Cart}
        options={{
          title: 'Cart',
          showLabel: false,
          tabBarIcon: ({ focused }) => {
            const cartCount = menuProps.screenProps.cartCount
            return (
              <View style={{
                alignItems: 'center', alignSelf: 'center', height: '100%',
                justifyContent: 'center', opacity: 1, position: 'absolute', width: '100%'
              }}>
                <FontAwesome name='shopping-cart' size={28} color={focused ? ACCENT : GREY} />
                {cartCount ? <Badge count={cartCount} /> : <View />}
              </View>
            )
          }
        }}
      />
    </Tab.Navigator>
  )
}

export {
  LoginNavigator,
  MenuNavigator
}
