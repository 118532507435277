import { colors } from '../../theme'

const { ACCENT, BLACK, DANGER, GREY, GREY_LT2, GREY_LT3, WHITE } = colors

export default {
  content: {
    container: {
      flex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: GREY_LT3
    },
    flexContainer: {
      flex: 1
    },
    flexRow: {
      flexDirection: 'row'
    },
    centerWrap: {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%'
    },
    emptyIcon: {
    },
    emptyText: {
      color: GREY,
      fontSize: 24
    },
    panelBlue: {
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: ACCENT,
      paddingVertical: 7,
      paddingHorizontal: 7,
      backgroundColor: GREY_LT2,
      marginBottom: 10
    },
    titleText: {
      fontSize: 14
    },
    titleTextLarge: {
      fontSize: 18,
      marginVertical: 10
    },
    label: {
      fontSize: 10,
      marginVertical: 10
    },
    prodImage: {
      width: 100,
      height: 100,
      resizeMode: 'contain',
      marginRight: 7,
      marginBottom: 5
    },
    favImage: {
      width: 20,
      height: 20,
      resizeMode: 'contain',
      alignSelf: 'flex-end'
    },
    doubleWrap: {},
    infoWrap: {
      flex: 4
    },
    endWrap: {
      flex: 1
    },
    buttonContainer: {
      width: '80%',
      maxWidth: 300,
      alignSelf: 'center',
      marginVertical: 20
    },
    checkoutButton: {
      marginBottom: 0
    },
    clearButton: {
      backgroundColor: DANGER
    },
    actionButtons: {
      marginBottom: 15,
      alignItems: 'center',
      justifyContent: 'center'
    },
    item: {
      flexContainer: {
        flex: 1
      },
      flexRow: {
        flexDirection: 'row'
      },
      panelBlue: {
        borderWidth: 1,
        borderColor: ACCENT,
        paddingVertical: 7,
        paddingHorizontal: 7,
        backgroundColor: WHITE,
        margin: 'auto',
        marginBottom: 10,
        width: '100%',
        maxWidth: 1200
      },
      productHeader: {
        marginBottom: 10
      },
      productTitle: {
        fontSize: 14
      },
      productSubTitle: {
        fontSize: 10
      },
      prodImage: {
        width: 100,
        height: 100,
        resizeMode: 'contain',
        marginRight: 7,
        marginBottom: 5
      },
      infoWrap: {
        flex: 4
      },
      endWrap: {
        height: 34,
        alignItems: 'center',
        justifyContent: 'center'
      },
      buttonContainerContainer: {
        alignItems: 'center'
      },
      buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: 500
      },
      quantity: {
        flexDirection: 'row',
        height: 34,
        borderRadius: 17,
        borderWidth: 1,
        borderColor: ACCENT,
        paddingVertical: 5,
        paddingHorizontal: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        width: 110
      },
      priceIndicator: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 10
      },
      priceTotalContainer: {
        paddingRight: 5,
        paddingLeft: 5,
        flex: 4
      },
      priceTotalHeader: {
        textAlign: 'right'
      },
      priceTotalValue: {
        textAlign: 'right'
      },
      priceListContainer: {
        paddingRight: 5,
        paddingLeft: 5,
        flex: 3
      },
      priceListHeader: {
        textAlign: 'right'
      },
      priceListValue: {
        textAlign: 'right'
      },
      priceSpecialContainer: {
        borderLeftWidth: 1,
        borderLeftColor: BLACK,
        paddingRight: 5,
        paddingLeft: 5,
        flex: 3
      },
      priceSpecialHeader: {
        textAlign: 'right'
      },
      priceSpecialValue: {
        textAlign: 'right',
        color: DANGER
      }
    }
  },
  checkout: {
    container: {
      marginVertical: 30,
      marginHorizontal: 40
    },
    header: {
      flex: 1,
      alignItems: 'center'
    },
    headerLabel: {
      fontSize: 18,
      marginBottom: 10
    },
    headerButton: {
      marginTop: 10,
      alignSelf: 'stretch'
    },
    inputControl: {
      borderWidth: 2,
      borderColor: ACCENT,
      backgroundColor: WHITE,
      padding: 15
    },
    inputContainer: {
      margin: 'auto',
      width: '100%',
      maxWidth: 1200
    },
    inputGroup: {
      flex: 1,
      marginBottom: 30
    },
    inputLabel: {
    },
    picker: {
      borderWidth: 2,
      borderColor: ACCENT,
      backgroundColor: WHITE
      // paddingRight: 40,
      // paddingLeft: 40,
      // paddingTop: 15,
      // paddingBottom: 15
    },
    screen: {
      flexDirection: 'column',
      backgroundColor: GREY_LT3
    },
    locationInput: {
      flex: 1,
      marginBottom: 10
    },
    orLabel: {
      paddingBottom: 10,
      textAlign: 'center',
    },
    placeOrderButton: {
      maxWidth: 200
    }
  },
  complete: {
    container: {
      flex: 1,
      backgroundColor: GREY_LT3
    },
    bottomHalf: {
      flex: 4,
      backgroundColor: GREY_LT3,
    },
    imageWrap: {
      padding: '5%',
      flex: 1,
      justifyContent: 'center'
    },
    image: {
      width: '100%',
      height: '100%',
      resizeMode: 'contain'
    },
    textWrap: {
      flex: 1,
      alignItems: 'center'
    },
    text: {
      color: GREY,
      fontSize: 24,
      marginVertical: 24
    },
    textSmall: {
      color: GREY,
      fontSize: 20,
      textAlign: 'center',
      width: '90%'
    }
  }
}
