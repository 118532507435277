import queryString from 'query-string'
import isEmpty from 'lodash/isEmpty'

import { bookmark, debug, warn, fault } from '../logger'

export default class ApiClient {
  constructor ({ host, apiPath, clientSecret, clientId }) {
    this.authHost = host
    this.host = `${host}${apiPath}`

    this.clientSecret = clientSecret
    this.clientId = clientId
  }

  injectStore (store) {
    this.reduxStore = store
  }

  getAuthUser = () => this.reduxStore.getState().userauth

  buildHeaders ({ withToken } = { withToken: false }) {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/json')
    if (withToken) {
      const { tokenAccess } = this.getAuthUser()
      headers.append('Authorization', `Bearer ${tokenAccess}`)
    }
    return headers
  }

  async login (credentials) {
    const url = `${this.authHost}oauth/token`
    const body = {
      scope: '',
      client_id: this.clientId,
      client_secret: this.clientSecret,
      grant_type: 'password',
      username: credentials.email,
      password: credentials.password
    }
    const options = {
      method: 'POST',
      headers: this.buildHeaders(),
      body: JSON.stringify(body)
    }

    return ApiClient.doFetch(url, options)
  }

  async anonCreate (path, body) {
    const url = `${this.host}${path}`
    const options = {
      method: 'POST',
      headers: this.buildHeaders(),
      body: JSON.stringify(body)
    }

    return ApiClient.doFetch(url, options)
  }

  async all (path, params = {}) {
    const urlParams = isEmpty(params) ? '' : queryString.stringify(params)
    const url = `${this.host}${path}?${urlParams}`
    const options = {
      method: 'GET',
      headers: this.buildHeaders({ withToken: true })
    }

    return ApiClient.doFetch(url, options)
  }

  async find (path, id, params = {}) {
    const urlParams = isEmpty(params) ? '' : queryString.stringify(params)
    const url = `${this.host}${path}/${id}?${urlParams}`
    const options = {
      method: 'GET',
      headers: this.buildHeaders({ withToken: true })
    }

    return ApiClient.doFetch(url, options)
  }

  async create (path, body) {
    const url = `${this.host}${path}`
    const options = {
      method: 'POST',
      headers: this.buildHeaders({ withToken: true }),
      body: JSON.stringify(body)
    }

    return ApiClient.doFetch(url, options)
  }

  async update (path, body) {
    const url = `${this.host}${path}`
    const options = {
      method: 'PUT',
      headers: this.buildHeaders({ withToken: true }),
      body: JSON.stringify(body)
    }

    return ApiClient.doFetch(url, options)
  }

  async delete (path, id = null) {
    let url = `${this.host}${path}`
    if (id) {
      url += `/${id}`
    }
    const options = {
      method: 'DELETE',
      headers: this.buildHeaders({ withToken: true })
    }

    return ApiClient.doFetch(url, options)
  }

  async request (path, method, body = null, includeStatus = false) {
    let options = {
      method: method,
      headers: this.buildHeaders({ withToken: true }),
    }

    if (body) {
      options.body = JSON.stringify(body)
    }

    return ApiClient.doFetch(`${this.host}${path}`, options, includeStatus)
  }

  static async doFetch (url, options, includeStatus) {
    let json
    const token = bookmark()

    debug('HTTP REQUEST', { url, options }, token)
    const response = await fetch(url, options)

    if (response.ok) {
      json = await response.json()
    } else {
      if (response.status >= 400) {
        json = await response.json()
        warn('HTTP REQUEST ERROR', { url, options, response, json }, token)
      } else if (response.status >= 500) {
        json = { message: 'An error occurred. Try again later.' }
        warn('API SERVER ERROR', { url, options, response }, token)
      } else {
        json = { message: 'An error occurred. Try again later.' }
        warn('UNKNOWN ERROR', { url, options, response }, token)
      }

      // I don't like this too much but it is better than refactoring everything
      if (includeStatus) {
        throw { body: json, status: response.status }
      } else {
        throw json
      }
    }

    debug('HTTP RESPONSE', { response, json }, token)

    // I don't like this too much but it is better than refactoring everything
    if (includeStatus) {
      return { body: json, status: response.status}
    } else {
      return json
    }
  }
}
