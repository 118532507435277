import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, Text, View } from 'react-native'
import { colors } from '../theme'

const { ACCENT, WHITE } = colors

class TitleOnlyHeader extends React.Component {
  render () {
    const { title } = this.props
    const finalTitle = (title.length > 18)
      ? `${title.substring(0, 18)}...` : title

    return (
      <View style={styles.container}>
        <View style={styles.backButton} />
        <Text style={styles.titleText}>
          {finalTitle}
        </Text>
        <View style={styles.right} />
      </View>
    )
  }
}

TitleOnlyHeader.propTypes = {
  title: PropTypes.string.isRequired
}

const styles = StyleSheet.create({
  container: {
    height: 84,
    backgroundColor: ACCENT,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20
  },
  backButton: {
    flex: 1
  },
  right: {
    flex: 1
  },
  backButtonImage: {
    height: 28
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: WHITE
  }
})

export default TitleOnlyHeader
