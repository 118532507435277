import React from 'react'
import { connect } from 'react-redux'
import FlashMessage from 'react-native-flash-message'
import { StatusBar, StyleSheet, View } from 'react-native'
import { LoginNavigator, MenuNavigator } from './navigators'
import { colors } from './theme'

import { fetchCurrentUser } from './features/UserAccount/behaviors'
import { getUserAccountIsLoaded } from './features/UserAccount/selectors'

import {apiGetCartItems} from './features/Cart/behaviors'

const { ACCENT, WHITE } = colors

// TODO: implement SafeAreaView to keep the tab bar from going to the bottom on iPhone X.
class RootComponent extends React.Component {
  componentDidMount () {
    const { isLoggedIn, hasUserAccount } = this.props
    if (isLoggedIn && !hasUserAccount) {
      this.props.fetchCurrentUser()
      this.props.apiGetCartItems()
    }
  }

  render () {
    const { isLoggedIn, cartCount } = this.props
    return (
      <View style={styles.container}>
        <StatusBar barStyle='light-content' />
        { isLoggedIn ? (
          <MenuNavigator
            style={styles.tabs}
            screenProps={{ cartCount }} />
        ) : (
          <LoginNavigator />
        )}
        <FlashMessage position='top' />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: ACCENT,
    flex: 1,
    paddingTop: 20
  },
  tabs: {
    backgroundColor: WHITE
  }
})

const mapStateToProps = state => ({
  isLoggedIn: state.userauth.isLoggedIn,
  cartCount: state.cart.items.length,
  hasUserAccount: getUserAccountIsLoaded(state)
})

export default connect(mapStateToProps, { fetchCurrentUser, apiGetCartItems })(RootComponent)
