import { showMessage } from 'react-native-flash-message'
import { CommonActions } from '@react-navigation/native';
import { processErrorResponse } from '../../utilities'
import { bookmark, debug, fault } from '../../logger'
import getClient from '../../services/getClient'

export const USERAUTH_RECOVER_REQUEST = 'USERAUTH/RECOVER/REQUEST'
export const USERAUTH_RECOVER_RECEIVE = 'USERAUTH/RECOVER/RECEIVE'

const resetLogoutNavigationAction = CommonActions.navigate({
  name: 'UserAuth',
  params: {
    index: 0,
  }
})

export const sendRecoverEmail = (body, navigation) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/forgot-password'

    debug(USERAUTH_RECOVER_REQUEST, { path, body }, token)

    try {
      const response = await client.anonCreate(path, body)
      debug(USERAUTH_RECOVER_REQUEST, { response }, token)
      showMessage({
        message: 'A password reset link has been sent to your email address.',
        type: 'success'
      })
      navigation.dispatch(resetLogoutNavigationAction)
    } catch (error) {
      if (error.message === 'The given data was invalid.') {
        showMessage({
          message: 'Please enter a valid email address.',
          type: 'danger'
        })
      } else {
        fault(USERAUTH_RECOVER_REQUEST, { error }, token)
        dispatch(processErrorResponse(USERAUTH_RECOVER_REQUEST, error))
        showMessage({
          message: 'An error occurred while recovering your account.',
          type: 'danger'
        })
        navigation.dispatch(resetLogoutNavigationAction)
      }
    }
  }
}
