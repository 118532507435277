const getProducts = state => state.search.items
const getCartItems = state => state.cart.items

export const getSearchErrorState = state =>
  state.search.hasError ? state.search.error : false

export const getSearchProducts = (state) => getProducts(state)
export const getSearchLoadingState = state => state.search.isRequestingResults
export const getSearchErpLoadingState = state => state.search.isRequestingPricing
export const getSearchContentState = state => state.search.hasResults
export const getSearchErpDataState = state => state.search.hasPricing
export const getSearchProductById = (state, productId) =>
  getProducts(state).find(it => it.id === productId)

// cart state selectors
export const getCartItemByProductId = (state, productId, styleId) =>
  getCartItems(state).find(it => it.itemId === productId && it.styleId === styleId)
