import { showMessage } from 'react-native-flash-message'
import { processErrorResponse } from '../../utilities'
import { bookmark, debug, fault } from '../../logger'
import getClient from '../../services/getClient'

export const LOCATION_ERROR = 'LOCATION/ERROR'
export const LOCATION_RESET = 'LOCATION/RESET'
export const LOCATION_FETCH = 'LOCATION/FETCH'
export const LOCATION_PICKUP_RECEIVE = 'LOCATION/PICKUP/RECEIVE'
export const LOCATION_SHIPPING_RECEIVE = 'LOCATION/SHIPPING/RECEIVE'
export const LOCATION_SHIPPING_CREATE = 'LOCATION/SHIPPING/CREATE'
export const LOCATION_SHIPPING_UPDATE = 'LOCATION/SHIPPING/UPDATE'
export const LOCATION_SHIPPING_DELETE = 'LOCATION/SHIPPING/DELETE'
export const LOCATION_SHIPPING_REMOVE = 'LOCATION/SHIPPING/REMOVE'

const initialState = {
  error: null,
  hasError: false,
  hasPickupLocations: false,
  hasShippingLocations: false,
  isRequestingShipping: false,
  isRequestingPickup: false,
  locations: []
}

const handlers = {
  [LOCATION_PICKUP_RECEIVE]: (state, action) => {
    const { locations } = state
    const { payload } = action

    const newLocations = locations.filter(it => it.type !== 'PICKUP')
    payload.map(it => newLocations.push({ ...it, type: 'PICKUP' }))

    return {
      ...state,
      isRequestingPickup: false,
      hasPickupLocations: true,
      locations: newLocations
    }
  },
  [LOCATION_SHIPPING_RECEIVE]: (state, action) => {
    const { locations } = state
    const { payload } = action

    const newLocations = locations.filter(it => it.type !== 'SHIPPING')
    payload.map(it => newLocations.push({ ...it, type: 'SHIPPING' }))

    return {
      ...state,
      isRequestingShipping: false,
      hasShippingLocations: true,
      locations: newLocations
    }
  },
  [LOCATION_FETCH]: (state, action) => ({
    ...state,
    error: null,
    hasError: false,
    isRequestingPickup: true,
    hasPickupLocations: false,
    isRequestingShipping: true,
    hasShippingLocations: false
  }),
  [LOCATION_ERROR]: (state, action) => ({ ...state, hasError: true, error: action.error }),
  [LOCATION_RESET]: (state, action) => ({ ...state, ...initialState })
}

export default (state = initialState, action) => {
  const handler = handlers[action.type]
  return handler ? handler(state, action) : state
}

export const fetchLocations = () => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const pickupPath = '/pickup-locations'
    const shippingPath = '/shipping-locations'

    debug(LOCATION_FETCH, { pickupPath, shippingPath }, token)
    dispatch({ type: LOCATION_FETCH })

    try {
      const promises = [
        client.all(pickupPath),
        client.all(shippingPath)
      ]
      const resolved = await Promise.all(promises)
      debug(LOCATION_PICKUP_RECEIVE, { location: resolved[0] }, token)
      debug(LOCATION_SHIPPING_RECEIVE, { location: resolved[1] }, token)
      dispatch({ type: LOCATION_PICKUP_RECEIVE, payload: resolved[0] })
      dispatch({ type: LOCATION_SHIPPING_RECEIVE, payload: resolved[1] })
    } catch (error) {
      fault(LOCATION_FETCH, { error }, token)
      dispatch(processErrorResponse(LOCATION_FETCH, error))
      showMessage({
        message: 'Failed to fetch locations list. Please try again later.',
        type: 'danger'
      })
    }
  }
}

export const createShippingLocation = (form) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = `/shipping-locations`

    debug(LOCATION_SHIPPING_CREATE, { path, form }, token)
    dispatch({ type: LOCATION_SHIPPING_CREATE })

    try {
      const response = await client.create(path, form)
      debug(LOCATION_SHIPPING_RECEIVE, { response }, token)
      dispatch({ type: LOCATION_SHIPPING_RECEIVE, payload: [response] })
      showMessage({
        message: 'New shipping location created.',
        type: 'success'
      })
    } catch (error) {
      fault(LOCATION_SHIPPING_CREATE, { error }, token)
      dispatch(processErrorResponse(LOCATION_SHIPPING_CREATE, error))
      showMessage({
        message: 'Failed to create new shipping location.',
        type: 'danger'
      })
    }
  }
}

export const updateShippingLocation = (form) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = `/shipping-locations/${form.id}`

    debug(LOCATION_SHIPPING_UPDATE, { path, form }, token)
    dispatch({ type: LOCATION_SHIPPING_UPDATE })

    try {
      const response = await client.update(path, form)
      debug(LOCATION_SHIPPING_RECEIVE, { response }, token)
      dispatch({ type: LOCATION_SHIPPING_RECEIVE, payload: [response] })
      showMessage({
        message: 'Shipping location updated.',
        type: 'success'
      })
    } catch (error) {
      fault(LOCATION_SHIPPING_UPDATE, { error }, token)
      dispatch(processErrorResponse(LOCATION_SHIPPING_UPDATE, error))
      showMessage({
        message: 'Failed to update shipping location.',
        type: 'danger'
      })
    }
  }
}

export const deleteShippingLocation = (locationId) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = `/shipping-locations`

    debug(LOCATION_SHIPPING_DELETE, { path, id: locationId }, token)
    dispatch({ type: LOCATION_SHIPPING_DELETE })

    try {
      const response = await client.delete(path, locationId)
      debug(LOCATION_SHIPPING_REMOVE, { response }, token)
      dispatch({ type: LOCATION_SHIPPING_REMOVE, payload: { id: locationId } })
      showMessage({
        message: 'Shipping location deleted.',
        type: 'success'
      })
    } catch (error) {
      fault(LOCATION_SHIPPING_DELETE, { error }, token)
      dispatch(processErrorResponse(LOCATION_SHIPPING_DELETE, error))
      showMessage({
        message: 'Failed to delete shipping location.',
        type: 'danger'
      })
    }
  }
}

export const resetLocations = () => {
  return (dispatch) => dispatch({ type: LOCATION_RESET })
}
