import { colors } from '../../theme'
const { ACCENT, WHITE } = colors

export default {
    headerWrap: {
        flex: 1,
        backgroundColor: WHITE,
        borderBottomWidth: 2,
        borderBottomColor: ACCENT,
        padding: 20
    },
    logo: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain'
    }
}
