import React from 'react'
import { View, Image } from 'react-native'
import { logo } from '../../theme'
import styles from './styles'

class LogoBanner extends React.Component {
  render () {
    return (
      <View style={styles.headerWrap}>
        <Image style={styles.logo} source={logo} />
      </View>
    )
  }
}

export default LogoBanner
