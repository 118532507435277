import React from 'react'
import PropTypes from 'prop-types'
import {
  StyleSheet,
  Text,
  TouchableOpacity
} from 'react-native'
import { FontAwesome } from '@expo/vector-icons'

const ListRow = ({ icon, title, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.row}>
      <FontAwesome name={icon} size={32} color={'#232122'} />
      <Text style={styles.text}>{title}</Text>
    </TouchableOpacity>
  )
}

ListRow.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired
}

const styles = StyleSheet.create({
  row: {
    height: 64,
    width: '100%',
    backgroundColor: 'white',
    borderBottomWidth: 1,
    alignItems: 'center',
    flexDirection: 'row',
    borderColor: '#A6A6A6',
    paddingLeft: 25
  },
  text: {
    fontSize: 20,
    paddingLeft: 25
  }
})

export default ListRow
