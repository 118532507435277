import React from 'react'
import PropTypes from 'prop-types'
import { Image, Text, TextInput, View, ActivityIndicator } from 'react-native'
import { ButtonPill, CurrencyText, FavoriteButton } from '../../components'
import { colors } from '../../theme'
import styles from './styles'

const { ACCENT_DK, GREY_LT3 } = colors
const IMAGE_PLACEHOLDER = require('../../../assets/images/missing-image.png')

const CartPriceTable = ({ item }) => {
  const { hasErpData, itemListPrice } = item
  if (!hasErpData) {
    return (
      <View>
        <ActivityIndicator size='small' color="gray" />
      </View>
    )
  } else {
    const { list_price_each: listPriceEach, discount_price_each: discountPriceEach, discount_price_total: lineTotal} = item.erp
    const listPrice = listPriceEach ?? itemListPrice
  
    return (
    <View style={styles.content.item.priceIndicator}>
      <View style={styles.content.item.priceListContainer}>
        <Text style={styles.content.item.priceListHeader}>List Price</Text>
        <CurrencyText style={styles.content.item.priceListValue} value={listPrice ?? 0} />
      </View>
  
      <View style={styles.content.item.priceSpecialContainer}>
        <Text style={styles.content.item.priceSpecialHeader}>Your Price</Text>
        <CurrencyText style={styles.content.item.priceSpecialValue} value={discountPriceEach ?? 0} />
      </View>
  
      <View style={styles.content.item.priceTotalContainer}>
        <Text style={styles.content.item.priceTotalHeader}>Line Total</Text>
        <CurrencyText style={styles.content.item.priceTotalValue} value={lineTotal ?? 0} />
      </View>
    </View>
    )
  }
}

CartPriceTable.propTypes = {
  item: PropTypes.object.isRequired
}

CartPriceTable.defaultProps = {
  item: {}
}

class CartListItem extends React.Component {
  handleItemQuantityChange (styleId, quantity) {
    this.props.updateCartItem(styleId, quantity)
  }

  handleRemoveFromCartPress (styleId) {
    this.props.removeCartItem(styleId)
  }

  handleZeroItemQuantity (styleId, quantity) {
    if (!quantity) {
      this.props.removeCartItem(styleId)
    }
  }

  render () {
    const { item, showPricing } = this.props
    const product = {
      id: item.itemId,
      sku: item.itemSku,
      user_favorite_id: item.itemFavId
    }
    return (
      <View style={styles.content.item.panelBlue}>
        <View>
          <View style={[styles.content.item.flexRow]}>
            <Image
              style={styles.content.item.prodImage}
              source={item.itemImage ? { uri: item.itemImage } : IMAGE_PLACEHOLDER} />

            <View style={styles.content.item.flexContainer}>
              <View style={styles.content.item.flexRow}>
                <View style={styles.content.item.infoWrap}>
                  <View style={styles.content.item.productHeader}>
                    <Text style={styles.content.item.productTitle}>{item.itemTitle}</Text>
                    <Text style={styles.content.item.productSubTitle}>{item.productCode}</Text>
                    <Text style={styles.content.item.productSubTitle}>{item.itemManufacturer}</Text>
                  </View>

                  { Boolean(showPricing) && 
                    <CartPriceTable
                      item={item}
                    />
                  }
                </View>
              </View>
            </View>
          </View>

          <View style={styles.content.item.buttonContainerContainer}>
            <View style={styles.content.item.buttonContainer}>
              <TextInput
                returnKeyType='done'
                placeholder={'quantity'}
                keyboardType='number-pad'
                underlineColorAndroid={GREY_LT3}
                style={styles.content.item.quantity}
                value={item.quantity ? String(item.quantity) : ''}
                onChangeText={(t) => this.handleItemQuantityChange(item.styleId, parseInt(t))}
                onEndEditing={() => this.handleZeroItemQuantity(item.styleId, item.quantity)}
              />

              <ButtonPill
                width={120}
                text={'Remove'}
                color={ACCENT_DK}
                onPress={() => this.handleRemoveFromCartPress(item.styleId)}
              />

              <View style={styles.content.item.endWrap}>
                <FavoriteButton
                  product={product}
                  style={{ id: item.styleId }}
                  setFavorite={this.props.setFavorite}
                  unsetFavorite={this.props.unsetFavorite} />
              </View>
            </View>
          </View>
        </View>
      </View>
    )
  }
}

export default CartListItem
