import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { colors } from '../../theme'

import { FontAwesome } from '@expo/vector-icons'
import { CommonActions } from '@react-navigation/native';

import ShippingEdit from '../Location/ShippingLocationFormScreen'
import ShippingLocations from '../Location/ShippingLocationsScreen'
import PickupLocations from '../Location/PickupLocationsScreen'

import OrderDetail from '../OrderHistory/OrderHistoryDetailScreen.js'
import OrderHistory from '../OrderHistory/OrderHistoryListScreen.js'

import AccountInfo from './AccountInfoScreen'
import AccountMenu from './AccountMenuScreen'

const { ACCENT, WHITE } = colors

const Stack = createStackNavigator()

const navigatorOptions = {
  headerTintColor: WHITE,
  headerStyle: {
    backgroundColor: ACCENT,
  }
}

function AccountStack() {
  return (
    <Stack.Navigator screenOptions={navigatorOptions}>
      <Stack.Screen name="AccountMenu" component={AccountMenu} options={{ headerShown: false }} />
      <Stack.Screen name="AccountInfo" component={AccountInfo} options={{ title: 'Account Info', headerBackTitle: ' ' }} />
      <Stack.Screen name="OrderDetail" component={OrderDetail} options={{ title: 'Order', headerBackTitle: ' ' }} />
      <Stack.Screen name="OrderHistory" component={OrderHistory} options={{ title: 'History', headerBackTitle: ' ' }} />
      <Stack.Screen name="ShippingEdit" component={ShippingEdit} options={(props) => (
        { 
          title: props.route.params.isNew ? 'New Location' : 'Edit Location',
          headerBackTitle: ' ' 
        })}
      />
      <Stack.Screen name="ShippingLocations" component={ShippingLocations} options={(props) => (
        {
          title: 'Shipping Locations',
          headerBackTitle: ' ',
          headerRight: () => <FontAwesome.Button name="plus" backgroundColor={ACCENT} onPress={() => props.navigation.navigate('ShippingEdit', { isNew: true })} />
        })}
      />
      <Stack.Screen name="PickupLocations" component={PickupLocations} options={{ title: 'Pickup Locations', headerBackTitle: ' ' }}/>
    </Stack.Navigator>
  )
}

export default AccountStack

