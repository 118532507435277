import { colors } from '../../theme'

const { ACCENT, GREY_LT3, GREY_DK2, WHITE } = colors

export default {
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: GREY_LT3
  },
  bottomHalf: {
    flex: 4,
    backgroundColor: GREY_LT3
  },
  formWrap: {
    padding: 40
  },
  textInput: {
    borderWidth: 2,
    borderColor: ACCENT,
    backgroundColor: WHITE,
    padding: 15,
    marginBottom: 15
  },
  rightTextWrap: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  centerTextWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 20
  },
  anchorWrap: {
    height: 20,
    marginLeft: 5,
    marginTop: 10
  },
  centerAnchorWrap: {
    height: 20,
    marginLeft: 0,
    marginTop: 0
  },
  darkText: {
    color: GREY_DK2
  },
  anchorText: {
    color: ACCENT
  },
  instructions: {
    color: GREY_DK2,
    marginTop: 5,
    marginBottom: 5
  }
}
