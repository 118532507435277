import config from '../config'
import ApiClient from './apiClient'

export const apiClient = new ApiClient({
  apiPath: config.apiPath,
  clientId: config.clientId,
  clientSecret: config.clientSecret,
  host: config.apiHost
})

export default function getClient () {
  return apiClient
}
