const getProducts = state => state.catalog.products
const getCategories = state => state.catalog.categories
const getCartItems = state => state.cart.items

export const getCatalogErrorState = state =>
  state.catalog.hasError ? state.catalog.error : false

export const getCategoriesForRoute = (state, routeKey) => getCategories(state)[routeKey]
export const getProductsForCategory = state => getProducts(state)
export const getProductById = (state, productId) => getProducts(state).find(it => it.id === productId)

export const getCategoryLoadingState = state => state.catalog.isRequestingCategories
export const getCategoryContentState = state => state.catalog.hasCategoryResponse

export const getProductsLoadingState = state => state.catalog.isRequestingProducts
export const getProductsContentState = state => state.catalog.hasProductsResponse

export const getProductsErpLoadingState = state => state.catalog.isRequestingErpData
export const getProductsErpContentState = state => state.catalog.hasErpDataResponse

// cart state selectors
export const getCartItemByProductId = (state, productId, styleId) =>
  getCartItems(state).find(it => it.itemId === productId && it.styleId === styleId)
