import React from 'react'
import { connect } from 'react-redux'
import { Image, View } from 'react-native'

import { ListRow, LogoBanner } from '../../components'
import { logout } from '../UserAuth/behaviors'

import styles from './styles'

class AccountMenuScreen extends React.Component {
  handleLogoutPress () {
    this.props.dispatch(logout(this.props.navigation))
  }

  handleRoutePress (route, title) {
    const { navigation } = this.props
    navigation.navigate(route, title)
  }

  render () {
    return (
      <View style={styles.menu.container}>

        <LogoBanner/>
        
        <View style={styles.menu.content}>
          <ListRow
            icon={'list-ul'}
            title='Order History'
            onPress={() => this.handleRoutePress('OrderHistory', 'Order History')}
          />
          <ListRow
            icon={'truck'}
            title='Shipping Locations'
            onPress={() => this.handleRoutePress('ShippingLocations', 'Shipping Locations')}
          />
          <ListRow
            icon={'map-marker'}
            title='Pickup Locations'
            onPress={() => this.handleRoutePress('PickupLocations', 'Pickup Locations')}
          />
          <ListRow
            icon={'user'}
            title='Account Info'
            onPress={() => this.handleRoutePress('AccountInfo', 'Account Info')}
          />
          <ListRow
            icon={'sign-out'}
            title='Logout'
            onPress={() => this.handleLogoutPress()} />
        </View>
      </View>
    )
  }
}

export default connect()(AccountMenuScreen)
