import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Image, ScrollView, Text, View } from 'react-native'

import { ButtonPill, CurrencyText, DateText } from '../../components'
import { importOrderItems } from '../Cart/behaviors'
import { colors } from '../../theme'

import { getOrderById } from './selectors'
import { getUserAccount } from '../UserAccount/selectors'

import styles from './styles'

const { ACCENT_DK } = colors

class OrderHistoryDetailScreen extends React.Component {
  static navigationOptions = {
    title: 'Order Detail'
  }

  static propTypes = {
    order: PropTypes.object.isRequired,
  }

  handleReorderPress = (items) => {
    const { navigation } = this.props
    this.props.importOrderItems(items)
    navigation.navigate('CartContent')
  }

  getLocationAddress = (order) => {
    const location = order.shipping_location || order.pickup_location
    if (location === null) { return null }

    const { address1, city, state, zip } = location
    return `${address1}, ${city}, ${state} ${zip}`
  }

  getOrderNumber = (order) => {
    return (order.erp && order.erp.order_number)
      ? order.erp.order_number : `WWW-${order.id}`
  }

  renderItem = (item) => {
    const { account } = this.props
    const { show_pricing: showPricing } = account
    return (
    <View style={styles.orderDetail.panelBlue} key={item.product.id}>
      <Image style={styles.orderDetail.prodImage} source={{ uri: item.product.image }} />
      <View style={styles.orderDetail.infoWrap}>
        <Text style={styles.orderDetail.titleText}>{item.product.title}</Text>
        <Text style={styles.orderDetail.label}>{item.product.partNumber}</Text>
      </View>
      <View style={styles.orderDetail.endWrap}>
        <Text style={styles.orderDetail.boldTextLarge}>{`QTY: ${item.quantity}`}</Text>
        { Boolean(showPricing) &&
          <CurrencyText style={styles.orderDetail.boldTextLarge} value={item.price} />
        }
      </View>
    </View>
    )
  }

  render () {
    const { order, account } = this.props
    const { show_pricing: showPricing } = account
    return (
      <View style={styles.orderDetail.container}>
        <ScrollView>
          <View>
            <View style={styles.orderDetail.panel}>
              <View style={styles.orderDetail.column}>
                <DateText style={styles.orderDetail.boldTextLarge} value={order.order_date} />
                <View style={styles.orderDetail.inlineWrap}>
                  <Text style={styles.orderDetail.boldText}>Order #:</Text>
                  <Text style={styles.orderDetail.label}>{this.getOrderNumber(order)}</Text>
                </View>
                <View style={styles.orderDetail.inlineWrap}>
                  <Text style={styles.orderDetail.boldText}>Status:</Text>
                  <Text style={styles.orderDetail.label}>{order.status}</Text>
                </View>
                { Boolean(showPricing) &&
                  <View style={styles.orderDetail.inlineWrap}>
                    <Text style={styles.orderDetail.boldText}>Total:</Text>
                    <CurrencyText style={styles.orderDetail.label} value={order.total} />
                  </View>
                }
                <Text style={styles.orderDetail.boldText}>Location:</Text>
                <Text style={styles.orderDetail.label}>{this.getLocationAddress(order)}</Text>

                { !!(order.poNumber) &&
                <View style={styles.orderDetail.inlineWrap}>
                  <Text style={styles.orderDetail.boldText}>PO Number:</Text>
                  <Text style={styles.orderDetail.label}>{order.po_num}</Text>
                </View>
                }
                { !!(order.comment) &&
                <View style={styles.orderDetail.inlineWrap}>
                  <Text style={styles.orderDetail.boldText}>Comment:</Text>
                  <Text style={styles.orderDetail.label}>{order.order_comment}</Text>
                </View>
                }
              </View>
              <View style={[styles.orderDetail.column, styles.orderDetail.flexCenter]}>
                <ButtonPill
                  text='Reorder'
                  align='center'
                  color={ACCENT_DK}
                  onPress={() => this.handleReorderPress(order.cart_data)}
                />
              </View>
            </View>
            {order.cart_data.map(item => this.renderItem(item))}
          </View>
        </ScrollView>
      </View>
    )
  }
}

const mapStateToProps = (state, { route }) => {
  const orderId = route.params?.orderId

  return {
    order: getOrderById(state, orderId),
    account: getUserAccount(state)
  }
}

export default connect(mapStateToProps, { importOrderItems })(OrderHistoryDetailScreen)
