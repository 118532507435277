import React from 'react'
import { connect } from 'react-redux'
import { FontAwesome } from '@expo/vector-icons'
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Switch,
  TextInput,
  Text,
  View
} from 'react-native'
import { ButtonPill, LoadingIcon } from '../../components'
import { colors } from '../../theme'
import {
  updateCurrentUser,
  deleteCurrentUser
} from './behaviors'
import { logout } from '../UserAuth/behaviors'
import {
  getUserAccount,
  getUserAccountIsLoaded,
  getUserAccountErrorState,
  getUserAccountIsLoading,
  getUserAccountConfirmDeletePasswordIncorrect,
  getUserAccountDeleteSuccess
} from './selectors'
import styles from './styles'

const { ACCENT, GREY, GREY_LT2, SUCCESS, WHITE, DANGER } = colors

class AccountInfoScreen extends React.Component {
  static navigationOptions = {
    title: 'Account Info'
  }

  state = {
    email: '',
    password: '',
    last_name: '',
    first_name: '',
    cell_phone: '',
    company_name: '',
    account_num: '',
    status_emails: '',
    showConfirmDelete: false,
    confirmDeletePassword: ''
  }

  componentDidMount() {
    const { hasAccount, account } = this.props
    if (hasAccount) {
      const {
        email,
        last_name,
        first_name,
        cell_phone,
        company_name,
        account_num,
        status_emails
      } = account

      this.setState({
        email,
        last_name,
        first_name,
        cell_phone,
        company_name,
        account_num,
        status_emails
      })
    }
  }

  componentDidUpdate() {
    // If deleted log out
    if (this.props.deleteSuccess) {
      this.props.logout(this.props.navigation)
    }
  }

  static getFormInputStyles (isEditable) {
    return {
      borderColor: isEditable ? ACCENT : ACCENT,
      backgroundColor: isEditable ? WHITE : GREY_LT2
    }
  }

  static isAccountNumEditable (account) {
    return !(typeof account['account_num'] !== 'undefined' && account['account_num'])
  }

  handleInputChange = (property, value) => {
    this.setState({
      [property]: value
    })
  }

  handleFormSubmit = async () => {
    const form = this.state

    form.status_emails = +form.status_emails
    if (!form.password || form.password === '') {
      delete form.password
    }

    await this.props.updateCurrentUser(form)
    this.props.navigation.goBack()
  }

  handleDeleteAccountTogglePress = () => {
    // Toggle account deletion confirmation
    this.setState({
      showConfirmDelete: !this.state.showConfirmDelete
    })
  }

  handleConfirmDeleteAccountPress = () => {
    const { confirmDeletePassword } = this.state
    this.props.deleteCurrentUser(confirmDeletePassword)
  }

  render () {
    const { account, error, hasAccount, isLoading } = this.props
    const isAcctNumEditable = this.constructor.isAccountNumEditable(account)

    return (
      <ScrollView class={styles.info.container}>
        <KeyboardAvoidingView
          style={styles.info.container}
          behavior={Platform.OS === 'ios' ? 'padding' : null}>
          <View extraHeight={290} style={styles.info.container}>

            { hasAccount && (
            <View style={styles.info.formWrap}>
              <TextInput
                value={this.state.first_name}
                placeholder='First Name'
                autoCapitalize='words'
                autoCorrect={false}
                style={[styles.info.textInput, this.constructor.getFormInputStyles(true)]}
                keyboardType='default'
                onChangeText={text => this.handleInputChange('first_name', text)}
                underlineColorAndroid='transparent'
              />

              <TextInput
                value={this.state.last_name}
                placeholder='Last Name'
                autoCapitalize='words'
                autoCorrect={false}
                style={[styles.info.textInput, this.constructor.getFormInputStyles(true)]}
                keyboardType='default'
                onChangeText={text => this.handleInputChange('last_name', text)}
                underlineColorAndroid='transparent'
              />

              <TextInput
                value={this.state.email}
                placeholder='Email Address'
                autoCapitalize='none'
                autoCorrect={false}
                style={[styles.info.textInput, this.constructor.getFormInputStyles(true)]}
                keyboardType='email-address'
                onChangeText={text => this.handleInputChange('email', text)}
                underlineColorAndroid='transparent'
              />

              <TextInput
                value={this.state.cell_phone}
                placeholder='Phone Number'
                autoCapitalize='none'
                autoCorrect={false}
                style={[styles.info.textInput, this.constructor.getFormInputStyles(true)]}
                keyboardType='phone-pad'
                onChangeText={text => this.handleInputChange('cell_phone', text)}
                underlineColorAndroid='transparent'
              />

              <TextInput
                value={this.state.company_name}
                placeholder='Company Name'
                autoCapitalize='words'
                autoCorrect={false}
                style={[styles.info.textInput, this.constructor.getFormInputStyles(true)]}
                keyboardType='default'
                onChangeText={text => this.handleInputChange('company_name', text)}
                underlineColorAndroid='transparent'
              />

              <TextInput
                autoCapitalize='none'
                autoCorrect={false}
                editable={isAcctNumEditable}
                keyboardType='numeric'
                onChangeText={text => this.handleInputChange('account_num', text)}
                placeholder='Account Number'
                style={[styles.info.textInput, this.constructor.getFormInputStyles(isAcctNumEditable)]}
                underlineColorAndroid='transparent'
                value={this.state.account_num}
              />

              <View style={styles.info.switchWrap}>
                <FontAwesome name='lock' style={styles.info.iconLock} color={GREY} size={30} />
                <Text style={styles.info.lightText}>Change Password</Text>
              </View>

              <TextInput
                value={this.state.password}
                placeholder='Password'
                secureTextEntry
                autoCapitalize='none'
                autoCorrect={false}
                style={[styles.info.textInput, this.constructor.getFormInputStyles(true)]}
                onChangeText={text => this.handleInputChange('password', text)}
                underlineColorAndroid='transparent'
              />

              <View style={styles.info.switchWrap}>
                <Switch
                  value={!!parseInt(this.state.status_emails)}
                  onValueChange={text => this.handleInputChange('status_emails', text)}
                />
                <Text style={styles.info.switchLabel}>Send emails on order status changes</Text>
              </View>

              <ButtonPill color={SUCCESS} text='Save' onPress={this.handleFormSubmit} />
            </View>
            )}

            { isLoading && (
              <LoadingIcon />
            )}

            <View style={styles.info.formWrap}>
              { !this.state.showConfirmDelete && (
                <ButtonPill color={DANGER} text='Delete Account' onPress={this.handleDeleteAccountTogglePress}/>
              )}
              { this.state.showConfirmDelete && (
                <View>
                  <ButtonPill color={ACCENT} text='Nevermind' onPress={this.handleDeleteAccountTogglePress}/>
                  <View style={[styles.info.deleteTextWrap]}>
                    <Text style={[styles.info.deleteTextWarn]}>This will PERMANENTLY DELETE ALL of your data! Are you sure you want to proceed?</Text>
                    <Text style={[styles.info.lightText]}>Enter your password to confirm total deletion of your account.</Text>
                  </View>
                  <TextInput
                    placeholder='Password'
                    secureTextEntry
                    style={[styles.info.textInput, this.constructor.getFormInputStyles(true)]}
                    onChangeText={text => this.handleInputChange('confirmDeletePassword', text)}
                  />
                  { this.props.confirmDeletePasswordIncorrect && (
                    <Text style={[styles.info.confirmDeletePasswordIncorrect]}>Incorrect Password. Please try again.</Text>
                  )}
                  <ButtonPill color={DANGER} text='Confirm Delete' onPress={this.handleConfirmDeleteAccountPress}/>
                </View>
              )}
            </View>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    error: getUserAccountErrorState(state),
    hasAccount: getUserAccountIsLoaded(state),
    isLoading: getUserAccountIsLoading(state),
    account: getUserAccount(state),
    confirmDeletePasswordIncorrect: getUserAccountConfirmDeletePasswordIncorrect(state),
    deleteSuccess: getUserAccountDeleteSuccess(state)
  }
}

export default connect(mapStateToProps, { updateCurrentUser, deleteCurrentUser, logout })(AccountInfoScreen)
