import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet, TextInput } from 'react-native'
import { colors } from '../theme'

const { ACCENT } = colors

const QuantityWidget = props => {
  const quantity = (props.value && props.value > 1) ? props.value : 1

  return (
    <TextInput style={styles.container} value={quantity} />
  )
}

QuantityWidget.propTypes = {
  onPress: PropTypes.func.isRequired,
  value: PropTypes.number
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 34,
    borderRadius: 17,
    borderWidth: 1,
    borderColor: ACCENT,
    paddingVertical: 5,
    paddingHorizontal: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 110
  },
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  buttonText: {
    fontSize: 28,
    height: 32
  },
  buttonTextLarge: {
    fontSize: 32,
    height: 35
  },
  text: {
    color: ACCENT,
    fontWeight: 'bold',
    fontSize: 20
  }
})

export default QuantityWidget
