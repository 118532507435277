import React from 'react'
import { connect } from 'react-redux'
import { FontAwesome } from '@expo/vector-icons'
import { ActivityIndicator, FlatList, ScrollView, Text, View } from 'react-native'
import { setFavorite, unsetFavorite } from '../UserFavorite/behaviors'

import { ButtonPill, CurrencyText } from '../../components'
import { colors } from '../../theme'

import CartListItem from './components'
import {
  clearCart,
  fetchCartItemPricing,
  removeCartItem,
  updateCartItem,
  apiGetCartItems
} from './behaviors'
import {
  getCartItems,
  getCartTotal,
  getCartErrorState,
  getCartItemsErpDataState,
  getCartItemsErpLoadingState,
  getCartImportCompleteState,
  getCartOrderImportState,
  getCartUpdateRequiredState,
} from './selectors'
import styles from './styles'
import { getUserAccount } from '../UserAccount/selectors'

import { useFocusEffect } from '@react-navigation/native';

const { DANGER, GREY } = colors

// This is apparently the way to do it according to https://reactnavigation.org/docs/use-focus-effect/
function FetchCartData({ onUpdate }) {
  useFocusEffect(
    React.useCallback(() => {onUpdate()}, [onUpdate])
  );

  return null
}

class CartContentScreen extends React.Component {
  static navigationOptions = {
    title: 'Cart'
  }

  static propTypes = {
  }

  static defaultProps = {
  }

  _apiGetCartItems = () => {
    this.props.apiGetCartItems()
  }

  handleCheckoutPress = () => {
    this.props.navigation.navigate('CartCheckout')
  }

  handleClearCartPress = () => {
    this.props.clearCart()
  }

  componentDidUpdate() {
    const { account, requiresPriceUpdate, fetchCartItemPricing } = this.props
    const { show_pricing: showPricing } = account

    if (
      requiresPriceUpdate
      && showPricing
    ) {
      fetchCartItemPricing()
    }
  }

  render () {
    const { hasErpData, isImporting, isLoadingErp, items, total, account } = this.props
    const { show_pricing: showPricing } = account

    return (
      <>
        <FetchCartData onUpdate={this._apiGetCartItems}/>

        <View style={styles.content.container}>
          { isImporting &&
            <View style={styles.content.centerWrap}>
              <ActivityIndicator size='large' color="gray" />
              <Text style={styles.content.emptyText}>Importing Order ...</Text>
            </View>
          }

          { !isImporting && !items.length && (
            <View style={styles.content.centerWrap}>
              <FontAwesome name='shopping-cart' style={styles.content.emptyIcon} color={GREY} size={144} />
              <Text style={styles.content.emptyText}>Your Cart is Empty</Text>
            </View>
          )}

          { !isImporting && items.length > 0 &&
            <View style={styles.content.container}>
              <FlatList
                data={items}
                keyExtractor={item => `${item.itemId}-${item.styleId}`}
                renderItem={({ item }) =>
                  <CartListItem
                    item={item}
                    hasErpData={hasErpData}
                    isLoadingErp={isLoadingErp}
                    setFavorite={this.props.setFavorite}
                    unsetFavorite={this.props.unsetFavorite}
                    removeCartItem={this.props.removeCartItem}
                    updateCartItem={this.props.updateCartItem}
                    showPricing={showPricing}
                  />
                }

                ListFooterComponent={
                  <View style={styles.content.actionButtons}>
                    { Boolean(showPricing) && Boolean(total) &&
                      <React.Fragment>
                        <Text style={styles.content.titleTextLarge}>Subtotal:</Text>
                        <CurrencyText style={styles.content.titleTextLarge} value={total} />
                      </React.Fragment>
                    }
                  
                    <ButtonPill
                      style={[styles.content.buttonContainer, styles.content.checkoutButton]}
                      text='Check Out'
                      onPress={this.handleCheckoutPress}
                    />
                    <ButtonPill
                      style={[styles.content.buttonContainer, styles.content.clearButton]}
                      text='Clear Cart'
                      onPress={this.handleClearCartPress}
                    />
                  </View>
                }
              />


            </View>
          }
        </View>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  isImporting: getCartOrderImportState(state),
  hasImportedOrder: getCartImportCompleteState(state),
  isLoadingErp: getCartItemsErpLoadingState(state),
  hasErpData: getCartItemsErpDataState(state),
  error: getCartErrorState(state),
  total: getCartTotal(state),
  items: getCartItems(state),
  requiresPriceUpdate: getCartUpdateRequiredState(state),

  account: getUserAccount(state)
  // favorites: findAllFavoriteProducts(state)
})

export default connect(mapStateToProps, {
  clearCart,
  fetchCartItemPricing,
  removeCartItem,
  setFavorite,
  unsetFavorite,
  updateCartItem,
  apiGetCartItems
})(CartContentScreen)
