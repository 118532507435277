import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'

import { colors } from '../../theme'
import SearchResult from './SearchResultScreen'
import SearchView from './SearchViewScreen'

const { ACCENT, WHITE } = colors


const Stack = createStackNavigator()

const navigatorOptions = {
  headerTintColor: WHITE,
  headerStyle: {
    backgroundColor: ACCENT,
  }
}

function SearchStack() {
  return (
    <Stack.Navigator screenOptions={navigatorOptions}>
      <Stack.Screen name="SearchResult" component={SearchResult} options={{headerShown: false}}/>
      <Stack.Screen name="SearchView" component={SearchView} options={({route}) => ({ title: route.params?.productTitle, headerBackTitle: ' ' })}/>
    </Stack.Navigator>
  )
}

export default SearchStack
