import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FlatList, Text, View, Dimensions, useState} from 'react-native'

import { LoadingIcon, NavigationHeader, TitleOnlyHeader } from '../../components'
import { CategoryListItem } from './components'
import {
  resetCatalog,
  fetchCategories
} from './behaviors'
import {
  getCategoriesForRoute,
  getCategoryContentState,
  getCategoryLoadingState,
  getCatalogErrorState
} from './selectors'
import styles from './styles'

class CategoryListScreen extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const title = navigation.getParam('categoryTitle', false)
    return title
      ? { header: props => <NavigationHeader navigation={navigation} title={title}/> }
      : { header: props => <TitleOnlyHeader title='Categories' /> }
  }
  
  static propTypes = {
    error: PropTypes.any,
    isLoading: PropTypes.bool,
    collunnCount: PropTypes.number,
    hasCategories: PropTypes.bool,
    categories: PropTypes.array,
    resetCatalog: PropTypes.func.isRequired,
    fetchCategories: PropTypes.func.isRequired
  }

  static defaultProps = {
    categories: [],
    hasCategories: false,
    isLoading: false,
    error: false,
    collunnCount: 1
  }

  state = {
    refreshing: true,
    collunnCount: 1
  }

  componentDidMount () {
    this.getCategoryList()
    Dimensions.addEventListener('change', () => {
      this.handleResize()
    });
  }


  getCategoryList () {
    const { categories, route} = this.props
    const selectedId = route.params?.categoryId
    if (!(selectedId) || categories.length === 0) {
      this.props.fetchCategories(route.key, selectedId)
      this.setState({ refreshing: false,collunnCount:this.getCollunnCount() })
    }
  }

  handleCategoryReset () {
    this.props.resetCatalog()
    this.getCategoryList()
  }

  handleOnPress (categoryId, categoryTitle, categoryCount) {
    const navRoute = (categoryCount > 0) ? 'CategoryList' : 'ProductList'
    this.props.navigation.push(navRoute, { categoryTitle, categoryId })
  }

  handleRefresh = () => {
    this.setState(
      { refreshing: true,collunnCount:this.getCollunnCount() },
      () => this.handleCategoryReset()
    )
  }

  handleResize = e => {
    this.setState({refreshing:this.state.refreshing,collunnCount:this.getCollunnCount()})
  }

  getCollunnCount = () =>{
    const windowWidth = Dimensions.get('window').width;
    if(windowWidth>=1800){
      return 3;
    }
    else if(windowWidth>=900){
      return 2;
    } else {
      return 1;
    }
  }


  render () {
    const { categories, error, isLoading, hasCategories } = this.props

    return (
      <View style={styles.container}>
        { isLoading && (
          <LoadingIcon />
        )}

        { hasCategories && (
          <FlatList
            data={categories}
            numColumns={this.state.collunnCount}
            key={this.state.collunnCount}
            horizontal={false}
            keyExtractor={item => String(item.id)}
            renderItem={({ item }) => (
              <CategoryListItem
                category={item}
                collumnCount={this.state.collunnCount}
                onPress={() => {
                  const { id, name, subcategories_count } = item
                  this.handleOnPress(id, name, subcategories_count)
                }} />
            )} />
        )}

        { error && (
          <View>
            <Text>Failed to request categories</Text>
          </View>
        )}
      </View>
    )
  }
}

const mapStateToProps = (state, { navigation, route }) => {
  const key = route.key

  return {
    categories: getCategoriesForRoute(state, key),
    hasCategories: getCategoryContentState(state),
    isLoading: getCategoryLoadingState(state),
    error: getCatalogErrorState(state)
  }
}

export default connect(mapStateToProps, { fetchCategories, resetCatalog })(CategoryListScreen)
