const getLocations = state => state.location.locations

export const getLocationErrorState = state =>
  state.location.hasError ? state.location.error : false

export const getPickupLocations = state => getLocations(state).filter(it => it.type === 'PICKUP')
export const getShippingLocations = state => getLocations(state).filter(it => it.type === 'SHIPPING')
export const getLocationLoadingState = state =>
  state.location.isRequestingShipping || state.location.isRequestingPickup
export const getLocationContentState = state =>
  state.location.hasShippingLocations && state.location.hasPickupLocations
export const getLocationById = (state, locationId) =>
  getLocations(state).find(it => it.id === locationId)
