import uniqBy from 'lodash/uniqBy'
import { showMessage } from 'react-native-flash-message'
import { processErrorResponse } from '../../utilities'
import { bookmark, debug, fault } from '../../logger'
import getClient from '../../services/getClient'
import { formatRequestBody, ERP_PRICING_MULTIPLE_PATH } from '../../services/erpFormattingService'

import {
  USER_FAVORITE_CREATE_RECEIVE,
  USER_FAVORITE_DELETE_RECEIVE
} from '../UserFavorite/behaviors'

export const SEARCH_RESET = 'SEARCH/RESET'
export const SEARCH_ERROR = 'SEARCH/ERROR'
export const SEARCH_FETCH_REQUEST = 'SEARCH/FETCH/REQUEST'
export const SEARCH_FETCH_RECEIVE = 'SEARCH/FETCH/RECEIVE'
export const SEARCH_PRICING_FETCH_REQUEST = 'SEARCH/PRICING/FETCH/REQUEST'
export const SEARCH_PRICING_FETCH_RECEIVE = 'SEARCH/PRICING/FETCH/RECEIVE'

/**
 * Definitions:
 * - error                contains API error string on error, `null` if no error
 * - hasError             `true` if API responded with an error, `false` otherwise
 * - hasResults           `true` if API favorites request completed successfully
 * - isRequestingResults  `true` if API pricing request has not yet completed
 * - selectedId           the numeric product ID of the selected product
 * - itemIds              array of product IDs contained within the store
 * - items                array of products
 */
const initialState = {
  error: null,
  hasError: false,
  hasPricing: false,
  hasResults: false,
  isRequestingPricing: false,
  isRequestingResults: false,
  resultCount: 0,
  currentPage: 1,
  finalPage: 0,
  selectedId: null,
  itemIds: [],
  items: []
}

const handlers = {
  [SEARCH_FETCH_RECEIVE]: (state, action) => {
    const { currentPage, finalPage, results, total } = action.payload

    // check if products are already in items array.
    // if not, push the product into the items array,
    // add product id to the itemIds array.
    const items = state.items
    const itemIds = state.itemIds
    Object.keys(results).map(pid => {
      items.push(results[pid])
      itemIds.push(pid)
    })

    return {
      ...state,
      hasResults: true,
      isRequestingResults: false,
      itemIds: [ ...itemIds ],
      items: [ ...items ],
      currentPage,
      finalPage,
      total
    }
  },

  [SEARCH_PRICING_FETCH_RECEIVE]: (state, action) => {
    const { payload } = action
    const { items } = state

    const newItems = [ ...items ]
    if (Array.isArray(payload.response)) {
      items.map(item => {
        if (!(Object(item).hasOwnProperty('erp'))) {
          item.erp = payload.response.find(it =>
            it['item_number'] === item['part_num'])
        }
      })
    }

    return {
      ...state,
      hasPricing: true,
      isRequestingPricing: false,
      items: newItems
    }
  },
  [USER_FAVORITE_CREATE_RECEIVE]: (state, action) => {
    const { payload } = action
    const { items } = state

    const idx = items.findIndex(it => payload.product.id === it.id)
    if (idx !== -1) { items[idx]['user_favorite_id'] = payload.id }

    return {
      ...state,
      items: [ ...items ]
    }
  },
  [USER_FAVORITE_DELETE_RECEIVE]: (state, action) => {
    const { payload } = action
    const { items } = state

    const idx = items.findIndex(it => payload.id === it['user_favorite_id'])
    if (idx !== -1) { items[idx]['user_favorite_id'] = null }

    return {
      ...state,
      items: [ ...items ]
    }
  },
  [SEARCH_PRICING_FETCH_REQUEST]: (state, action) => ({ ...state, hasPricing: false, isRequestingPricing: true }),
  [SEARCH_FETCH_REQUEST]: (state, action) => ({ ...state, error: null, hasError: false, hasResults: false, isRequestingResults: true }),
  [SEARCH_ERROR]: (state, action) => ({ ...state, hasError: true, isRequestingResults: false, error: action.error }),
  [SEARCH_RESET]: (state, action) => ({ ...state, ...initialState, itemIds: [], items: [] })
}

export default (state = initialState, action) => {
  const handler = handlers[action.type]
  return handler ? handler(state, action) : state
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const fetchResults = (body, page = 1) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = `/search?page=${page}`

    debug(SEARCH_FETCH_REQUEST, { path, body }, token)
    dispatch({ type: SEARCH_FETCH_REQUEST })

    try {
      const response = await client.create(path, body)
      const payload = {
        currentPage: page,
        finalPage: response['last_page'],
        results: uniqBy(response.data, 'id'),
        total: response.total
      }

      debug(SEARCH_FETCH_RECEIVE, { response }, token)
      dispatch({ type: SEARCH_FETCH_RECEIVE, payload })

      try {
        // ERP Request
        // const erpReqBody = {
        //   'item_numbers': response.data.map(a => a['part_num']),
        //   'quantities': response.data.map(a =>
        //     ((a.quantity === undefined) ? 1 : a.quantity))
        // }

        const erpReqBody = formatRequestBody(response.data)
        // erpReqBody['item_numbers'] = erpReqBody['item_numbers'].filter(onlyUnique)
        // var searchSize = erpReqBody['item_numbers'].length

        // while(searchSize < erpReqBody['quantities'].length){
        //   erpReqBody['quantities'].pop() //Just pop off the extra quantity numbers, since they're all 1 anyhow
        // }

        debug(SEARCH_PRICING_FETCH_REQUEST, { path: ERP_PRICING_MULTIPLE_PATH, body: erpReqBody }, token)
        dispatch({ type: SEARCH_PRICING_FETCH_REQUEST })

        const erpResponse = await client.create(ERP_PRICING_MULTIPLE_PATH, erpReqBody)

        debug(SEARCH_PRICING_FETCH_RECEIVE, { payload: erpResponse }, token)
        dispatch({ type: SEARCH_PRICING_FETCH_RECEIVE, payload: erpResponse })
      } catch (error) {
        fault(SEARCH_ERROR, { error }, token)
        dispatch({ type: SEARCH_ERROR, error })
        showMessage({
          message: 'Failed to fetch pricing information. Please try again later.',
          type: 'danger'
        })
      }
    } catch (error) {
      fault(SEARCH_ERROR, { error }, token)
      dispatch({ type: SEARCH_ERROR, error })
      showMessage({
        message: 'Failed to fetch search results. Please try again later.',
        type: 'danger'
      })
    }
  }
}

export const clearResults = () => {
  return (dispatch) => dispatch({ type: SEARCH_RESET })
}
