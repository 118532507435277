import { colors } from '../../theme'

const { ACCENT, DANGER, GREY_DK1, GREY_LT3, WHITE } = colors

export default {
  container: {
    flex: 1,
    backgroundColor: GREY_LT3
  },
  bottomHalf: {
    flex: 4,
    backgroundColor: GREY_LT3
  },
  formWrap: {
    padding: 40
  },
  textInput: {
    borderWidth: 2,
    borderColor: ACCENT,
    backgroundColor: WHITE,
    padding: 15,
    marginBottom: 15
  },
  rightTextWrap: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  centerTextWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 20
  },
  anchorWrap: {
    height: 20,
    marginLeft: 5,
    marginTop: 10
  },
  centerAnchorWrap: {
    height: 20,
    marginLeft: 0,
    marginTop: 0
  },
  darkText: {
    color: GREY_DK1
  },
  anchorText: {
    color: ACCENT
  },
  error: {
    color: DANGER
  }
}
