import React from 'react'
import { connect } from 'react-redux'
import { FlatList, Text, View } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import { LoadingIcon, ProductPanel } from '../../components'
import { addItemToCart } from '../Cart/behaviors'
import {
  setFavorite,
  unsetFavorite,
  fetchFavorites,
  fetchFavoritesPrice,
  changeFavoriteSortOrder
} from './behaviors'
import {
  getCartItems,
  getFavoritesContent,
  getFavoritesLoadingState,
  getFavoritesErpLoadingState,
  getFavoritesContentState,
  getFavoritesErpDataState,
  getFavoritesErrorState
} from './selectors'
import styles from './styles'
import { getUserAccount } from '../UserAccount/selectors'

class FavoriteListScreen extends React.Component {
  static navigationOptions = {
    title: 'Favorites'
  }

  state = {
    refreshing: true
  }

  getUserFavorites = () => {
    const { account, fetchFavorites } = this.props
    const { show_pricing: showPricing, show_inventory: showInventory } = account

    fetchFavorites(Boolean(showPricing) || Boolean(showInventory))
    this.setState({ refreshing: false })
  }

  getCartItem = (item) => {
    const { cartItems } = this.props
    return cartItems.find(it =>
      it.itemId === item.product.id && it.styleId === item.style.id)
  }

  handleRefresh = () => {
    this.setState(
      { refreshing: true },
      () => this.getUserFavorites()
    )
  }

  componentDidMount () {
    this.getUserFavorites()
  }

  componentDidUpdate (prevProps) {
    const { favorites, account, fetchFavoritesPrice } = this.props
    const { show_pricing: showPricing, show_inventory: showInventory } = account

    // if the length changes, request favorites again to get the new sort order
    if (favorites.length != prevProps.favorites.length) {
      this.getUserFavorites()

      // only fetch pricing again if another favorite got added
      if (
        favorites.length > prevProps.favorites.length
        && (Boolean(showPricing) || Boolean(showInventory))
      ) {
        fetchFavoritesPrice()
      }
    }
  }

  render () {
    const { favorites, hasErpData, hasFavorites, isLoadingErp, isLoading, error, account } = this.props
    const { show_pricing: showPricing, show_inventory: showInventory } = account

    return (
      <View style={styles.container}>
        { hasFavorites && favorites.length === 0 && (
          <View style={styles.centerWrap}>
            <FontAwesome name='heart' style={styles.emptyIcon}/>
            <Text style={styles.emptyText}>You do not have any favorites.</Text>
          </View>
        )}

        { !(hasFavorites) && isLoading && (
          <LoadingIcon />
        )}

        { hasFavorites && (
          <FlatList
            data={favorites}
            handleRefresh={this.handleRefresh}
            refreshing={this.state.refreshing}
            keyExtractor={item => String(item.id)}
            renderItem={({ item }) => (
              <ProductPanel
                product={item.product}
                hasErpData={hasErpData}
                productStyle={item.style}
                isLoadingErp={isLoadingErp}
                cartItem={this.getCartItem(item)}
                setFavorite={this.props.setFavorite}
                unsetFavorite={this.props.unsetFavorite}
                addItemToCart={this.props.addItemToCart}
                showPricing={Boolean(showPricing)}
                showInventory={Boolean(showInventory)}
                changeFavoriteSortOrder={this.props.changeFavoriteSortOrder}
                changeFavoriteSortOrderDisabled={isLoading}
                favorite={item}
              />
            )} />
        )}

        { error && (
          <View>
            <Text>No favorites found</Text>
          </View>
        )}
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: getCartItems(state),
    favorites: getFavoritesContent(state),
    hasErpData: getFavoritesErpDataState(state),
    hasFavorites: getFavoritesContentState(state),
    isLoadingErp: getFavoritesErpLoadingState(state),
    isLoading: getFavoritesLoadingState(state),
    error: getFavoritesErrorState(state),

    account: getUserAccount(state)
  }
}

export default connect(mapStateToProps, { setFavorite, unsetFavorite, fetchFavorites, fetchFavoritesPrice, addItemToCart, changeFavoriteSortOrder })(FavoriteListScreen)
