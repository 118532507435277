import React from 'react'
import { Keyboard, StyleSheet, TextInput, View } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import { colors } from '../theme'

const { ACCENT, WHITE } = colors

class SearchHeader extends React.Component {
  componentDidMount () {
    // This doesn't seem to be triggered on web
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide', this.props.onSubmit)
  }

  componentWillUnmount () {
    this.keyboardDidHideListener.remove()
  }

  render () {
    const { searchText, onChange, onSubmit } = this.props

    return (
      <View style={styles.searchBarHeader}>
        <View style={styles.searchBarContainer}>
          <FontAwesome style={styles.searchIcon} name='search' size={24} color={ACCENT} />
          <TextInput
            style={styles.searchBar}
            onChangeText={searchText => onChange(searchText)}
            onSubmitEditing={() => onSubmit()}
            placeholder='Search All Categories'
            placeholderTextColor={ACCENT}
            returnKeyType='search'
            value={searchText}
          />
        </View>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  searchBarHeader: {
    height: 84,
    backgroundColor: ACCENT,
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchBarContainer: {
    flexDirection: 'row',
    width: '80%',
    height: 34,
    backgroundColor: WHITE,
    borderRadius: 34,
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchBar: {
    flex: 1,
    height: '100%',
    paddingLeft: 40
  },
  searchIcon: {
    padding: 5,
    marginRight: -40
  }
})

export default SearchHeader
