import app from '../../app.json'

const logo = require('../../assets/brand/logo-full.png')

const colors = {
  // greyscale colors
  BLACK: '#000000',
  GREY_DK3: '#3E3A3B',
  GREY_DK2: '#585657',
  GREY_DK1: '#757374',
  GREY: '#918F90',
  GREY_LT1: '#ACAAAB',
  GREY_LT2: '#C7C5C6',
  GREY_LT3: '#E3E1E2',
  WHITE: '#FFFFFF',
  TRANSPARENT: 'rgba(0, 0, 0, 0)',

  // alert/control colors
  DANGER: '#DC3545',
  DANGER_DK: '#7C2B33',
  DANGER_LT: '#F8D7DA',
  INFO: '#17A2B8',
  INFO_DK: '#0B5460',
  INFO_LT: '#D1ECF1',
  PRIMARY: '#057BFF',
  PRIMARY_DK: '#014085',
  PRIMARY_LT: '#CCE5FF',
  SUCCESS: '#28A745',
  SUCCESS_DK: '#155725',
  SUCCESS_LT: '#D4EDDA',
  WARN: '#FFC108',
  WARN_DK: '#856407',
  WARN_LT: '#FFF3CD',

  // brand colors
  ACCENT: app.brand.colors.accent,
  ACCENT_DK: app.brand.colors.accentDk,
  ACCENT_LT: app.brand.colors.accentLt
}

export { colors, logo }
