/**
 * @param {Object} initial
 * @param {Object} mappings
 * @param {boolean} reverse if true, maps according to mapping values
 * @return {Object}
 */
export const transform = (initial, mappings, reverse = false) => {
  const result = {}
  if (reverse) {
    Object.values(mappings).map(it => {
      if (initial.hasOwnProperty(it)) {
        const key = Object.keys(mappings).find(key => mappings[key] === it)
        result[key] = initial[it]
      }
    })
  } else {
    Object.keys(mappings).map(it => {
      if (initial.hasOwnProperty(it)) {
        result[mappings[it]] = initial[it]
      }
    })
  }
  return result
}

/**
 * @param input
 * @return {Object}
 */
export const flatten = (input) => {
  const result = {}
  const recurse = (cur, prop) => {
    if (Object(cur) !== cur) {
      result[prop] = cur
    } else if (Array.isArray(cur)) {
      if (cur.length === 0) {
        result[prop] = []
      } else {
        for (let i = 0; i < cur.length; i += 1) {
          recurse(cur[i], `${prop}[${i}]`)
        }
      }
    } else {
      let isEmpty = true
      for (let p in cur) {
        isEmpty = false
        recurse(cur[p], prop ? `${prop}.${p}` : p)
      }
      if (isEmpty && prop) {
        result[prop] = {}
      }
    }
  }
  recurse(input, '')
  return result
}

/**
 * @param data
 * @return {*}
 */
export const unflatten = (data) => {
  if (Object(data) !== data || Array.isArray(data)) {
    return data
  }

  const result = {}
  const regex = /\.?([^.[\]]+)|\[(\d+)\]/g

  for (let p in data) {
    let cur = result
    let prop = ''
    let m = regex.exec(p)

    while (m) {
      cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}))
      prop = m[2] || m[1]
      m = regex.exec(p)
    }

    cur[prop] = data[p]
  }

  return result[''] || result
}

/**
 * @param action
 * @param error
 * @returns {*}
 */
export const processErrorResponse = (action, error) => {
  const errorType = typeof error
  switch (errorType) {
    case 'string':
      return {
        type: action,
        message: error
      }

    case 'object':
      const message = error.message || error.toString()
      return {
        type: action,
        message
      }

    default:
      return {
        type: action,
        message: 'An unknown error occurred.'
      }
  }
}
