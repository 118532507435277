import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  activityContainer: {
    flex: 1,
    justifyContent: 'center'
  },
  activityHorizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10
  }
})

const LoadingIcon = () => {
  return (
    <View style={[styles.activityContainer, styles.activityHorizontal]}>
      <ActivityIndicator size='large'color="gray" />
    </View>
  )
}

export default LoadingIcon
