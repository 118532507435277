import React from 'react'
import { Image, Text, View } from 'react-native'

import { LogoBanner } from '../../components'
import styles from './styles'

const IMAGE_SHIPPING_FAST = require('../../../assets/images/shipping-fast.png')

class CartCompleteScreen extends React.Component {
  render() {
    return (
      <View style={styles.complete.container}>
        <LogoBanner/>
        <View style={styles.complete.bottomHalf}>
          <View style={styles.complete.imageWrap}>
            <Image style={styles.complete.image} source={IMAGE_SHIPPING_FAST} />
          </View>
          <View style={styles.complete.textWrap}>
            <Text style={styles.complete.text}>Thank You!</Text>
            <Text style={styles.complete.textSmall}>
              You will be notified when your order has been processed.
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

export default CartCompleteScreen
