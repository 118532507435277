import { colors } from '../../theme'

const { ACCENT, GREY_LT3, WHITE, GREY } = colors

export default {
  container: {
    height: '100%',
    backgroundColor: GREY_LT3
  },
  emptyText: {
    color: GREY,
    fontSize: 24
  },
  emptyIcon: {
    color: GREY,
    fontSize: 144
  },
  centerWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  item: {
    container: {
      flex: 1,
      flexDirection: 'row',
      padding: 10,
      marginBottom: 15,
      borderBottomWidth: 0.5,
      borderTopWidth: 0.5,
      borderColor: ACCENT,
      backgroundColor: WHITE
    },
    image: {
      width: 75,
      height: 75
    },
    info: {
      flex: 1,
      flexGrow: 1,
      justifyContent: 'space-between',
      paddingLeft: 9,
      width: 0
    },
    partNumber: {
      paddingTop: 5,
      fontSize: 10
    },
    stockIndicator: {
    },
    title: {
      fontSize: 16
    }
  }
}
