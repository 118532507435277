import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import CategoryList from './CategoryListScreen'
import ProductList from './ProductListScreen'
import ProductView from './ProductViewScreen'
import { colors } from '../../theme'

const { ACCENT, WHITE } = colors

const Stack = createStackNavigator()

const navigatorOptions = {
  headerTintColor: WHITE,
  headerStyle: {
    backgroundColor: ACCENT,
  }
}

function CatelogStack() {
  return (
    <Stack.Navigator screenOptions={navigatorOptions}>
      <Stack.Screen name="CategoryList" component={CategoryList} options={{ title: 'Categories', headerBackTitle: ' ' }}/>
      <Stack.Screen name="ProductList" component={ProductList} options={{ title: 'Products', headerBackTitle: ' ' }}/>
      <Stack.Screen name="ProductView" component={ProductView} options={{ title: 'Product', headerBackTitle: ' ' }}/>
    </Stack.Navigator>
  )
}

export default CatelogStack
