import { colors } from '../../theme'

const { ACCENT, ACCENT_LT, GREY_DK3, WHITE } = colors

export default {
  // buttonText: {
  //   color: WHITE,
  //   fontSize: 18
  // },
  // chevron: {
  //   marginRight: 20
  // },
  // checkMark: {
  //   marginHorizontal: 20
  // },
  // container: {
  //   width: '100%'
  // },
  // dropdownButton: {
  //   width: '80%',
  //   alignSelf: 'center',
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   backgroundColor: ACCENT,
  //   marginVertical: 15,
  //   opacity: 1,
  //   paddingHorizontal: 20,
  //   paddingVertical: 7
  // },
  // dropdownContainer: {
  //   backgroundColor: WHITE,
  //   margin: 10,
  //   overflow: 'hidden'
  // },
  // flexRow: {
  //   flex: 1,
  //   marginRight: 10
  // },
  // greyText: {
  //   color: GREY_DK3
  // },
  // innerRow: {
  //   flexDirection: 'row',
  //   justifyContent: 'space-between'
  // },
  // list: {
  //   borderBottomWidth: 1,
  //   borderBottomColor: ACCENT
  // },
  // row: {
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  //   alignItems: 'center',
  //   paddingVertical: 10,
  //   backgroundColor: ACCENT_LT,
  //   borderTopWidth: 1,
  //   borderTopColor: ACCENT
  // },
  // spacer: {
  //   marginTop: 7
  // },

  container: {
    padding: 15
  },
  innerContainer: {
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  text: {
    fontSize: 18
  },
  headerFooterContainer: {
    padding: 10,
    alignItems: 'center'
  },
  headerFooterText: {
    fontWeight: 'bold',
    fontSize: 18
  },
  clearButton: {
    backgroundColor: 'grey',
    borderRadius: 5,
    marginRight: 10,
    padding: 5
  },
  optionContainer: {
    padding: 10,
    borderBottomColor: 'grey',
    borderBottomWidth: 1
  },
  optionInnerContainer: {
    flex: 1,
    flexDirection: 'row'
  },
  box: {
    width: 20,
    height: 20,
    marginRight: 10
  },
  wrapper: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center'
  }
}
