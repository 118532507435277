export const getUserAccount = state => state.useraccount.account
export const getUserAccountErrorState = state =>
  state.useraccount.hasError ? state.useraccount.error : false
export const getUserAccountIsLoading = state => state.useraccount.isRequestingUserAccount
export const getUserAccountIsLoaded = state => state.useraccount.hasUserAccountResponse
export const getUserAccountConfirmDeletePasswordIncorrect = state => state.useraccount.confirmDeletePasswordIncorrect
export const getUserAccountDeleteSuccess = state => state.useraccount.deleteSuccess

// Not sure this works as I wanted it to
// export const getUserPricing = state => Boolean(state.useraccount.account.show_pricing)
// export const getUserInventory = state => Boolean(state.useraccount.account.show_inventory)
