import React from 'react'
import striptags from 'striptags'
import PropTypes from 'prop-types'
import { showMessage } from 'react-native-flash-message'
import { ActivityIndicator, Image, Text, TextInput, View, ScrollView } from 'react-native'
import Icon from 'react-native-vector-icons/FontAwesome';

import CurrencyText from '../../components/CurrencyText'
import FavoriteButton from '../FavoriteButton'
import ButtonPill from '../ButtonPill'
import styles from './styles'
import { TouchableOpacity } from 'react-native-gesture-handler'

const IMAGE_PLACEHOLDER = require('../../../assets/images/missing-image.png')

const StockIndicator = ({ warehouseQuantity, allQuantity }) => {
  const hasPreferredWarehouse = warehouseQuantity != null
  const warehouseAvailable = warehouseQuantity > 0
  const anyAvailable = allQuantity > 0
  return (
    <View style={styles.stockIndicator}>
      { warehouseAvailable && hasPreferredWarehouse &&
        <View>
          <Text>In Stock</Text>
          <Text>{ warehouseQuantity }</Text>
        </View>
      }

      { !warehouseAvailable && hasPreferredWarehouse && anyAvailable &&
        <View>
          <Text>Total Stock</Text>
          <Text>{ allQuantity }</Text>
        </View>
      }

      { anyAvailable && !hasPreferredWarehouse &&
        <View>
          <Text>In Stock</Text>
          <Text>{ allQuantity }</Text>
        </View>
      }

      { !anyAvailable &&
        <View>
          <Text>Out of Stock</Text>
        </View>
      }
    </View>
  )
}

const PriceTable = ({ style, hasPricing, isRequestingPricing, product, showPricing, showInventory }) => {
  const erp = product.erp
  const listPrice = erp?.list_price_each
  const discountPrice = erp?.discount_price_each

  let warehouseAvailable = false
  let anyAvailable = null
  let hasPreferredWarehouse = false
  let warehouseQuantity = null
  let totalAvailable = 0;

  if (erp) {
    // TODO a lot of this logic may be better put into the server
    for (let warehouse of erp.warehouse_data) {
      totalAvailable += warehouse.stock
      if (warehouse.warehouse == erp.preferred_warehouse) {
        warehouseQuantity = warehouse.stock
      }
    }

    // hasPreferredWarehouse = true
    // warehouseAvailable = warehouseQuantity > 0

    anyAvailable = product.erp.total_available > 0
  }


  let views = []

  if (hasPricing) {
    // Inventory
    if (showInventory) {
      views.push((
        <View key="inventory">
          <StockIndicator
            warehouseQuantity={warehouseQuantity}
            allQuantity={totalAvailable} />
        </View>
      ))

      if (!warehouseAvailable && hasPreferredWarehouse && anyAvailable) {
        views.push((
          <View>
            <Text
              key="warehouseUnavailableMessage"
              style={styles.warehouseUnavailableMessage}>
                Your warehouse is out of stock. Shipping may be delayed.
            </Text>
          </View>
        ))
      }
    }

    // Pricing
    if (showPricing) {
      views.push((
        <View key="pricingContainer" style={styles.pricingContainer}>
          <View key="listPrice" style={styles.priceListContainer}>
            <Text style={styles.priceListHeader}>List Price</Text>
            <CurrencyText style={styles.priceListValue} value={listPrice} />
          </View>

          {/* Add a real condition here when we implement the user turning off discount price in app */}
          { true &&
            <View key="discountPrice" style={styles.priceSpecialContainer}>
              <Text style={styles.priceSpecialHeader}>Your Price</Text>
              <CurrencyText style={styles.priceSpecialValue} value={discountPrice} />
            </View>
          }
        </View>
      ))
    }
  }

  // Loading
  if ((showPricing || showInventory) && isRequestingPricing) {
    views.push((
        <ActivityIndicator key="loading" size='small' color="gray" />
    ))
  }

  return (
      <View style={[styles.priceIndicator, style]}>
        { views }
      </View>
  )
}

PriceTable.defaultProps = {
  isRequestingPricing: false,
  hasPricing: false,
  showPricing: false,
  showInventory: false,
}

PriceTable.propTypes = {
  product: PropTypes.object.isRequired,
  isRequestingPricing: PropTypes.bool,
  hasPricing: PropTypes.bool,
  showPricing: PropTypes.bool,
  showInventory: PropTypes.bool
}

class ProductPanel extends React.Component {
  static propTypes = {
    product: PropTypes.shape({}),
    cartItem: PropTypes.shape({}),
    productStyle: PropTypes.shape({}),
    hasErpData: PropTypes.bool,
    isLoadingErp: PropTypes.bool,
    addItemToCart: PropTypes.func.isRequired,
    unsetFavorite: PropTypes.func.isRequired,
    setFavorite: PropTypes.func.isRequired,
    showPricing: PropTypes.bool,
    showInventory: PropTypes.bool,
    changeFavoriteSortOrder: PropTypes.func,
    changeFavoriteSortOrderDisabled: PropTypes.bool,
    favorite: PropTypes.shape({})
  }

  static defaultProps = {
    isLoadingErp: false,
    hasErpData: false,
  }

  state = {
    quantity: ''
  }

  handleQuantityChange (q) {
    const quantity = (q !== '') ? parseInt(q) : ''
    this.setState({ quantity })
  }

  handleZeroItemQuantity () {
    const { quantity } = this.state
    if (!(quantity) || quantity === 0) {
      this.setState({ quantity: '' })
    }
  }

  handleAddToCartPress (item, style) {
    const { quantity } = this.state
    if (quantity && quantity > 0) {
      this.props.addItemToCart(item, style, quantity)
      this.setState({ quantity: '' })
    } else {
      showMessage({
        message: 'Please enter a numeric quantity greater than 0 to add to cart.',
        type: 'warning'
      })
    }
  }

  render () {
    const { cartItem, product, productStyle, hasErpData, isLoadingErp, showPricing, showInventory, changeFavoriteSortOrder, changeFavoriteSortOrderDisabled, favorite } = this.props
    return (
      <View style={styles.panelBlue}>
        { product && (
          <ScrollView>
            {/* row */}
            <View style={[styles.flexRow]}>
              {/* column */}
              { changeFavoriteSortOrder && favorite && (
                <View style={[styles.sortOrderContainer]}>
                    <TouchableOpacity
                      onPress={() => changeFavoriteSortOrder(product.user_favorite_id, 'previous')}
                      disabled={favorite.is_first || changeFavoriteSortOrderDisabled}>
                      <Icon
                      name="chevron-up"
                      style={favorite.is_first || changeFavoriteSortOrderDisabled ? styles.sortOrderArrowDisabled : styles.sortOrderArrow}/>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => changeFavoriteSortOrder(product.user_favorite_id, 'next')}
                      disabled={favorite.is_last || changeFavoriteSortOrderDisabled}>
                      <Icon
                      name="chevron-down"
                      style={favorite.is_last || changeFavoriteSortOrderDisabled ? styles.sortOrderArrowDisabled : styles.sortOrderArrow}/>
                    </TouchableOpacity>
                {/* end column */}
                </View>
              )}

              {/* column */}
              <View style={[styles.flexRow, styles.flexContainer]}>
                <View>
                  <Image
                    style={styles.prodImage}
                    source={product.image ? { uri: product.image } : IMAGE_PLACEHOLDER} />

                  { cartItem && (
                    <View style={styles.quantityLabel}>
                      <Text style={styles.quantityLabelText}>
                        {cartItem.quantity} in cart
                      </Text>
                    </View>
                  )}
                </View>

                <View style={styles.flexContainer}>
                  <View style={styles.flexRow}>
                    <View style={styles.infoWrap}>
                      <View style={styles.productHeader}>
                        <Text style={styles.productTitle}>{product.title}</Text>
                        <Text style={styles.productSubTitle}>{product.part_num}</Text>
                        <Text style={styles.productSubTitle}>{product.manufacturer}</Text>
                      </View>

                      <View style={styles.productDesc}>
                        <Text style={styles.productDescText}>
                          {striptags(product['manufacturer_description'], [], '')}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              {/* end column */}
              </View> 
            {/* end row */}
            </View>

            <View style={styles.priceTableContainer}>
              <PriceTable
                style={styles.priceTable}
                product={product}
                hasPricing={hasErpData}
                isRequestingPricing={isLoadingErp}
                showPricing={showPricing}
                showInventory={showInventory} />
            </View>

            <View style={styles.buttonContainerContainer}>
              <View style={styles.buttonContainer}>
                <View style={styles.itemWrap}>
                  <TextInput
                    returnKeyType='done'
                    keyboardType='numeric'
                    placeholder={'quantity'}
                    style={styles.quantityInput}
                    underlineColorAndroid={'transparent'}
                    value={String(this.state.quantity)}
                    onChangeText={(t) => this.handleQuantityChange(t)}
                    onEndEditing={() => this.handleZeroItemQuantity()} />
                </View>

                <View style={styles.itemWrap}>
                  <ButtonPill
                    width={120}
                    justify='flex-end'
                    text={cartItem ? 'Add More' : 'Add to Cart'}
                    onPress={() => this.handleAddToCartPress(product, productStyle)} />
                </View>

                <View style={styles.itemWrap}>
                  <FavoriteButton
                    product={product}
                    style={productStyle}
                    setFavorite={this.props.setFavorite}
                    unsetFavorite={this.props.unsetFavorite} />
                </View>
              </View>
            </View>
            
          </ScrollView>
        )}
      </View>
    )
  }
}

export default ProductPanel
