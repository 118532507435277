import { showMessage } from 'react-native-flash-message'
import { CommonActions } from '@react-navigation/native';
import { processErrorResponse } from '../../utilities'
import { bookmark, debug, fault } from '../../logger'
import getClient from '../../services/getClient'

export const USERAUTH_REGISTER_REQUEST = 'USERAUTH/REGISTER/REQUEST'
export const USERAUTH_REGISTER_RECEIVE = 'USERAUTH/REGISTER/RECEIVE'

const resetLogoutNavigationAction = CommonActions.navigate({
  name: 'UserAuth',
  params: {
    index: 0
  }
})

export const register = (body, navigation) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/register'

    debug(USERAUTH_REGISTER_REQUEST, { path, body }, token)

    try {
      const response = await client.anonCreate(path, body)
      debug(USERAUTH_REGISTER_RECEIVE, { response }, token)
      navigation.dispatch(resetLogoutNavigationAction)
      showMessage({
        message: 'Your account was created successfully.',
        type: 'success'
      })
    } catch (error) {
      fault(USERAUTH_REGISTER_REQUEST, { error }, token)
      dispatch(processErrorResponse(USERAUTH_REGISTER_REQUEST, error))
      showMessage({
        message: 'An error occurred while creating your account.',
        type: 'danger'
      })
    }
  }
}

