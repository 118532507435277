import { combineReducers } from 'redux'
import cart from '../features/Cart/behaviors'
import catalog from '../features/Catalog/behaviors'
import location from '../features/Location/behaviors'
import orderhistory from '../features/OrderHistory/behaviors'
import search from '../features/ProductSearch/behaviors'
import useraccount from '../features/UserAccount/behaviors'
import userauth from '../features/UserAuth/behaviors'
import userfavorite from '../features/UserFavorite/behaviors'

const appReducer = combineReducers({
  cart,
  catalog,
  location,
  orderhistory,
  search,
  useraccount,
  userauth,
  userfavorite
})

// Reset entire state on logout
// This hopefully won't have issues with redux-persist
const rootReducer = (state, action) => {
  if (action.type == 'USERAUTH/LOGOUT') {
    // reducers return initial state when called with undefined
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

// This has to be on a separate line or else it breaks the web build
// for seemingly no good reason
export default rootReducer
