import { showMessage } from 'react-native-flash-message'
import { processErrorResponse } from '../../utilities'
import { bookmark, debug, fault } from '../../logger'
import getClient from '../../services/getClient'
import { formatRequestBody, ERP_PRICING_MULTIPLE_PATH } from '../../services/erpFormattingService'

import {
  USER_FAVORITE_CREATE_RECEIVE,
  USER_FAVORITE_DELETE_RECEIVE
} from '../UserFavorite/behaviors'

// Api Read
export const SHOPPING_CART_READ_REQUEST = 'SHOPPING/CART/READ/REQUEST'
export const SHOPPING_CART_READ_RECEIVE = 'SHOPPING/CART/READ/RECEIVE'

// Api Create
export const SHOPPING_CART_CREATE_REQUEST = 'SHOPPING/CART/CREATE/REQUEST'
export const SHOPPING_CART_CREATE_RECEIVE = 'SHOPPING/CART/CREATE/RECEIVE'

// API Update
export const SHOPPING_CART_UPDATE_REQUEST = 'SHOPPING/CART/UPDATE/REQUEST'
export const SHOPPING_CART_UPDATE_RECEIVE = 'SHOPPING/CART/UPDATE/RECEIVE'

// API Delete
export const SHOPPING_CART_DELETE_REQUEST = 'SHOPPING/CART/DELETE/REQUEST'
export const SHOPPING_CART_DELETE_RECEIVE = 'SHOPPING/CART/DELETE/RECEIVE'

// API Clear
export const SHOPPING_CART_CLEAR_REQUEST = 'SHOPPING/CART/CLEAR/REQUEST'
export const SHOPPING_CART_CLEAR_RECEIVE = 'SHOPPING/CART/CLEAR/RECEIVE'

export const CART_ERROR = 'CART/ERROR'
export const CART_RESET = 'CART/RESET'
export const CART_ADD_ITEM = 'CART/ITEM/ADD'
export const CART_UPDATE_ITEM = 'CART/ITEM/UPDATE'
export const CART_REMOVE_ITEM = 'CART/ITEM/REMOVE'
export const CART_SELECT_LOCATION = 'CART/SET_PICKUP_LOCATION'
export const CART_SELECT_SHIPPING_LOCATION = 'CART/SET_SHIPPING_LOCATION'
export const CART_CLEAR_LOCATION = 'CART/CLEAR_PICKUP_LOCATION'
export const CART_ERP_PRICING_FETCH = 'CART/PRICING/ERP/FETCH_BEGIN'
export const CART_ERP_PRICING_RECEIVE = 'CART/PRICING/ERP/FETCH_ENDED'
export const CART_ORDER_IMPORT = 'CART/ORDER/IMPORT'
export const CART_ORDER_RECEIVE = 'CART/ORDER/RECEIVE'
export const CART_CHECKOUT_BEGIN = 'CART/CHECKOUT/BEGIN'
export const CART_CHECKOUT_ENDED = 'CART/CHECKOUT/ENDED'

const isInCart = (items, itemId, styleId) => {
  return (items.filter(it => it.itemId === itemId && it.styleId === styleId).length > 0)
}

const newCartItem = (item, styleId, quantity) => {
  return {
    itemId: item.id,
    itemSku: item.sku,
    itemFavId: item.user_favorite_id,
    itemImage: item.image,
    itemManufacturer: item.manufacturer,
    itemTitle: item.title,
    productCode: item.part_num,
    itemListPrice: item.price,
    itemPriceUnit: item.unit,
    styleId: styleId,
    quantity,
    hasErpData: false,
    erp: null
  }
}

function update (state) {
  const { hasErpPricing, items} = state
  let cartTotal = 0
  if (hasErpPricing) {
    for (let item of items) {
      if (item.hasErpData) {
        cartTotal += item.erp.discount_price_total
      }
    }
  }

  return { ...state, cartTotal }
}

// TODO: Somehow the items in this object get edited somewhere. Probably due to an assign by reference thingy
const initialState = {
  error: null,
  hasError: false,
  locationId: null,
  hasErpPricing: false,
  hasImportedOrder: false,
  isFetchingPrices: false,
  isImportingOrder: false,
  requiresPriceUpdate: true,
  shippingLocation: null,
  pickupLocation: null,
  cartTotal: 0,
  items: []
}

const handlers = {
  // API Read
  [SHOPPING_CART_READ_REQUEST]: (state, action) => ({ ...state, error: null, hasError: false }),
  [SHOPPING_CART_READ_RECEIVE]: (state, action) => {
    const { payload } = action
    let newItems = []
    for (let item of payload) {
      newItems.push(newCartItem(item.product, item.style_id, item.quantity))
    }
    return update({
      ...state,
      requiresPriceUpdate: true,
      items: newItems
    })
  },

  // API Create
  [SHOPPING_CART_CREATE_REQUEST]: (state, action) => ({ ...state, error: null, hasError: false }),
  [SHOPPING_CART_CREATE_RECEIVE]: (state, action) => {
    const { payload } = action
    let newItems = state.items
    let index  = newItems.findIndex(it => it.itemId === payload.product.id )
      if (index === -1) {
        newItems.push(newCartItem(payload.product, payload.style_id, payload.quantity))
      }
      else {
        newItems[index].quantity = payload.quantity
      }
    return update({
      ...state,
      requiresPriceUpdate: true,
      items: newItems
    })
  },

  // API Update
  [SHOPPING_CART_UPDATE_REQUEST]: (state, action) => {
    const { styleId, quantity } = action.payload

    const newItems = state.items.map(it => {
      if (it.styleId == styleId ) {
        it.quantity = quantity
      }
      return it
    })

    return update({
      ...state,
      requiresPriceUpdate: true,
      items: newItems
    })
  },
  [SHOPPING_CART_UPDATE_RECEIVE]: (state, action) => {
    const { payload } = action
    let newItems = state.items
    for (let key in newItems) {
      if (newItems[key].styleId == payload.style_id) {
        newItems[key].quantity = payload.quantity

        // Need to resend pricing request
        // this flag will do it, but not the most organized
        newItems[key].hasErpData = false;
      }
    }

    return update({
      ...state,
      requiresPriceUpdate: true,
      items: newItems
    })
  },

  // API Delete
  [SHOPPING_CART_DELETE_REQUEST]: (state, action) => ({ ...state, error: null, hasError: false }),
  [SHOPPING_CART_DELETE_RECEIVE]: (state, action) => {
    const { payload } = action
    let newItems = state.items
    for (let key in newItems) {
      if (newItems[key].styleId == payload) {
        newItems.splice(key, 1)
      }
    }

    return update({
      ...state,
      requiresPriceUpdate: true,
      items: newItems
    })
  },

  // API Clear
  [SHOPPING_CART_CLEAR_REQUEST]: (state, action) => ({ ...state, error: null, hasError: false }),
  [SHOPPING_CART_CLEAR_RECEIVE]: (state, action) => {
    if (action) {
      let newItems = []
      return update({
        ...state,
        requiresPriceUpdate: true,
        items: newItems
      })
    }
    return state
  },

  // ERP
  [CART_ERP_PRICING_FETCH]: (state, action) => ({ ...state, isFetchingPrices: true }),
  [CART_ERP_PRICING_RECEIVE]: (state, action) => {
    const { payload } = action
    const { items } = state

    let newItems = []

    if (payload.products) {
      newItems = items.map(item => {
        let newItem = item
        newItem.erp = payload.products.find(responseItem =>
          responseItem.product_code === item.productCode
        ) ?? item.erp
  
        if (newItem.erp) {
          newItem.hasErpData = true
        }
  
        return newItem
      })
    } else {
      newItems = items
    }

    return update({
      ...state,
      items: newItems,
      hasErpPricing: true,
      isFetchingPrices: false,
      requiresPriceUpdate: false
    })
  },
  
  [CART_ORDER_RECEIVE]: (state, action) => {
    const { payload } = action
    const items = payload.map((it) => {
      if (!(isInCart(payload, it.product.id, it.style.id))) {
        return newCartItem(it.product, it.style.id, it.quantity)
      }
    })

    return update({
      ...state,
      hasImportedOrder: true,
      isImportingOrder: false,
      requiresPriceUpdate: true,
      items
    })
  },
  [USER_FAVORITE_CREATE_RECEIVE]: (state, action) => {
    const { payload } = action
    const { items } = state

    const newItems = [ ...items ]
    const idx = newItems.findIndex(it => payload.product.id === it['itemId'])
    if (idx !== -1) { newItems[idx]['itemFavId'] = payload.id }

    return {
      ...state,
      items: newItems
    }
  },
  [USER_FAVORITE_DELETE_RECEIVE]: (state, action) => {
    const { payload } = action
    const { items } = state

    const newItems = [ ...items ]
    const idx = newItems.findIndex(it => payload.id === it['itemFavId'])
    if (idx !== -1) { newItems[idx]['itemFavId'] = null }

    return {
      ...state,
      items: newItems
    }
  },
  [CART_SELECT_LOCATION]: (state, action) => ({ ...state, shippingLocation: null, pickupLocation: action.payload.locationId }),
  [CART_SELECT_SHIPPING_LOCATION]: (state, action) => ({ ...state, pickupLocation: null, shippingLocation: action.payload.locationId }),
  [CART_ORDER_IMPORT]: (state, action) => ({ ...state, isImportingOrder: true, hasImportedOrder: false }),
  [CART_CLEAR_LOCATION]: (state, action) => ({ ...state, pickupLocation: null, shippingLocation: null }),
  [CART_RESET]: (state, action) => ({ ...initialState, items: [] /* TODO: Initial State items gets edited somehow so set items manually to empty array */ })
}

export default function cart (state = initialState, action) {
  const handler = handlers[action.type]
  return handler ? handler(state, action) : state
}

export const apiGetCartItems = () => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/shopping-cart'

    debug(SHOPPING_CART_READ_REQUEST, { path }, token)
    dispatch({ type: SHOPPING_CART_READ_REQUEST })
  
    try {
      const response = await client.all(path)
      debug(SHOPPING_CART_READ_RECEIVE, response, token)
      dispatch({ type: SHOPPING_CART_READ_RECEIVE, payload: response })

    } catch (error) {
      fault(SHOPPING_CART_READ_REQUEST, { error }, token)
      dispatch(processErrorResponse(SHOPPING_CART_READ_REQUEST, error))
      showMessage({
        message: 'Could not sync shopping cart!',
        type: 'warning'
      })
    }
  }
}

/**
 * Adds a selected item and item style to the the cart.
 * @param {Product}       item - the selected item
 * @param {ProductStyle}  style - the selected item style
 * @param {number}        quantity - the quantity to be added
 * @returns {{payload: {item: *, quantity: *, style: *}, type: string}}
 */
// TODO remove item here pls
export const addItemToCart = (item, style, quantity) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/shopping-cart'
    const body = { style_id: style.id, quantity: quantity }
  
    debug(SHOPPING_CART_CREATE_REQUEST, { path, body }, token)
    dispatch({ type: SHOPPING_CART_CREATE_REQUEST })
  
    try {
      const response = await client.create(path, body)
      debug(SHOPPING_CART_CREATE_RECEIVE, response, token)
      dispatch({ type: SHOPPING_CART_CREATE_RECEIVE, payload: response })
  
      showMessage({
        message: 'Product added to shopping cart.',
        type: 'success'
      })
    } catch (error) {
      fault(SHOPPING_CART_CREATE_REQUEST, { error }, token)
      dispatch(processErrorResponse(SHOPPING_CART_CREATE_REQUEST, error))
      showMessage({
        message: 'Product could not be added to shopping cart.',
        type: 'warning'
      })
    }
  }
}

export const updateCartItem = (styleId, quantity) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/shopping-cart/' + styleId
    const body = { quantity: quantity }
  
    debug(SHOPPING_CART_UPDATE_REQUEST, { path, body }, token)
    dispatch({ type: SHOPPING_CART_UPDATE_REQUEST, payload: { styleId, quantity }})
  
    if (quantity) {
      try {
        const response = await client.update(path, body)
        debug(SHOPPING_CART_UPDATE_RECEIVE, response, token)
        dispatch({ type: SHOPPING_CART_UPDATE_RECEIVE, payload: response })
    
        // showMessage({
        //   message: 'Product quantity updated.',
        //   type: 'success'
        // })
      } catch (error) {
        fault(SHOPPING_CART_UPDATE_REQUEST, { error }, token)
        dispatch(processErrorResponse(SHOPPING_CART_UPDATE_REQUEST, error))
        showMessage({
          message: 'Product quantity could not be updated.',
          type: 'warning'
        })
      }
    }
  }
}

export const removeCartItem = (styleId) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/shopping-cart/' + styleId
  
    debug(SHOPPING_CART_DELETE_REQUEST, { path }, token)
    dispatch({ type: SHOPPING_CART_DELETE_REQUEST })
  
    try {
      const response = await client.delete(path)
      debug(SHOPPING_CART_DELETE_RECEIVE, response, token)
      dispatch({ type: SHOPPING_CART_DELETE_RECEIVE, payload: styleId })
  
      showMessage({
        message: 'Product removed.',
        type: 'success'
      })
    } catch (error) {
      fault(SHOPPING_CART_DELETE_REQUEST, { error }, token)
      dispatch(processErrorResponse(SHOPPING_CART_DELETE_REQUEST, error))
      showMessage({
        message: 'Product could not be removed.',
        type: 'warning'
      })
    }
  }
}

export const clearCart = () => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/shopping-cart'
  
    debug(SHOPPING_CART_CLEAR_REQUEST, { path }, token)
    dispatch({ type: SHOPPING_CART_CLEAR_REQUEST })
  
    try {
      const response = await client.delete(path)
      debug(SHOPPING_CART_CLEAR_RECEIVE, response, token)
      dispatch({ type: SHOPPING_CART_CLEAR_RECEIVE})
  
      showMessage({
        message: 'Shopping cart cleared.',
        type: 'success'
      })
    } catch (error) {
      fault(SHOPPING_CART_CLEAR_REQUEST, { error }, token)
      dispatch(processErrorResponse(SHOPPING_CART_CLEAR_REQUEST, error))
      showMessage({
        message: 'Shopping cart could not be cleared.',
        type: 'warning'
      })
    }
  }
}

export const fetchCartItemPricing = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const client = getClient()
    const token = bookmark()

    const { items } = state.cart

    debug(CART_ERP_PRICING_FETCH, {}, token)
    dispatch({ type: CART_ERP_PRICING_FETCH })

    // Only products that don't already have data
    // accounting for quantity change is unorganized
    let updateItems = items.filter(favorite => (!favorite.hasErpData))

    if (updateItems.length > 0) {
      const erpReqestBody = formatRequestBody(updateItems)

      let response
      try {
        response = await client.create(ERP_PRICING_MULTIPLE_PATH, erpReqestBody)
      } catch (error) {
        fault(CART_ERP_PRICING_FETCH, { error }, token)
        processErrorResponse(CART_ERP_PRICING_FETCH, error)
        showMessage({
          message: 'An error occurred while fetching pricing information. Please try again later.',
          type: 'warn'
        })
      }
  
      debug(CART_ERP_PRICING_RECEIVE, { payload: response }, token)
      dispatch({ type: CART_ERP_PRICING_RECEIVE, payload: response })
    } else {
      // We don't need to update any items so send no payload to clear the pricing update flag
      // feels kinda hacky
      debug(CART_ERP_PRICING_RECEIVE, { payload: {} }, token)
      dispatch({ type: CART_ERP_PRICING_RECEIVE, payload: {} })
    }
  }
}

export const importOrderItems = (items) => {
  return async (dispatch) => {
    const token = bookmark()
    debug(CART_ORDER_IMPORT, items)
    dispatch({ type: CART_ORDER_IMPORT })

    try {
      debug(CART_ORDER_RECEIVE, { items }, token)
      dispatch({ type: CART_ORDER_RECEIVE, payload: items })
      showMessage({
        message: 'order imported successfully',
        type: 'success'
      })
    } catch (error) {
      fault(CART_ORDER_IMPORT, { error }, token)
      dispatch({ type: CART_ORDER_IMPORT, error })
      showMessage({ message: 'order import failed', type: 'danger' })
    }
  }
}

export const checkout = ({ comment, poNumber }) => {
  return async (dispatch, getState) => {
    const client = getClient()
    const token = bookmark()
    const path = '/orders'

    const { cart } = getState()

    const products = []
    cart.items.map(it => {
      products.push({
        product_id: it.itemId,
        quantity: it.quantity
      })
    })

    const order = {
      products,
      po_num: poNumber,
      order_comment: comment,
      pickup_location: cart.pickupLocation || null,
      shipping_location: cart.shippingLocation || null
    }

    debug(CART_CHECKOUT_BEGIN, { path, order }, token)
    dispatch({ type: CART_CHECKOUT_BEGIN })

    try {
      const response = await client.create(path, order)
      debug(CART_CHECKOUT_ENDED, { response }, token)
      dispatch({ type: CART_CHECKOUT_ENDED })
      dispatch(clearCart())
    } catch (errors) {
      fault(CART_ERROR, { errors }, token)
      dispatch({ type: CART_ERROR, isFetching: false, errors })
      showMessage({
        message: 'Failed to create new order. Please try again later.',
        type: 'danger',
        autoHide: false
      })
    }
  }
}

export const selectLocation = locationId => {
  return {
    type: CART_SELECT_LOCATION,
    payload: { locationId }
  }
}

export const selectShippingLocation = locationId => {
  return {
    type: CART_SELECT_SHIPPING_LOCATION,
    payload: { locationId }
  }
}

export const clearLocation = () => {
  return (dispatch) => dispatch({ type: CART_CLEAR_LOCATION })
}
