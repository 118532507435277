import React from 'react'
import { connect } from 'react-redux'
import { showMessage } from 'react-native-flash-message'
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TextInput,
  Text,
  TouchableOpacity,
  View
} from 'react-native'
import config from '../../config'
import {
  getUserAuthLoginState,
  getUserAuthErrorState
} from '../UserAuth/selectors'
import { register } from './behaviors'
import styles from './styles'

class UserRegisterScreen extends React.Component {
  static navigationOptions = {
    title: 'Register'
  }

  state = {
    account_num: '',
    company_name: '',
    cell_phone: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    password_confirm: '',
    preferred_location: null,
    branch_id: config.branchId,
    dist_id: config.distId
  }

  handleInputChange = (property, value) => {
    this.setState({
      [property]: value
    })
  }

  handleSignUpPress = async () => {
    let user = this.state
    if (!(user.first_name && user.last_name)) {
      showMessage({
        message: 'First Name and Last Name are required fields',
        type: 'danger'
      })
    } else if (!(user.email)) {
      showMessage({
        message: 'Email Address is a required field',
        type: 'danger'
      })
    } else if (!(user.cell_phone)) {
      // technically, the phone number field is not required by the API
      // however, the API throws a 500 error if it is not provided.
      showMessage({
        message: 'Phone Number is a required field',
        type: 'danger'
      })
    } else if (!(user.company_name)) {
      showMessage({
        message: 'Company Name is a required field',
        type: 'danger'
      })
    } else if (!(user.password && user.password === user.password_confirm)) {
      showMessage({
        message: 'Password and Confirm Password are required and must match',
        type: 'danger'
      })
    } else {
      await this.props.register(user, this.props.navigation)
    }
  }

  handleLoginPress () {
    this.props.navigation.goBack()
  }

  render () {
    const { error, isLoggedIn } = this.props
    return (
      <ScrollView class={styles.container}>
        <KeyboardAvoidingView
          style={styles.container}
          behavior={Platform.OS === 'ios' ? 'padding' : null}>

          <View extraHeight={290} style={styles.bottomHalf}>
            <View style={styles.formWrap}>
              <TextInput
                value={this.state.first_name}
                placeholder='First Name'
                autoCapitalize='words'
                autoCorrect={false}
                style={styles.textInput}
                keyboardType='default'
                onChangeText={text => this.handleInputChange('first_name', text)}
                underlineColorAndroid='transparent' />
              <TextInput
                value={this.state.last_name}
                placeholder='Last Name'
                autoCapitalize='words'
                autoCorrect={false}
                style={styles.textInput}
                keyboardType='default'
                onChangeText={text => this.handleInputChange('last_name', text)}
                underlineColorAndroid='transparent' />
              <TextInput
                value={this.state.email}
                placeholder='Email Address'
                autoCapitalize='none'
                autoCorrect={false}
                style={styles.textInput}
                keyboardType='email-address'
                onChangeText={text => this.handleInputChange('email', text)}
                underlineColorAndroid='transparent' />
              <TextInput
                value={this.state.cell_phone}
                placeholder='Phone Number'
                autoCapitalize='none'
                autoCorrect={false}
                style={styles.textInput}
                keyboardType='phone-pad'
                onChangeText={text => this.handleInputChange('cell_phone', text)}
                underlineColorAndroid='transparent' />
              <TextInput
                value={this.state.company_name}
                placeholder='Company Name'
                autoCapitalize='words'
                autoCorrect={false}
                style={styles.textInput}
                keyboardType='default'
                onChangeText={text => this.handleInputChange('company_name', text)}
                underlineColorAndroid='transparent' />
              <TextInput
                value={this.state.account_num}
                placeholder='Account Number'
                autoCapitalize='none'
                autoCorrect={false}
                style={styles.textInput}
                keyboardType='numeric'
                onChangeText={text => this.handleInputChange('account_num', text)}
                underlineColorAndroid='transparent' />
              <TextInput
                value={this.state.password}
                placeholder='Password'
                secureTextEntry
                autoCapitalize='none'
                autoCorrect={false}
                style={styles.textInput}
                onChangeText={text => this.handleInputChange('password', text)}
                underlineColorAndroid='transparent' />
              <TextInput
                value={this.state.password_confirm}
                placeholder='Confirm Password'
                secureTextEntry
                autoCapitalize='none'
                autoCorrect={false}
                style={styles.textInput}
                onChangeText={text => this.handleInputChange('password_confirm', text)}
                onSubmitEditing={() => this.handleSignUpPress()}
                underlineColorAndroid='transparent' />

              { error && (
                <View>
                  <Text style={styles.error}>{error}</Text>
                </View>
              )}

              <TouchableOpacity style={styles.button} onPress={this.handleSignUpPress}>
                <Text style={styles.lightText}>Register</Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.centerTextWrap}
                onPress={() => this.handleLoginPress()}>
                <Text style={styles.darkText}>Back To Log In</Text>
              </TouchableOpacity>
            </View>
          </View>

        </KeyboardAvoidingView>
      </ScrollView>
    )
  }
}

const mapStateToProps = (state) => ({
  error: getUserAuthErrorState(state),
  isLoggedIn: getUserAuthLoginState(state)
})

export default connect(mapStateToProps, { register })(UserRegisterScreen)
