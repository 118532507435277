import thunk from 'redux-thunk'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducers from './allReducers'

const persistConfig = {
  timeout: 0,
  key: 'root',
  storage: AsyncStorage,
  whitelist: [
    'userauth',
    'cart'
  ]
}

const persistedReducer = persistReducer(persistConfig, rootReducers)
const middleWares = [thunk]

export default function configureStore () {
  const enhancer = composeWithDevTools(applyMiddleware(...middleWares))
  const store = createStore(persistedReducer, enhancer)
  const persistor = persistStore(store)
  return { persistor, store }
}
