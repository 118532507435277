import React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { colors } from '../theme'

const { ACCENT, WHITE } = colors

const Button = (props) => {
  return (
    <TouchableOpacity style={styles.button} onPress={props.onPress}>
      <Text style={styles.lightText}>{props.text}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: ACCENT,
    paddingVertical: 15,
    marginTop: 10
  },
  lightText: {
    color: WHITE,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20
  }
})

export default Button
