export const getCartItems = state => state.cart.items
export const getCartTotal = state => state.cart.cartTotal
export const getCartOrderImportState = state => state.cart.isImportingOrder
export const getCartImportCompleteState = state => state.cart.hasImportedOrder
export const getCartItemsErpLoadingState = state => state.cart.isFetchingPrices
export const getCartUpdateRequiredState = state => state.cart.requiresPriceUpdate
export const getCartItemsErpDataState = state => state.cart.hasErpPricing
export const getCartSelectedLocationId = state => state.cart.locationId
export const getCartErrorState = state =>
  state.cart.hasError ? state.cart.error : false
