import React from 'react'
import { connect } from 'react-redux'
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  TextInput,
  View
} from 'react-native'
import { colors } from '../../theme'
import { ButtonPill } from '../../components'
import { updateShippingLocation, createShippingLocation, fetchLocations } from './behaviors'
import styles from './styles'
import { getLocationById } from './selectors'

class ShippingLocationFormScreen extends React.Component {
  static navigationOptions = {
    title: 'Edit'
  }

  state = {
    id: '',
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: ''
  }

  handleInputChange = (property, value) => {
    this.setState({
      [property]: value
    })
  }

  handleFormSubmit = async () => {
    if (this.props.route.params.isNew) {
      await this.props.createShippingLocation(this.state)
    } else {
      await this.props.updateShippingLocation(this.state)
    }

    this.props.fetchLocations()
    
    this.props.navigation.goBack()
  }

  componentDidMount () {
    const location = this.props.location
    this.setState({ ...location })
  }

  render () {
    return (
      <ScrollView class={styles.shippingForm.container}>
        <KeyboardAvoidingView
          style={styles.shippingForm.container}
          behavior={Platform.OS === 'ios' ? 'padding' : null}>
          <View extraHeight={290} style={styles.shippingForm.container}>
            <View style={styles.shippingForm.formWrap}>
              <TextInput
                value={this.state.name}
                placeholder='Name'
                autoCapitalize='words'
                autoCorrect={false}
                style={styles.shippingForm.textInput}
                keyboardType='default'
                underlineColorAndroid='transparent'
                onChangeText={text => this.handleInputChange('name', text)} />

              <TextInput
                value={this.state.address1}
                placeholder='Address Line 1'
                autoCapitalize='words'
                autoCorrect={false}
                style={styles.shippingForm.textInput}
                keyboardType='default'
                underlineColorAndroid='transparent'
                onChangeText={text => this.handleInputChange('address1', text)} />

              <TextInput
                value={this.state.address2}
                placeholder='Address Line 2'
                autoCapitalize='words'
                autoCorrect={false}
                style={styles.shippingForm.textInput}
                keyboardType='default'
                underlineColorAndroid='transparent'
                onChangeText={text => this.handleInputChange('address2', text)} />

              <TextInput
                value={this.state.city}
                placeholder='City'
                autoCapitalize='words'
                autoCorrect={false}
                style={styles.shippingForm.textInput}
                keyboardType='default'
                underlineColorAndroid='transparent'
                onChangeText={text => this.handleInputChange('city', text)} />

              <TextInput
                value={this.state.state}
                placeholder='State/Province'
                autoCapitalize='words'
                autoCorrect={false}
                style={styles.shippingForm.textInput}
                keyboardType='default'
                underlineColorAndroid='transparent'
                onChangeText={text => this.handleInputChange('state', text)} />

              <TextInput
                value={this.state.zip}
                placeholder='Zip/Postal Code'
                autoCapitalize='none'
                autoCorrect={false}
                style={styles.shippingForm.textInput}
                keyboardType='default'
                underlineColorAndroid='transparent'
                onChangeText={text => this.handleInputChange('zip', text)} />

              <TextInput
                value={this.state.phone}
                placeholder='Phone Number'
                autoCapitalize='none'
                autoCorrect={false}
                style={styles.shippingForm.textInput}
                keyboardType='numeric'
                underlineColorAndroid='transparent'
                onChangeText={text => this.handleInputChange('phone', text)} />

              <ButtonPill
                text='Save'
                color={colors.GREEN}
                onPress={this.handleFormSubmit} />
            </View>
          </View>
        </KeyboardAvoidingView>
      </ScrollView>
    )
  }
}

const mapStateToProps = (state, { route }) => {
  const locationId = route.params?.locationId
  return {
    location: getLocationById(state, locationId)
  }
}

export default connect(mapStateToProps, { updateShippingLocation, createShippingLocation, fetchLocations })(ShippingLocationFormScreen)
