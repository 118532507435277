import React from 'react'
import { connect } from 'react-redux'
import {
  KeyboardAvoidingView,
  Platform,
  TextInput,
  Text,
  TouchableOpacity,
  View
} from 'react-native'

import { Button, LogoBanner } from '../../components'
import { login } from './behaviors'
import {
  getUserAuthLoginState,
  getUserAuthLoadingState,
  getUserAuthErrorState
} from './selectors'
import styles from './styles'

class UserLoginScreen extends React.Component {
  state = {
    email: '',
    password: ''
  }

  handleInputChange = (property, value) => {
    this.setState({
      [property]: value
    })
  }

  handleSignUpPress = () => {
    this.props.navigation.navigate('UserRegister')
  }

  handleLoginPress = () => {
    this.props.login(this.state, this.props.navigation)
  }

  handleForgotPasswordPress = () => {
    this.props.navigation.navigate('UserRecover')
  }

  render () {
    const { error, isLoading, isLoggedIn } = this.props
    return (
      <KeyboardAvoidingView
        style={styles.container}
        behavior={Platform.OS === 'ios' ? 'padding' : null}>

        <LogoBanner/>

        <View extraHeight={290} style={styles.bottomHalf}>
          <View style={styles.formWrap}>
            <TextInput
              placeholder='Email'
              autoCapitalize='none'
              autoCorrect={false}
              style={styles.textInput}
              keyboardType='email-address'
              onChangeText={text => this.handleInputChange('email', text)}
              onSubmitEditing={() => this.handleLoginPress()}
              underlineColorAndroid='transparent' />

            <TextInput
              secureTextEntry
              placeholder='Password'
              autoCapitalize='none'
              autoCorrect={false}
              style={styles.textInput}
              onChangeText={text => this.handleInputChange('password', text)}
              onSubmitEditing={() => this.handleLoginPress()}
              underlineColorAndroid='transparent' />

            { error && (
              <View>
                <Text style={styles.error}>{error}</Text>
              </View>
            )}

            <Button text='Sign In' onPress={() => this.handleLoginPress()} />

            <View style={styles.centerTextWrap}>
              <TouchableOpacity
                style={styles.centerAnchorWrap}
                onPress={() => this.handleSignUpPress()}>

                <Text style={styles.darkText}>Register</Text>
              </TouchableOpacity>

              <Text style={styles.darkText}> / </Text>

              <TouchableOpacity
                style={styles.centerAnchorWrap}
                onPress={() => this.handleForgotPasswordPress()}>

                <Text style={styles.darkText}>Forgot Password</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
    )
  }
}

const mapStateToProps = state => ({
  isLoggedIn: getUserAuthLoginState(state),
  isLoading: getUserAuthLoadingState(state),
  error: getUserAuthErrorState(state)
})

export default connect(mapStateToProps, { login })(UserLoginScreen)
