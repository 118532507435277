import React from 'react'
import { connect } from 'react-redux'
import {
  KeyboardAvoidingView,
  Platform,
  TextInput,
  Text,
  TouchableOpacity,
  View
} from 'react-native'

import { Button } from '../../components'
import { sendRecoverEmail } from './behaviors'
import styles from './styles'

class UserRecoverScreen extends React.Component {
  static navigationOptions = {
    title: 'Forgot Password'
  }

  state = {
    email: ''
  }

  handleInputChange = (property, value) => {
    this.setState({
      [property]: value
    })
  }

  handleResetEmailPress = () => {
    this.props.sendRecoverEmail(this.state, this.props.navigation)
  }

  handleLoginPress = () => {
    this.props.navigation.goBack()
  }

  render () {
    return (
      <KeyboardAvoidingView
        style={styles.container}
        behavior={Platform.OS === 'ios' ? 'padding' : null}>

        <View extraHeight={290} style={styles.bottomHalf}>
          <View style={styles.formWrap}>
            <Text style={styles.instructions}>
              Please enter the email address you used to register your account. An email will be
              sent with instructions on resetting your password.
            </Text>

            <TextInput
              placeholder='Email Address'
              autoCapitalize='none'
              autoCorrect={false}
              style={styles.textInput}
              keyboardType='email-address'
              onChangeText={text => this.handleInputChange('email', text)}
              onSubmitEditing={() => this.handleResetEmailPress()}
              underlineColorAndroid='transparent'/>

            <Button text='Send Email' onPress={this.handleResetEmailPress} />

            <View style={styles.centerTextWrap}>
              <TouchableOpacity
                style={styles.centerAnchorWrap}
                onPress={this.handleLoginPress}>
                <Text style={styles.darkText}>Back To Log In</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
    )
  }
}

const mapStateToProps = state => ({})
export default connect(mapStateToProps, { sendRecoverEmail })(UserRecoverScreen)
