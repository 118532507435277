import { colors } from '../../theme'

const { ACCENT, GREY, BLACK, DANGER, SUCCESS, WHITE } = colors

export default {
  flexContainer: {
    flex: 1
  },
  flexRow: {
    flexDirection: 'row'
  },
  sortOrderContainer: {
    height: 100,
    paddingHorizontal: 10,
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  sortOrderArrow: {
    color: ACCENT,
    fontSize: 30
  },
  sortOrderArrowDisabled: {
    color: GREY,
    fontSize: 30
  },
  priceTableContainer: {
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 5,
    alignItems: 'center'
  },
  priceTable: {
    width: '100%',
    maxWidth: 500
  },
  priceIndicator: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    paddingVertical: 10,
  },
  pricingContainer: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  priceListContainer: {
    paddingRight: 15,
    paddingLeft: 5,
  },
  priceSpecialContainer: {
    borderLeftWidth: 1,
    borderLeftColor: GREY,
    paddingRight: 5,
    paddingLeft: 15,
  },
  priceListHeader: {
    textAlign: 'right'
  },
  priceListValue: {
    textAlign: 'right'
  },
  priceSpecialHeader: {
    textAlign: 'left'
  },
  priceSpecialValue: {
    textAlign: 'left',
    color: DANGER
  },
  warehouseUnavailableMessage: {
    flex: 3,
    paddingHorizontal: 10
  },
  panelBlue: {
    borderWidth: 1,
    borderColor: ACCENT,
    paddingVertical: 7,
    paddingHorizontal: 7,
    backgroundColor: WHITE,
    marginBottom: 10,
    width: '100%',
    maxWidth: 1500,
    margin: 'auto'
  },
  productHeader: {
    marginBottom: 10
  },
  productTitle: {
    fontSize: 14
  },
  productSubTitle: {
    fontSize: 10
  },
  productDesc: {
    marginBottom: 5
  },
  productDescText: {
  },
  priceTotalContainer: {
    flex: 3
  },
  priceTotalHeader: {
  },
  prodImage: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
    marginRight: 7,
    marginBottom: 0
  },
  infoWrap: {
    flex: 4
  },
  itemWrap: {
    height: 34,
    alignItems: 'center',
    justifyContent: 'center'
  },
  buttonContainerContainer: {
    alignItems: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 500
  },
  quantityInput: {
    flexDirection: 'row',
    height: 34,
    borderRadius: 17,
    borderWidth: 1,
    borderColor: ACCENT,
    paddingVertical: 5,
    paddingHorizontal: 5,
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    width: 110
  },
  quantityLabel: {
    alignItems: 'center',
    marginBottom: 0
  },
  quantityLabelText: {
    color: SUCCESS
  }
}
