import React from 'react'
import PropTypes from 'prop-types'
import { CustomPicker } from 'react-native-custom-picker'
import { Text, TouchableOpacity, View } from 'react-native'

import styles from './styles'
import { debug } from '../../logger'

class LocationPicker extends React.Component {
  renderHeader = () => (
    <View style={[styles.headerFooterContainer, { borderBottomWidth: 1, borderBottomColor: 'grey' }]}>
      <Text style={styles.headerFooterText}>{this.props.header}</Text>
    </View>
  )

  renderFooter (action) {
    return (
      <TouchableOpacity
        style={styles.headerFooterContainer}
        onPress={action.close.bind(this)}>
        <Text style={styles.headerFooterText}>Cancel</Text>
      </TouchableOpacity>
    )
  }

  renderField (settings) {
    const { selectedItem, defaultText } = settings
    let label = 'Pickup Location'
    if(selectedItem && !selectedItem.dist_id) {
      label = 'Shipping Location'
    }
    return (
      <View style={styles.container}>
        <View>
          {!selectedItem && <Text style={[styles.text, { color: 'grey' }]}>{defaultText}</Text>}
          {selectedItem && (
            <View style={styles.innerContainer}>
              <Text style={styles.text}>
                {label}: {selectedItem.city}
              </Text>
            </View>
          )}
        </View>
      </View>
    )
  }

  renderOption (settings) {
    const { item, getLabel } = settings
    return (
      <View style={styles.optionContainer}>
        <View style={styles.innerContainer}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ flex: 1, justifyContent: 'flex-start' }}>{item.city}</Text>
            <Text style={{ flex: 1, justifyContent: 'flex-end' }}>{item.phone}</Text>
          </View>
          <Text style={{ flex: 1, color: item.color, alignSelf: 'flex-start' }}>{getLabel(item)}</Text>
        </View>
      </View>
    )
  }

  getAddress = location => {
    return `${location.address1}, ${location.city}, ${location.state} ${location.zip}`
  }

  render () {
    const { locations, onSelect, placeholder, selected, containerStyle } = this.props
    let value = Array.isArray(locations) ? locations.find(a => a.id === selected) : null
    return (
      <View style={styles.wrapper}>
        <CustomPicker
          options={locations}
          placeholder={placeholder}
          containerStyle={containerStyle}
          fieldTemplate={this.renderField}
          optionTemplate={this.renderOption}
          headerTemplate={this.renderHeader}
          footerTemplate={this.renderFooter}
          getLabel={item => this.getAddress(item)}
          onValueChange={value => onSelect(value)}
          value={value}
        />
      </View>
    )
  }
}

LocationPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  selected: PropTypes.string,
  // locations: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     value: PropTypes.string,
  //     option: PropTypes.any
  //   })
  // )
}

LocationPicker.defaultProps = {
  header: null,
  isLoading: false,
  selected: null,
  locations: []
}

export default LocationPicker
