import app from '../app.json'

const logLevel = app.config.logLevel
const traceEnabled = logLevel === 'trace'
const debugEnabled = logLevel === 'debug' || traceEnabled
const warnEnabled = logLevel === 'warn' || debugEnabled

export default {
  apiHost: app.config.api.hostname,
  apiPath: app.config.api.pathname,
  branchId: app.config.api.branchId,
  clientId: app.config.api.clientId,
  clientSecret: app.config.api.clSecret,
  distId: app.config.api.distId,
  traceEnabled,
  debugEnabled,
  warnEnabled
}
