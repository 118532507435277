import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'react-native'

// Android does not support the ECMAScript
// Internationalization API so a hack is required.
const formatter = (typeof Intl !== 'undefined')
  ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
  : { format: (input) => `$${input.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` }

const CurrencyText = (props) => {
  const formatted = formatter.format(props.value || 0)
  return <Text {...props}>{formatted}</Text>
}

CurrencyText.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired
}

export default CurrencyText
