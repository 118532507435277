import React from 'react'
import { connect } from 'react-redux'
import { FlatList, Switch, Text, View } from 'react-native'

import { updateCurrentUser } from '../UserAccount/behaviors'
import { getUserAccount } from '../UserAccount/selectors'
import { fetchLocations } from './behaviors'
import {
  getLocationErrorState,
  getLocationContentState,
  getLocationLoadingState,
  getPickupLocations
} from './selectors'
import styles from './styles'

class PickupLocationsScreen extends React.Component {
  static navigationOptions = {
    title: 'Pickup Locations'
  }

  state = {
    refreshing: true
  }

  getPickupLocations = async () => {
    await this.props.fetchLocations()
    this.setState({ refreshing: false })
  }

  getLocationAddress = ({ address1, city, state, zip }) => {
    return `${address1}\n${city}, ${state}\n${zip}`
  }

  handleSwitchPress = (value, location) => {
    this.props.updateCurrentUser({preferred_location: (value) ? location.id : null})
  }

  handleRefresh = () => {
    this.setState(
      {
        refreshing: true
      },
      () => {
        this.getPickupLocations()
      }
    )
  }

  componentWillMount () {
    this.getPickupLocations()
  }

  renderLocation = item => {
    return (
      <View style={styles.pickup.panel}>
        <View style={styles.pickup.column}>
          <Text style={styles.pickup.boldTextLarge}>{item.name}</Text>
          <Text style={styles.pickup.label}>{this.getLocationAddress(item)}</Text>
        </View>
        <View style={styles.pickup.columnDouble}>
          <View style={styles.pickup.divider} />
          <View style={styles.pickup.switchWrap}>
            <Switch
              value={this.props.user.preferred_location === item.id}
              onValueChange={value => this.handleSwitchPress(value, item)}
            />
            <Text style={styles.pickup.switchLabel}>Preferred Location</Text>
          </View>
        </View>
      </View>
    )
  }

  render () {
    const { locations } = this.props
    return (
      <View style={styles.pickup.container}>
        <FlatList
          data={locations}
          onEndReached={null}
          keyExtractor={item => String(item.id)}
          renderItem={({ item }) => this.renderLocation(item)}
          ItemSeparatorComponent={({ item }) => (
            <View style={styles.pickup.separator} />
          )} />
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    error: getLocationErrorState(state),
    isLoading: getLocationLoadingState(state),
    hasLocation: getLocationContentState(state),
    locations: getPickupLocations(state),
    user: getUserAccount(state)
  }
}

export default connect(mapStateToProps, {
  fetchLocations,
  updateCurrentUser
})(PickupLocationsScreen)
