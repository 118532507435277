import { colors } from '../../theme'

const { ACCENT, GREY_LT3, WHITE } = colors

export default {
    orderDetail: {
        container: {
            flex: 1,
            backgroundColor: GREY_LT3,
            marginBottom: 20
        },
        panel: {
            width: '100%',
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: ACCENT,
            backgroundColor: WHITE,
            paddingVertical: 15,
            paddingHorizontal: 10,
            flexDirection: 'row',
            marginTop: 10,
            alignItems: 'center'
        },
        column: {
            flex: 1,
            flexDirection: 'column'
        },
        flexCenter: {
            justifyContent: 'center',
            alignItems: 'baseline'
        },
        inlineWrap: {
            flexDirection: 'row'
        },
        boldText: {
            lineHeight: 24,
            fontWeight: 'bold'
        },
        boldTextLarge: {
            lineHeight: 24,
            fontSize: 16,
            fontWeight: 'bold'
        },
        label: {
            lineHeight: 24,
            marginRight: 5
        },
        divider: {
            height: 0,
            marginVertical: 3
        },
        panelBlue: {
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: ACCENT,
            paddingVertical: 7,
            paddingHorizontal: 7,
            backgroundColor: WHITE,
            flexDirection: 'row',
            marginTop: 10
        },
        titleText: {
            fontSize: 18
        },
        prodImage: {
            width: 100,
            height: 100,
            resizeMode: 'contain',
            marginRight: 7
        },
        favImage: {
            width: 40,
            height: 40,
            resizeMode: 'contain',
            alignSelf: 'flex-end'
        },
        infoWrap: {
            flex: 3
        },
        endWrap: {
            flex: 1
        }
    },
    orderHistory: {
        container: {
            flex: 1,
            backgroundColor: GREY_LT3,
            marginTop: 10
        },
        panel: {
            width: '100%',
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderColor: ACCENT,
            backgroundColor: WHITE,
            paddingVertical: 15,
            paddingHorizontal: 10,
            flexDirection: 'row',
            alignItems: 'center'
        },
        separator: {
            height: 10
        },
        column: {
            flex: 1,
            flexDirection: 'column'
        },
        flexCenter: {
            justifyContent: 'center',
            alignItems: 'baseline'
        },
        inlineWrap: {
            flexDirection: 'row'
        },
        boldText: {
            lineHeight: 24,
            fontWeight: 'bold'
        },
        boldTextLarge: {
            lineHeight: 24,
            fontSize: 16,
            fontWeight: 'bold'
        },
        label: {
            lineHeight: 24,
            marginRight: 5
        },
        divider: {
            height: 0,
            marginVertical: 3
        }
    }
}