import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { ProductPanel } from '../../components'
import { setFavorite, unsetFavorite } from '../UserFavorite/behaviors'
import { addItemToCart } from '../Cart/behaviors'

import {
  getSearchProductById,
  getSearchContentState,
  getSearchErpDataState,
  getCartItemByProductId,
  getSearchErpLoadingState
} from './selectors'

class SearchViewScreen extends React.Component {
  static propTypes = {
  }

  static defaultProps = {
  }

  render () {
    const { cartItem, product, productStyle, hasErpData, hasResults, isLoadingErp } = this.props
    return (
      <View>
        { hasResults && product && (
          <ProductPanel
            product={product}
            cartItem={cartItem}
            hasErpData={hasErpData}
            productStyle={productStyle}
            isLoadingErp={isLoadingErp}
            setFavorite={this.props.setFavorite}
            unsetFavorite={this.props.unsetFavorite}
            addItemToCart={this.props.addItemToCart} />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state, { route }) => {
  const productId = route.params?.productId
  const product = getSearchProductById(state, productId)

  // NOTE: product styles are not fully implemented in the app. For now, select the first style
  const productStyle = (product && product.styles && product.styles.length > 0) ? product.styles[0] : {}

  return {
    hasErpData: getSearchErpDataState(state),
    hasResults: getSearchContentState(state),
    isLoadingErp: getSearchErpLoadingState(state),
    cartItem: getCartItemByProductId(state, productId, productStyle.id),
    productStyle,
    product
  }
}

export default connect(mapStateToProps, { setFavorite, unsetFavorite, addItemToCart })(SearchViewScreen)
