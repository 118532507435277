import React from 'react'

import { createStackNavigator } from '@react-navigation/stack'

import { colors } from '../../theme'
import CartContent from './CartContentScreen'
import CartCheckout from './CartCheckoutScreen'
import CartComplete from './CartCompleteScreen'

const { ACCENT, WHITE } = colors

const Stack = createStackNavigator()

function CartNavigator() {
  return (
    <Stack.Navigator screenOptions={{
      initialRouteName: 'CartContent',
      headerStyle: {
        backgroundColor: ACCENT,
      },
      headerTintColor: WHITE
    }}>
      <Stack.Screen name="CartContent" component={CartContent} options={{ title: 'Cart' }}/>
      <Stack.Screen name="CartCheckout" component={CartCheckout} options={{ title: 'Checkout' }}/>
      <Stack.Screen name="CartComplete" component={CartComplete} options={{title: 'Complete'}}/>
    </Stack.Navigator>
  )
}

export default CartNavigator
