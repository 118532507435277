import React from 'react'
import { connect } from 'react-redux'
import { Platform, Text, TextInput, View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { ButtonPill, CurrencyText, LocationPicker } from '../../components'
import { getPickupLocations, getLocationLoadingState, getShippingLocations } from '../Location/selectors'
import { fetchLocations } from '../Location/behaviors'
import { colors } from '../../theme'

import { selectLocation, selectShippingLocation, checkout } from './behaviors'
import styles from './styles'
import { debug } from '../../logger'
import { getUserAccount } from '../UserAccount/selectors'

const { SUCCESS } = colors

class CartCheckoutScreen extends React.Component {
  static navigationOptions = {
    title: 'Checkout'
  }

  state = {
    comment: '',
    poNumber: ''
  }

  getLocations = async () => {
    this.props.fetchLocations()
    // this.setState({ refreshing: false })
  }

  handleOrderPress = () => {
    this.props.checkout(this.state)
    this.props.navigation.navigate('CartComplete')
  }

  handlePickupPress = location => {
    if(location.type === 'SHIPPING') {
      this.props.selectShippingLocation(location.id)
    } else {
      this.props.selectLocation(location.id)
    }
  }

  componentDidMount () {
    this.getLocations()
  }

  render () {
    const { cart, isRequestingLocations, locations, shippingLocations, account } = this.props
    const { show_pricing: showPricing } = account

    return (
      <KeyboardAwareScrollView
        enableOnAndroid
        style={styles.checkout.screen}
        extraScrollHeight={15}
        resetScrollToCoords={{ x: 0, y: 0 }}
        enableAutoAutomaticScroll={(Platform.OS === 'ios')}
        contentContainerStyle={styles.checkout.container}>

        <View style={styles.checkout.inputGroup}>
          <View style={styles.checkout.header}>
            { Boolean(showPricing) &&
              <React.Fragment>
                <Text style={styles.checkout.headerLabel}>Subtotal:</Text>
                <CurrencyText style={styles.checkout.headerLabel} value={cart.cartTotal} />
              </React.Fragment>
            }
            <View style={styles.checkout.headerButton}>
              <ButtonPill
                style={styles.checkout.placeOrderButton}
                text='Place Order'
                align='center'
                width='100%'
                color={SUCCESS}
                onPress={this.handleOrderPress}
              />
            </View>
          </View>
        </View>

        <View style={styles.checkout.inputContainer}>
          <View style={styles.checkout.inputGroup}>
            <Text style={styles.checkout.inputLabel}>PO Number:</Text>
            <TextInput
              editable
              style={styles.checkout.inputControl}
              value={this.state.poNumber}
              autoCapitalize='none'
              keyboardType='default'
              returnKeyType='done'
              underlineColorAndroid='transparent'
              onChangeText={(poNumber) => this.setState({ poNumber })}
            />
          </View>

          <View style={styles.checkout.inputGroup}>
            <Text style={styles.checkout.inputLabel}>Order Comment:</Text>
            <TextInput
              multiline
              style={[styles.checkout.inputControl, { textAlignVertical: 'top' }]}
              value={this.state.comment}
              autoCapitalize='none'
              keyboardType='default'
              returnKeyType='done'
              numberOfLines={4}
              underlineColorAndroid='transparent'
              onChangeText={(comment) => this.setState({ comment })}
            />
          </View>

          <View style={styles.checkout.locationInput}>
            <LocationPicker
              locations={locations}
              selected={cart['pickup_location']}
              placeholder={'Choose a Pickup Location'}
              header={'Available Pickup Locations'}
              onSelect={this.handlePickupPress}
              selected={this.props.cart.pickupLocation}
              isLoading={isRequestingLocations}
              containerStyle={styles.checkout.picker}
            />
          </View>
          
          <Text style={styles.checkout.orLabel}>OR</Text>

          <View style={styles.checkout.inputGroup}>
            <LocationPicker
              locations={shippingLocations}
              selected={cart['shipping_location']}
              placeholder={'Choose a Shipping Location'}
              header={'Available Shipping Locations'}
              selected={this.props.cart.shippingLocation}
              onSelect={this.handlePickupPress}
              isLoading={isRequestingLocations}
              containerStyle={styles.checkout.picker}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    locations: getPickupLocations(state),
    shippingLocations: getShippingLocations(state),
    isRequestingLocations: getLocationLoadingState(state),
    account: getUserAccount(state)
  }
}

export default connect(mapStateToProps, { checkout, fetchLocations, selectLocation, selectShippingLocation })(CartCheckoutScreen)
