import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Text, View } from 'react-native'
import { NavigationHeader, ProductPanel } from '../../components'
import { setFavorite, unsetFavorite } from '../UserFavorite/behaviors'
import { getUserAccount } from '../UserAccount/selectors'
import { addItemToCart } from '../Cart/behaviors'
import {
  getProductById,
  getCartItemByProductId,
  getProductsContentState,
  getProductsErpLoadingState,
  getProductsErpContentState,
} from './selectors'

class ProductViewScreen extends React.Component {
  static navigationOptions = ({ navigation }) => {
    const title = navigation.getParam('productTitle', 'Product')
    return { header: props => <NavigationHeader navigation={navigation} title={title} /> }
  }

  static propTypes = {
    product: PropTypes.object.isRequired,
    productStyles: PropTypes.array,
    cartItem: PropTypes.object,
  }

  static defaultProps = {
    productStyles: []
  }

  render () {
    const { cartItem, product, productStyle, hasErpDataResponse, hasProductsResponse, isRequestingErpData, account } = this.props
    return (
      <View>
        { hasProductsResponse && (
          <ProductPanel
            product={product}
            cartItem={cartItem}
            isLoadingErp={isRequestingErpData}
            hasErpData={hasErpDataResponse}
            productStyle={productStyle}
            setFavorite={this.props.setFavorite}
            unsetFavorite={this.props.unsetFavorite}
            addItemToCart={this.props.addItemToCart}
            showPricing={Boolean(account.show_pricing)}
            showInventory={Boolean(account.show_inventory)} />
        )}

        { !(hasProductsResponse) && (
          <View>
            <Text>Selected product not found</Text>
          </View>
        )}
      </View>
    )
  }
}

const mapStateToProps = (state, { route }) => {
  const productId = route.params?.productId
  const product = getProductById(state, productId)

  // NOTE: product styles are not fully implemented in the app. For now, select the first style
  const productStyle = (product.styles && product.styles.length > 0) ? product.styles[0] : {}

  return {
    isRequestingErpData: getProductsErpLoadingState(state),
    hasErpDataResponse: getProductsErpContentState(state),
    hasProductsResponse: getProductsContentState(state),
    cartItem: getCartItemByProductId(state, productId, productStyle.id),
    productStyle,
    product,

    account: getUserAccount(state),
  }
}



export default connect(mapStateToProps, { setFavorite, unsetFavorite, addItemToCart })(ProductViewScreen)
