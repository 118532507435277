import React from 'react'
import PropTypes from 'prop-types'
import { Image, ImageBackground, Text, TouchableOpacity, View } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import { colors } from '../../theme'
import styles from './styles'

const IMAGE_PLACEHOLDER = require('../../../assets/images/missing-image.png')
const { DANGER, GREY_DK2, SUCCESS } = colors

const CategoryListItemContent = ({ text }) => (
  <View>
    <Text style={styles.category.text}>{text}</Text>
    <FontAwesome
      size={32}
      color={GREY_DK2}
      name='chevron-right'
      style={styles.category.icon} />
  </View>
)

const CategoryListItem = ({ category, onPress, collumnCount }) => (
  <TouchableOpacity style={{...styles.category.container,flex:1/collumnCount}} onPress={onPress}>
    <ImageBackground
      imageStyle={styles.category.image}
      style={styles.category.background}
      source={{uri: category.image}}>

      <CategoryListItemContent text={category.name} />
    </ImageBackground>
  </TouchableOpacity>
)

CategoryListItem.propTypes = {
  category: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  onPress: PropTypes.func.isRequired
}

CategoryListItem.defaultProps = {}

const ProductListItem = ({ product, onPress }) => (
  <TouchableOpacity onPress={onPress}>
    <View style={styles.productList.item}>

      <Image
        style={styles.productList.image}
        source={product.image !== null
          ? { uri: product.image } : IMAGE_PLACEHOLDER} />

      <View style={styles.productList.info}>
        <View>
          <Text style={styles.productList.title}>{product.title}</Text>
          <Text style={styles.productList.partNumber}>{product['part_num']}</Text>
        </View>
      </View>

    </View>
  </TouchableOpacity>
)

ProductListItem.propTypes = {
  hasErpData: PropTypes.bool,
  isLoadingErp: PropTypes.bool,
  product: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired
}

ProductListItem.defaultProps = {
  isLoadingErp: false,
  hasErpData: false
}

export {
  CategoryListItem,
  ProductListItem
}
