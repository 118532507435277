import React from 'react'
import { connect } from 'react-redux'
import { Alert, FlatList, Text, View } from 'react-native'

import { ButtonPill, CurrencyText, DateText } from '../../components'
import { colors } from '../../theme'
import {
  cancelOrder,
  fetchOrderHistory
} from './behaviors'
import {
  getOrderHistoryOrders,
  getOrderHistoryLoadedUpToPage,
  getOrderHistoryPageCount,
  getOrderHistoryLoadingState,
  getOrderHistoryLoadedInitialPage
} from './selectors'
import {
  getUserAccount
} from '../UserAccount/selectors'
import styles from './styles'

const { DANGER } = colors

class OrderHistoryListScreen extends React.Component {
  static navigationOptions = {
    title: 'Order History'
  }

  state = {
    refreshing: true
  }

  getOrderNumber = (order) => {
    return (order.erp && order.erp.order_number)
      ? order.erp.order_number : `WWW-${order.id}`
  }

  getOrderHistory = async (page = 1) => {
    await this.props.fetchOrderHistory(page)
    this.setState({ refreshing: false })
  }

  handleViewPress = async (order) => {
    this.props.navigation.navigate(
      'OrderDetail', { orderId: order.id, showPricing: this.props.showPricing })
  }

  handleCancelPress = async (order) => {
    const orderNumber = this.getOrderNumber(order)
    Alert.alert(
      `Cancel Order #${orderNumber}`,
      `Are you sure you want to cancel this order?`,
      [
        { text: 'No', style: 'cancel' },
        { text: 'Confirm',
          style: 'destructive',
          onPress: () => {
            // console.log('CONFIRMED', this.props, this.state)
            // await this.props.cancelOrder(order.id)
            // this.setState({ refreshing: false })
            Alert.alert(
              'Not Implemented',
              'This feature has not yet been implemented. Your order has NOT been cancelled.',
              [
                { text: 'OK', style: 'cancel' }
              ],
              { cancelable: true }
            )
          }
        }
      ],
      { cancelable: true }
    )
  }

  handleNextPage = () => {
    const { pageCount, loadedUpToPage, isLoading } = this.props
    // Don't run if it is on the last page already or if it is loading
    if (loadedUpToPage < pageCount && !isLoading) {
      this.getOrderHistory(loadedUpToPage + 1)
    }
  }

  componentDidMount () {
    const { loadedInitialPage } = this.props
    // Load initial data
    if (!loadedInitialPage) {
      this.getOrderHistory()
    }
  }

  renderOrder = (order) => {
    const { account } = this.props
    const { show_pricing: showPricing } = account
    return (
      <View style={styles.orderHistory.panel}>
        <View style={styles.orderHistory.column}>
          <Text style={styles.orderHistory.boldTextLarge}>{order.date}</Text>
          <View style={styles.orderHistory.inlineWrap}>
            <Text style={styles.orderHistory.label}>Order #:</Text>
            <Text style={styles.orderHistory.boldText}>{this.getOrderNumber(order)}</Text>
          </View>
          <View style={styles.orderHistory.inlineWrap}>
            <Text style={styles.orderHistory.label}>Order Date:</Text>
            <DateText style={styles.orderHistory.boldText} value={order.order_date} />
          </View>
          { Boolean(showPricing) && 
            <View style={styles.orderHistory.inlineWrap}>
              <Text style={styles.orderHistory.label}>Order Total:</Text>
              <CurrencyText style={styles.orderHistory.boldText} value={order.total} />
            </View>
          }
        </View>
        <View style={[styles.orderHistory.column, styles.orderHistory.flexCenter]}>
          <ButtonPill text='View' align='center' onPress={() => this.handleViewPress(order)} />
          <View style={styles.orderHistory.divider} />
          <ButtonPill text='Cancel' align='center' onPress={() => this.handleCancelPress(order)} color={DANGER} />
        </View>
      </View>
    )
  }

  render () {
    const { orders, isLoading } = this.props
    return (
      <View style={styles.orderHistory.container}>
        <FlatList
          data={orders}
          onEndReached={this.handleNextPage}
          keyExtractor={item => String(item.id)}
          renderItem={({ item }) => this.renderOrder(item)}
          ItemSeparatorComponent={({ item }) => (
            <View style={styles.orderHistory.separator} />
          )} />
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    orders: getOrderHistoryOrders(state),
    isLoading: getOrderHistoryLoadingState(state),
    loadedInitialPage: getOrderHistoryLoadedInitialPage(state),
    loadedUpToPage: getOrderHistoryLoadedUpToPage(state),
    pageCount: getOrderHistoryPageCount(state),
    account: getUserAccount(state),
  }
}

export default connect(mapStateToProps, { cancelOrder, fetchOrderHistory })(OrderHistoryListScreen)
