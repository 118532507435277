import 'react-native-gesture-handler';
import React from 'react'
import { View } from 'react-native'
import AppLoading from 'expo-app-loading';
import * as Icon from '@expo/vector-icons'
import { Asset } from 'expo-asset'
import * as Font from 'expo-font'
import { PersistGate } from 'redux-persist/es/integration/react'
import { Provider } from 'react-redux'
import { NavigationContainer } from '@react-navigation/native';

import getClient from './src/services/getClient'
import { configureStore } from './src/store'
import RootComponent from './src/main'

const IMAGE_LOGO_FULL = require('./assets/brand/logo-full.png')
const IMAGE_SHIPPING = require('./assets/images/shipping-fast.png')
const IMAGE_MISSING = require('./assets/images/missing-image.png')

const { persistor, store } = configureStore()
getClient().injectStore(store)

export default class App extends React.Component {
  state = {
    isLoadingComplete: false
  }

  _loadResourcesAsync = async () => {
    return Promise.all([
      Asset.loadAsync([
        IMAGE_LOGO_FULL,
        IMAGE_SHIPPING,
        IMAGE_MISSING
      ]),
      Font.loadAsync({
        ...Icon.FontAwesome.font
      })
    ])
  }

  _handleLoadingError = error => {
    // In this case, you might want to report the error to your error
    // reporting service, for example Sentry
    console.warn(error)
  }

  _handleFinishLoading = () => {
    this.setState({ isLoadingComplete: true })
  }

  render () {
    if (!this.state.isLoadingComplete && !this.props.skipLoadingScreen) {
      return (
          <AppLoading
            onError={this._handleLoadingError}
            onFinish={this._handleFinishLoading}
            startAsync={this._loadResourcesAsync}
            />
      )
    } else {
      return (
        <Provider store={store}>
          <PersistGate loading={<View />} persistor={persistor}>
            <NavigationContainer>
              <RootComponent />
            </NavigationContainer>
          </PersistGate>
        </Provider>
      )
    }
  }
}
