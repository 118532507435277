export const getFavoritesContent = state => state.userfavorite.favorites
export const getFavoritesLoadingState = state => state.userfavorite.isRequestingFavorites
export const getFavoritesErpLoadingState = state => state.userfavorite.isRequestingErpData
export const getFavoritesContentState = state => state.userfavorite.hasFavoritesResponse
export const getFavoritesErpDataState = state => state.userfavorite.hasErpDataResponse
export const getFavoritesErrorState = state =>
  state.userfavorite.hasError ? state.userfavorite.error : false

// cart state selectors
export const getCartItems = state => state.cart.items
