import { colors } from '../../theme'

const { ACCENT, BLUE_LIGHT, GREY, GREY_LIGHTER, GREY_LT3, WHITE, DANGER, WARN_DK } = colors

export default {
  info: {
    container: {
      flex: 1,
      width: '100%',
      height: '100%',
      backgroundColor: GREY_LT3
    },
    formWrap: {
      padding: 40,
      backgroundColor: GREY_LT3
    },
    iconLock: {
      paddingRight: 10
    },
    lightText: {
      color: GREY,
      fontSize: 18
    },
    deleteTextWrap: {
      marginVertical: 15
    },
    deleteTextWarn: {
      fontSize: 20,
      color: WARN_DK,
      marginBottom: 15
    },
    confirmDeletePasswordIncorrect: {
      marginBottom: 15,
      color: DANGER,
      fontSize: 14
    },
    switchWrap: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 15
    },
    switchLabel: {
      marginLeft: 10
    },
    textInput: {
      borderWidth: 2,
      padding: 15,
      marginBottom: 15
    }
  },
  menu: {
    container: {
      flex: 1,
      backgroundColor: GREY_LIGHTER
    },
    content: {
      flex: 3
    }
  }
}
