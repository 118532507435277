import { colors } from '../../theme'

const { ACCENT, WHITE, GREY_LT3, GREY } = colors

export default {
  container: {
    flex:1,
    height: '100%',
    backgroundColor: GREY_LT3
  },
  messageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20
  },
  emptyText: {
    color: GREY,
    fontSize: 24
  },
  emptyIcon: {
    color: GREY,
    fontSize: 144
  },
  centerWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  product: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
    marginBottom: 15,
    borderBottomWidth: 0.5,
    borderTopWidth: 0.5,
    borderColor: ACCENT,
    backgroundColor: WHITE,
    margin: 'auto',
    marginBottom: 15,
    width: '100%',
    maxWidth: 1200
  },
  productImage: {
    width: 75,
    height: 75
  },
  productInfo: {
    paddingLeft: 9,
    width: 0,
    flex: 1,
    flexGrow: 1
  },
  productTitle: {
    fontSize: 16
  },
  partNumber: {
    paddingTop: 5,
    fontSize: 10
  }
}
