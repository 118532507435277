import React from 'react'
import { connect } from 'react-redux'
import { FlatList, Image, Text, TouchableOpacity, View } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'

import { LoadingIcon, SearchHeader } from '../../components'
import { clearResults, fetchResults } from './behaviors'
import {
  getSearchProducts,
  getSearchErrorState,
  getSearchLoadingState,
  getSearchContentState,
} from './selectors'
import styles from './styles'

const IMAGE_PLACEHOLDER = require('../../../assets/images/missing-image.png')

class SearchResultScreen extends React.Component {
  state = {
    query: '',
    lastQuery: '',
    page: 1
  }

  componentDidMount () {
    this.props.clearResults()
  }

  handleSearchTextChange = (query) => {
    this.setState({ query })
  }

  handleSubmit = () => {
    if (this.state.query) {
      if(this.state.query != this.state.lastQuery){ //Only start search when hiding the keyboard if the query changed, so that it doesn't affect adding to cart.
      this.state.lastQuery = this.state.query
      this.props.clearResults()
      this.props.fetchResults({ search: this.state.query }, this.state.page)
      }
    }
  }

  handleOnPress (productId, productTitle) {
    this.props.navigation.navigate('SearchView', {
      productTitle,
      productId
    })
  }

  renderProduct (product) {
    return (
      <TouchableOpacity onPress={() => this.handleOnPress(product.id, product.title)}>
        <View style={styles.product}>
          <Image
            style={styles.productImage}
            source={(product.image !== null) ? { uri: product.image } : IMAGE_PLACEHOLDER} />

          <View style={styles.productInfo}>
            <Text style={styles.productTitle}>{product.title}</Text>
            <Text style={styles.partNumber}>{product['part_num']}</Text>
          </View>

        </View>
      </TouchableOpacity>
    )
  }

  render () {
    const { error, hasResults, isLoading, results } = this.props

    return (
      <View style={styles.container}>
        <SearchHeader
          searchText={this.state.query}
          onChange={this.handleSearchTextChange}
          onSubmit={this.handleSubmit} />

        { isLoading && !hasResults  && (
          <LoadingIcon />
        )}


        { !hasResults && !isLoading && !error && (
          <View style={styles.centerWrap}>
            <FontAwesome name='search' style={styles.emptyIcon} />
              <Text style={styles.emptyText}>Search</Text>
          </View>
        )}



        { hasResults && !isLoading && (
          <FlatList
            data={results}
            keyExtractor={item => String(item.id)}
            renderItem={({ item }) => this.renderProduct(item)}
            // initialNumToRender and maxToRenderPerBatch help us load lazily: https://reactnative.dev/docs/optimizing-flatlist-configuration
            initialNumToRender={8} //The amount of items possible on the first page. could be closer to 5 for phones if we want to seperate them from tablets, but the performance increase is negligible.
            //maxToRenderPerBatch={10}//This is the default, so we don't need to set it.
          />
        )}

        { hasResults && !isLoading && (results.length === 0) && (
          <View style={styles.centerWrap}>
            <FontAwesome name='search' style={styles.emptyIcon} />
            <Text style={styles.emptyText}>No results found</Text>
          </View>
        )}

        { error && !isLoading && (
          <View style={styles.centerWrap}>
            <Text style={styles.emptyText}>There was an error with your search. Please try again.</Text>
          </View>
        )}
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  results: getSearchProducts(state),
  isLoading: getSearchLoadingState(state),
  hasResults: getSearchContentState(state),
  error: getSearchErrorState(state),
})

export default connect(mapStateToProps, {
  clearResults,
  fetchResults
})(SearchResultScreen)
