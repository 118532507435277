export const ERP_PRICING_MULTIPLE_PATH = '/erp/items';

export function formatProductForPricing(product, quantity = 1) {
    let productCode = product.product_code ?? product.part_num ?? product.productCode

    quantity = product.quantity ?? quantity

    return { product_code: productCode, quantity: quantity }
}

export function formatRequestBody(products) {
    if (!Array.isArray(products)) {
        throw new Error('Products should be an array!')
    }

    let formattedProducts = []
    for (let product of products) {
        formattedProducts.push(formatProductForPricing(product))
    }

    return { products: formattedProducts }
}

export function formatResponseBody(response) {
    
}