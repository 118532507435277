import { colors } from '../../theme'

const { ACCENT, DANGER, GREY_DK2, GREY_LT3, WHITE } = colors

export default {
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: GREY_LT3
  },
  topHalf: {
    flex: 1,
    width: null,
    height: null,
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    resizeMode: 'contain'
  },
  headerText: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'white',
    backgroundColor: 'transparent',
    textAlign: 'center',
    padding: 10,
    fontWeight: 'bold'
  },
  bottomHalf: {
    flex: 1,
    backgroundColor: GREY_LT3
  },
  formWrap: {
    padding: 40
  },
  textInput: {
    borderWidth: 2,
    borderColor: ACCENT,
    backgroundColor: WHITE,
    padding: 15,
    marginBottom: 15
  },
  button: {
    backgroundColor: ACCENT,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 0
  },
  lightText: {
    color: WHITE,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20
  },
  rightTextWrap: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row'
  },
  centerTextWrap: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 20
  },
  anchorWrap: {
    height: 20,
    marginLeft: 5,
    marginTop: 10
  },
  centerAnchorWrap: {
    height: 0,
    marginLeft: 0,
    marginTop: 0
  },
  darkText: {
    color: GREY_DK2
  },
  anchorText: {
    color: ACCENT
  },
  error: {
    color: DANGER
  }
}
