import { showMessage } from 'react-native-flash-message'
import { processErrorResponse } from '../../utilities'
import { bookmark, debug, fault } from '../../logger'
import getClient from '../../services/getClient'
import { formatRequestBody, ERP_PRICING_MULTIPLE_PATH } from '../../services/erpFormattingService'

import {
  USER_FAVORITE_CREATE_RECEIVE,
  USER_FAVORITE_DELETE_RECEIVE
} from '../UserFavorite/behaviors'

export const CATALOG_ERROR = 'CATALOG/ERROR'
export const CATALOG_RESET = 'CATALOG/RESET'
export const CATEGORY_FETCH = 'CATALOG/CATEGORY/FETCH'
export const CATEGORY_RECEIVE = 'CATALOG/CATEGORY/RECEIVE'
export const PRODUCT_FETCH = 'CATALOG/PRODUCT/FETCH'
export const PRODUCT_RECEIVE = 'CATALOG/PRODUCT/RECEIVE'
export const PRODUCT_ERP_FETCH = 'CATALOG/PRODUCT/ERP/FETCH'
export const PRODUCT_ERP_RECEIVE = 'CATALOG/PRODUCT/ERP/RECEIVE'
export const PRODUCT_ERP_RECIEVE_COMPLETE = 'CATALOG/PRODUCT/ERP/RECEIVE/COMPLETE'

/**
 * Definitions:
 * - error                  contains API error string on error
 * - hasError               `true` if API responded with an error
 * - hasCategoryResponse    `true` if API request completed successfully
 * - isRequestingCategories `true` if API request has not yet returned a response
 * - categories                  object containing category lists keyed by navigation route
 */
const initialState = {
  error: null,
  hasError: false,
  isRequestingCategories: false,
  hasCategoryResponse: false,
  isRequestingProducts: false,
  hasProductsResponse: false,
  isRequestingErpData: false,
  hasErpDataResponse: false,
  categories: {},
  products: []
}

const handlers = {
  // Category
  [CATEGORY_FETCH]: (state, action) => ({
    ...state,
    error: null,
    hasError: false,
    hasCategoryResponse: false,
    isRequestingCategories: true 
  }),

  [CATEGORY_RECEIVE]: (state, action) => {
    const { payload } = action
    const { categories } = state

    const newCategories = { ...categories }
    newCategories[payload.routeKey] = payload.categories

    return {
      ...state,
      hasCategoryResponse: true,
      isRequestingCategories: false,
      categories: newCategories
    }
  },

  // Products
  [PRODUCT_FETCH]: (state, action) => ({
    ...state,
    error: null,
    hasError: false,
    hasProductsResponse: false,
    isRequestingProducts: true
  }),

  [PRODUCT_RECEIVE]: (state, action) => {
    const { payload } = action
    return {
      ...state,
      hasProductsResponse: true,
      isRequestingProducts: false,
      products: payload.products
    }
  },

  // ERP
  [PRODUCT_ERP_FETCH]: (state, action) => ({
    ...state,
    error: null,
    hasError: false,
    hasErpDataResponse: false,
    isRequestingErpData: true
  }),

  [PRODUCT_ERP_RECEIVE]: (state, action) => {
    const { products } = state
    const { payload } = action

    const newProducts = products.map((stateItem) => {
      // Find the match, if there is no match return the original
      stateItem.erp = payload.products.find(responseItem =>
        responseItem['product_code'] === stateItem['part_num']
      ) ?? stateItem.erp

      return stateItem
    })

    return {
      ...state,
      products: newProducts,
      hasErpDataResponse: true,
      isRequestingErpData: false
    }
  },


  [USER_FAVORITE_CREATE_RECEIVE]: (state, action) => {
    const { payload } = action
    const { products } = state

    const idx = products.findIndex(it => payload.product.id === it.id)
    if (idx !== -1) { products[idx]['user_favorite_id'] = payload.id }

    return {
      ...state,
      products: [ ...products ]
    }
  },

  [USER_FAVORITE_DELETE_RECEIVE]: (state, action) => {
    const { payload } = action
    const { products } = state

    const idx = products.findIndex(it => payload.id === it['user_favorite_id'])
    if (idx !== -1) { products[idx]['user_favorite_id'] = null }

    return {
      ...state,
      products: [ ...products ]
    }
  },

  [CATALOG_ERROR]: (state, action) => ({ ...state, hasError: true, error: action.error }),
  [CATALOG_RESET]: (state, action) => ({ ...state, ...initialState })
}

// Root Reducer
export default (state = initialState, action) => {
  const handler = handlers[action.type]
  return handler ? handler(state, action) : state
}



export const fetchCategories = (routeKey, selectedId) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = selectedId
      ? `/categories/${selectedId}/subcategories`
      : '/categories'

    debug(CATEGORY_FETCH, { path, selectedId }, token)
    dispatch({ type: CATEGORY_FETCH, payload: { path, routeKey } })

    try {
      const categories = await client.all(path)
      debug(CATEGORY_RECEIVE, { categories, routeKey }, token)
      dispatch({ type: CATEGORY_RECEIVE, payload: { categories, routeKey } })
    } catch (error) {
      fault(CATEGORY_FETCH, { error }, token)
      dispatch(processErrorResponse(CATEGORY_FETCH, error))
      showMessage({
        message: 'Failed to fetch category list. Please try again later.',
        type: 'danger'
      })
    }
  }
}



export const fetchProducts = (categoryId, showPricingOrStock) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = `/categories/${categoryId}/products`

    debug(PRODUCT_FETCH, { path }, token)
    dispatch({ type: PRODUCT_FETCH })

    let products

    try {
      products = await client.all(path)
    } catch (error) {
      fault(PRODUCT_FETCH, { error }, token)
      dispatch(processErrorResponse(PRODUCT_FETCH, error))
      showMessage({
        message: 'Failed to fetch products list. Please try again later.',
        type: 'danger'
      })
    }

    debug(PRODUCT_RECEIVE, { categoryId, products }, token)
    dispatch({ type: PRODUCT_RECEIVE, payload: { categoryId, products } })

    // ERP Pricing
    if (showPricingOrStock) {
      const requestBody = formatRequestBody(products)

      debug(PRODUCT_ERP_FETCH, { path: ERP_PRICING_MULTIPLE_PATH, body: requestBody }, token)
      dispatch({ type: PRODUCT_ERP_FETCH })
  
      let response
      try {
        response = await client.create(ERP_PRICING_MULTIPLE_PATH, requestBody)  
      } catch (error) {
        fault(PRODUCT_ERP_FETCH, { error }, token)
        dispatch(processErrorResponse(PRODUCT_ERP_FETCH, error))
        showMessage({
          message: 'Failed to fetch product pricing. Please try again later.',
          type: 'danger'
        })
      }

      debug(PRODUCT_ERP_RECEIVE, { payload: response }, token)
      dispatch({ type: PRODUCT_ERP_RECEIVE, payload: response })
    }
  }
}

export const resetCatalog = () => {
  return (dispatch) => dispatch({ type: CATALOG_RESET })
}
