import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import { colors } from '../theme'

const { ACCENT, WHITE } = colors

class NavigationHeader extends React.Component {
  static propTypes = {
    navigation: PropTypes.shape({
      goBack: PropTypes.func.isRequired
    }),
    title: PropTypes.string.isRequired
  }

  handleNavigationBack = () => {
    this.props.navigation.goBack()
    // const { navigation, status } = this.props
    // navigation.dispatch({
    //   routeName: status.selectedProductId ? 'ProductList' : 'CategoryList',
    //   params: { id: status.selectedCategoryId },
    //   type: NavigationActions.BACK
    // })
  }

  render () {
    const { title } = this.props
    const finalTitle = (title.length > 18)
      ? `${title.substring(0, 18)}...` : title

    return (
      <View style={styles.navigationHeaderContainer}>
        <TouchableOpacity activeOpacity={0.8}
          style={styles.backButton}
          onPress={this.handleNavigationBack}>
          <FontAwesome name='chevron-left' size={32} color={WHITE} />
        </TouchableOpacity>
        <Text style={styles.titleText}>
          {finalTitle}
        </Text>
        <View style={styles.right} />
      </View>
    )
  }
}

const styles = StyleSheet.create({
  navigationHeaderContainer: {
    height: 84,
    backgroundColor: ACCENT,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
  },

  backButton: {
    flex: 1,
  },

  right: {
    flex: 1,
  },

  backButtonImage: {
    height: 28,
  },

  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
    color: WHITE
  },

  searchBar: {
    width: 250,
    height: 34,
    backgroundColor: WHITE,
    borderRadius: 34,
    paddingLeft: 40,
  },
});

export default connect(({ status }) => ({ status }))(NavigationHeader)
