import { hideMessage, showMessage } from 'react-native-flash-message'
import AsyncStorage from '@react-native-async-storage/async-storage'

import { processErrorResponse } from '../../utilities'
import { bookmark, debug, fault } from '../../logger'
import getClient from '../../services/getClient'

import { fetchCurrentUser } from '../UserAccount/behaviors'

export const USERAUTH_ERROR = 'USERAUTH/ERROR'
export const USERAUTH_RESET = 'USERAUTH/RESET'
export const USERAUTH_LOGIN_REQUEST = 'USERAUTH/LOGIN/REQUEST'
export const USERAUTH_LOGIN_RECEIVE = 'USERAUTH/LOGIN/RECEIVE'
export const USERAUTH_LOGOUT = 'USERAUTH/LOGOUT'

const initialState = {
  error: null,
  hasError: false,
  expiration: null,
  isLoggedIn: false,
  tokenAccess: null,
  tokenRefresh: null,
  isRequestingLogin: false
}

const handlers = {
  [USERAUTH_LOGIN_RECEIVE]: (state, action) => {
    const { payload } = action
    return {
      ...state,
      isLoggedIn: true,
      isRequestingLogin: false,
      expiration: payload['expires_in'],
      tokenAccess: payload['access_token'],
      tokenRefresh: payload['refresh_token']
    }
  },
  [USERAUTH_LOGIN_REQUEST]: (state, action) => ({
    ...state,
    error: null,
    hasError: false,
    isLoggedIn: false,
    isRequestingLogin: true
  }),
  [USERAUTH_LOGOUT]: (state, action) => ({ ...state, ...initialState }),
  [USERAUTH_ERROR]: (state, action) => ({ ...state, hasError: true, error: action.error }),
  [USERAUTH_RESET]: (state, action) => ({ ...state, ...initialState })
}

export default (state = initialState, action) => {
  const handler = handlers[action.type]
  return handler ? handler(state, action) : state
}

export const login = (credentials) => {
  return async (dispatch) => {
    const client = getClient()
    const token = bookmark()
    const path = '/users'

    debug(USERAUTH_LOGIN_REQUEST, { path, credentials }, token)
    dispatch({ type: USERAUTH_LOGIN_REQUEST })

    try {
      const response = await client.login(credentials)
      hideMessage()
      debug(USERAUTH_LOGIN_RECEIVE, { response }, token)
      dispatch({ type: USERAUTH_LOGIN_RECEIVE, payload: response })

      // Fetch User Details on Login
      fetchCurrentUser()(dispatch)
    } catch (error) {
      fault(USERAUTH_LOGIN_REQUEST, { error }, token)
      dispatch(processErrorResponse(USERAUTH_LOGIN_REQUEST, error))
      showMessage({
        message: 'Incorrect email or password.',
        type: 'danger',
        autoHide: 5000
      })
    }
  }
}


export const logout = () => {
  return (dispatch) => {
    dispatch({ type: USERAUTH_LOGOUT })
    AsyncStorage.clear()
    showMessage({
      message: 'Logout successful',
      type: 'success'
    })
  }
}
