import React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { colors } from '../theme'

const { ACCENT, WHITE } = colors

const ButtonPill = props => {
  // We should put all the styles in the style prop
  const style = props.style ?? null

  // We will keep this for now until we change all occurances, then put the defaults in the style sheet below
  const align = props.align ? props.align : 'flex-start'
  const color = props.color ? props.color : ACCENT
  const margin = props.margin ? props.margin : 0
  const width = props.width ? props.width : 100

  return (
    <TouchableOpacity
      style={[
        styles.button,
        { backgroundColor: color, alignSelf: align, width, marginVertical: margin },
        style,
      ]}
      onPress={props.onPress}
    >
      <Text style={styles.lightText}>{props.text}</Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    paddingVertical: 7,
    paddingHorizontal: 10,
    borderRadius: 22,
    alignSelf: 'flex-start'
  },
  lightText: {
    color: WHITE,
    textAlign: 'center',
    fontSize: 16
  }
})

export default ButtonPill
